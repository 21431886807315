// *** Site Mixins *** //

@mixin center($maxWidth){
	width: 100%;
	margin: auto;
	position: relative;
	max-width: $maxWidth;
}

@mixin bgImg($file){
	background-image: url('../images/#{$file}');
}

@mixin font-smoothing(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
}

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin border-radius($args) {
 -moz-border-radius: $args;
  -webkit-border-radius: $args;
  border-radius: $args;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} { @content;}
  @-moz-keyframes #{$animationName}  {@content;}
  @-o-keyframes #{$animationName} {@content;}
  @keyframes #{$animationName} {@content;}
}

@mixin animation($animation) {
  -o-animation: $animation;
  -moz-animation: $animation;
  -webkit-animation: $animation;
  animation: $animation;
}

@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}

@mixin redButton(){
  padding: 7px 15px 5px;
  background: $accentColor;
  color: white;
  font-family: $fontTwo;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px $darkRed;
  transition: $transition-standard;
  font-weight: $semibold;
  text-shadow: none; 
  border-radius: 0;
  box-shadow: none;
}

@mixin redButtonHover(){
  background: $darkRed;
  border-bottom: solid 4px $accentColor;
}

// 3. Animations
@include keyframes(dropdown-season) {
  0% {opacity: 0;top: 90%;}
  100% {opacity: 1;top: 100%;}
}