// *** Specific Page Styles *** //

#splash,
.newsPage {

	#siteHeader {

		#menuBtn,
		#playerMenu { display: none; }

	}

}

// body#home.user_mode { display: none; }

#home {

	#displayBodyHeader { display: none; }

	#pageContentContainer {
		max-width: $maxWidthHome;

		#prospectsContainer {
			width: 30%;
			display: block;
			float: left;
		}

	}

	#network .networkTeams {
		@include center($maxWidthHome);
		padding: 0 25px;
	}

	#siteHeader  { max-width: $maxWidthHome; }

	#topNav {

		table { max-width: 1340px; }

	}

	#siteContainer2 {

		&.one_panel {
			margin-left: 0;

			&#yieldContent,
			.yieldPageContent { padding: 0; }

		}

		&.two_panel {

			#panelTwo {
				margin-right: 0;
				    max-width: calc(100% - 300px);

				.layoutContainer { padding-left: 0; }

			}

		}

	}

	&.topProspects {

		#panelTwo { max-width: 70%; }

		#siteFooter #footerUpdates { display: block; }

	}

	.largeHeader {
		font-size: 2.5rem;
		padding: 30px 0 0 30px;
		color: $coal;
	}

	#siteFooter {

		#footerUpdates .updates,
		#megaFooter { max-width: $maxWidthHome; }

		#footerUpdates { display: block; }

	}

	#sidePanelContainer {
		// padding-top: 8px;

		.homeSidePanel {
			padding-left: 0;

			.pageElement { margin-right: 0; }

		}

		.adOne,
		.adTwo { margin-bottom: 15px; }

		.twitterFeed { margin-bottom: 8px; }

	}

}

#assets {

	&.user_mode {

		.players {

			.pageEl {
				width: 33.3333333%;
				display: inline-block;
				margin-bottom: 10px;

				.pageElement { background: white; }

			}

		}

		.footerRight {

			.heroPhotoElement img { max-width: 250px; }

		}

	}

	&.edit_mode {

		.players {

			.pageEl {
				width: 100%;

				.pageElement {
					background: white;
					padding: 0 15px;
					position: relative;

					.text {
						margin-top: 0;
						position: absolute;
						width: 100%;
						bottom: 0;

						h1, h2, h3, h4 {
							text-transform: uppercase;
							line-height: normal;
						}

						h1 {
							color: $mainColor;
							font-size: 4vw;
						}

						h2 {
							font-size: 1vw;
						}

						h3 {
							color: $coal;
						}

						h4 {
							color: $coal;
							font-size: 3vw;
							margin-top: -10px;
						}

					}

				}

			}

			.drag {
				width: 33.3333333%;
				float: left;
				margin-bottom: 10px;
			}

			// .drag:first-of-type { width: 100%; }

			.tabContainer .drag:first-of-type { width: 33.3333333%; }

		}

	}

}

// Team Logos
#team-logos {

	.networkLogos {

		.heroPhotoElement {

			img { max-width: 100px; }

		}

	}

}

// Game Show

.gameShow {

	#sidePanelContainer {
		margin: 0;
    float: left;
	}

}

// News Page

.user_mode {

	&.newsPage {


		h2#displayBodyHeader { display: none; }


		.newsTags a {
			font-size: .7rem;
			transition: $transition-standard;

			&:hover { opacity: .75; }

		}

		.bottomOptions .pageOptions li {

			a { font-size: 12px; }

		}

		.layoutContainer {

			&.newsItemColumn { margin: 0 0 1.6875rem }

			.pageElement.newsItemElement { border-bottom: 1px solid $green; }

			.heroPhotoElement img { margin-bottom: 1.6875rem }

		}

		.newsItemElement {

			h2 {
				font-family: $fontOne;
				font-size: 3rem;
				color: $mainColor;
				font-weight: 700;
			}

			.newsDate {
				font-family: $fontTwo;
				font-size: .8rem;
				color: $dark;
				font-weight: 400;
				line-height: 1;
				margin-bottom: 10px;
				padding-right: 5px;
			}

			h4 {
				font-family: $fontTwo;
				font-size: 1rem;
				color: $coal;
				font-weight: 700;
				line-height: normal;
				margin-bottom: 1em;
			}

			.newsAuthor {
				font-family: $fontTwo;
				font-size: .75rem;
				font-weight: 500;
				color: $dark;
				padding-left: 10px;
				border-left: solid 1px $gray;
			}

		}

		.textBlockElement {

			p {
				font-family: $fontOne;
				color: #222;
				font-size: 1.125rem;
				line-height: 1.5;
				margin-bottom: 1.5em;
				font-weight: 400;
			}

			h2, h1 {
				font-family: $fontOne;
				color: #15315a;
				font-size: 1.6875rem;
				line-height: 1.5;
				text-transform: uppercase;
				margin: 0;
			}

			h3 {
				font-family: $fontOne;
				color: $mainColor;
				font-size: 1.5rem;
				line-height: 1.5;
				text-transform: uppercase;
				margin: 0;
				padding: 0;
			}

			a {
				color: #ffb005;
				font-weight: 700;
				text-decoration: none;
				border-bottom: transparent solid 3px;
				transition: all 0.25s ease-out;

				&:hover {
					border-bottom: 3px solid;
					transition: all 0.25s ease-in;
				}


			}

		}

		.pageElement h3 {
			font-family: $fontOne;
			color: $mainColor;
			font-size: 1.5rem;
			line-height: 1.5;
			text-transform: uppercase;
			margin: 0;
			padding: 0;
		}

		.paginationElement,
		.directorySearchElement,
		.filterElement { background: none; }

		.paginationElement {

			h4 {
				font-family: $fontOne;
				color: #222;
				font-size: 1rem;
				line-height: 1.5;
				text-transform: uppercase;
				margin: 0;
				padding: 0;
			}

			.results {
				font-family: $fontOne;
				color: #8c9192;
				font-size: .75rem;
				line-height: 1.5;
				text-transform: uppercase;
				margin: 0;
				padding: 0;
			}

		}

		.pagination {
			font-family: $fontOne;
			color: #222;
			font-size: 1rem;
			line-height: 1.5;
			text-transform: uppercase;
			margin: 0;
			padding: 0;

				a {color: #ec1c2d}

		}

		.user_filters {

			select {
				font-family: $fontOne;
				color: #222;
				font-size: 1.125rem;
				line-height: 1.5;
				font-weight: 400;
			}

			.button-small {
				font-family: $fontOne;
				text-transform: uppercase;
				font-weight: 700;
				background: #ec1c2d;
				border-radius: 0;
				color: #fff;
				font-size: 1em;
				padding: .25em .5em;
				box-shadow: none;
				text-shadow: none;

				&:hover { background: #d0112b }

			}

		}

		#siteFooter {
			background: black;

			ul {
				text-align: center;
			}

			#poweredByNGIN {
				float: none;
		    width: 100%;
		    margin: 4px 0;
			}

		}





	} // Newspage End

	.socialSharing {
    margin-left: 15px;

	    li {
		    width: 40px;
		    height: 39px;
		    background-color: #999;
		    display: inline-block;
		    margin: 0 5px 5px 0;
	    	float: left;

		     &:hover { opacity: .75; }

		    a {
			    width: 40px;
			    height: 39px;
			    display: block;
			    transition: $transition-standard;

				}

			}

			.socialTitle { display: none; }

			.socialComments {
		    @include bgImg('connect/socialComments.svg');
		    background-position: center;
		    background-color: $dark;
		    display: none;
		    background-repeat: no-repeat;
			}

			.socialFacebook {
		    @include bgImg('connect/socialFacebook.svg');
		    background-position: center;
		    background-size: auto 50%;
		    background-color: #3b5998;
		    background-repeat: no-repeat;
			}

			.socialTwitter {
		    @include bgImg('connect/socialTwitter.svg');
		    background-position: center;
		    background-color: #019FE9;
		    background-size: 50% auto;
		    background-repeat: no-repeat;
			}

			.socialEmail {
		    @include bgImg('connect/socialEmail.svg');
		    background-position: center;
		    background-color: #76868D;
		    background-size: 50% auto;
		    background-repeat: no-repeat;
			}

	}

} // User Mode End

// Player Page

.user_mode {

	.playerProfile {
		margin-bottom: 15px;
		margin-right: 15px;

		.rowOne,
		.rowTwo { position: relative; }

		.layoutInner {
			border-bottom: solid 3px $accentColor;
			max-width: 855px;
		}

		.pageEl {
			display: inline-block;
			float: left;

			&:last-of-type .pageElement { margin-right: 0; }

			.pageElement { margin-bottom: 0; }

		}

		.playerPhoto {
			width: 25%;

			.heroPhotoElement {
				position: relative;
				text-align: center;
			}

		}

		.statsOne {
			width: 31%;

			.textBlockElement {

				h3 {
					color: $mainColor;
					margin-bottom: 10px;

					span {
						font-size: 1rem;
					}

				}

				.text {
					margin-top: 0;

					.number {
						color: $mainColor;
						font-size: 4vw;
						font-weight: $semibold;
						margin-bottom: 10px;
					}

					.name {
						color: $black;
						font-weight: $semibold;
						font-size: 2vw;
						line-height: normal;
						margin-bottom: 0;

						strong { font-size: 2.5vw; }

					}

					.position {
						font-weight: $semibold;
						margin-top: -9px;

						u {
							color: $accentColor;
							text-decoration: none;
						}

					}


				}

			}

		}

		.statsTwo {
			width: 25%;

			.pageElement.textBlockElement .text p { margin-bottom: 5px; }

		}

		.playerLogo {
			width: 15%;
			margin: 0 2%;
		}

	}

}


// Assets

#assets.edit_mode {

	.footerRight .heroPhotoElement img { max-width: 250px; }

	.sidePanel,
	.homeSidePanel {

		.drag {
			width: 33.3333333333%;
			float: left;
		}

	}

}

#assets.user_mode {

	.homeSidePanel,
	.sidePanel {

		.pageEl {
			width: 33.3333333333%;
			float: left;

			.pageElement { margin: 0 15px 15px 0; }

			.aggregatorElement.newsAggregatorElement .item {

			> a { display: none; }

			h4 a {
				color: $black;
				font-size: 1rem;
				font-weight: $semibold;
				line-height: normal;
				transition: $transition-standard;

				&:hover { color: $accentColor; }

			}

			.details {
				padding-left: 0;

				.dateAuthor { display: none; }

			}

			.commentsAndMore,
			&.extendedOptions { display: none; }

			&:last-of-type { border-bottom: none; }

		}

		}

	}

}

// #snm_advert

#sport-header #snm_advert { display: none; }


// Media Queries

@media only screen and (min-width: 1300px) {

	.user_mode .socialSharing {
    position: fixed;
    top: 270px;
    margin-left: -50px;

    ul {
    	position: relative;
		}

		li {
    	display: block;
    	margin: 5px auto;
    	float: none;
		}

	}

	.user_mode.newsPage .layoutContainer.newsItemColumn {
		margin: 0 0 1.6875rem;
	}

}

@media (min-width: 768px) and (max-width: 1200px) {

	.user_mode .playerProfile {

		.statsOne {
			width: 55%;

			.textBlockElement .text {

				.number { line-height: 1rem; }

				.name {
					font-size: 1.5rem;
					line-height: 2rem;

					strong {
						font-size: 2rem;
	  				line-height: 1.5rem;
					}

				}

			}

		}

		.statsTwo {
			width: 100%;
			padding-top: 10px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				height: 3px;
				background: $accentColor;
				top: 0;
			}

			.pageElement.textBlockElement .text {
				margin-bottom: 10px;

				p {
					display: inline-block;
					margin-right: 5px;
					margin-bottom: 0;
					line-height: normal;
				}

			}

		}

	}

}


@media screen and (max-width: 1024px) {

	#home {

		#siteContainer2.two_panel #panelTwo { max-width: 100%; }

	}

}

@media screen and (max-width: 990px) {

		.user_mode .playerProfile {

		.statsOne {
			width: 60%;
			float: none;

			.textBlockElement,
			.textBlockElement .text {

				h3 {
					margin-bottom: 3px;

					span { font-size: .75rem; }

				}

				.number {
					font-size: 2rem;
    			line-height: 1rem;
    			margin-bottom: 2px;
				}

				.name {
					font-size: 1.25rem;

					strong {
						font-size: 1.5rem;
    				line-height: 1rem;
					}

				}

				.position {
					font-size: 1rem;
    			line-height: 2rem;
				}

			}

		}

		.statsTwo {
			width: 100%;
			border-top: solid 3px $accentColor;
			padding-top: 5px;

			&:before { display: none; }

			.pageElement.textBlockElement .text {
				margin-bottom: 10px;

				p {
					display: inline-block;
					margin-right: 5px;
					margin-bottom: 0;
					line-height: normal;
				}

			}

		}

		.playerPhoto {
			width: 40%;

		}

	}

		.user_mode .playerProfile {

		.rowOne {
			position: relative;
			margin-bottom: 15px;

			&:after {
				content: '';
				display: block;
				height: 3px;
				position: absolute;
				width: 100%;
				bottom: 0;
				left: 0;
				background: $accentColor;
				visibility: visible;
			}

		}

		.playerPhoto {
			width: 40%;
			float: none;
		}

		.playerLogo {
			width: 40%;
			margin: 0;

			.heroPhotoElement {
				text-align: center;

				img {
					max-width: 100px;
	  			padding: 0 0 15px;
	  			display: inline-block;
	  		}

			}

		}

		.statsTwo {
			border-top: none;
			width: 60%;

			.pageElement.textBlockElement .text p {
				display: block;
				margin-bottom: 5px;
			}

		}

	}

}

@media screen and (max-width: 768px) {

}


@media screen and (max-width: 640px) {


}

@media screen and (max-width: 480px) {


}

@media screen and (max-width: 1024px) {
 .newsPage .siteContainer#siteContainer2.two_panel #panelTwo {
	 max-width: 100% !important;
 }

}
