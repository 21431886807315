// *** Default Site Styles *** //

html {
	overflow-x: hidden;
	font-size: 18px;
}

html, body { background: $light; }

// Site Container
.siteContainer {
	@include center(100%);

	&#siteContainer2 {

		&.one_panel,
		&.two_panel {

			// Panel One
			#panelOne {
				width: 100%;
				max-width: 20%;
			}

			// Panel Two
			#panelTwo {
				box-shadow: none;
				background: transparent;
			}

			// Yield Content
			#yieldContent { background: transparent; }

		}

		&.two_panel {

			// Panel One
			#panelOne {
				width: 100%;
				max-width: 300px;

				#leftHandGraphics {
					max-width: 300px;
				}

			}

			// Panel Two
			#panelTwo {
				box-shadow: none;
				background: transparent;
				width: 100%;
				// max-width: calc(100% - 315px);
				// margin-right: 15px;
				margin-left: 0;
				padding: 0;
			}

			#pageContentContainer {
				padding-top: 15px;
			}

		}

	}
}

.newsPage .siteContainer {

	&#siteContainer2 {
		&.two_panel {
			#panelTwo {
				max-width: calc(100% - 315px);
				margin-right: 15px;
			}
		}
	}

}

.gameShow {

	#siteContainer2.one_panel #panelTwo {
		box-shadow: none;
		background: transparent;
		width: 100%;
		max-width: calc(100% - 315px);
		margin-right: 15px;
		margin-left: 0;
		padding: 0;
	}

}

.user_mode #panelOne {

	.subNavContainer { display: none; }

}

// Page Element
.pageElement { font-family: $fontTwo; }

// Page Content Container
#pageContentContainer {
	@include center(1200px);
	background: transparent;
	padding: 0;
}

// Acoount Nav
#accountNav {
	background: $black;
	border-bottom: solid 1px $coal;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;

	li a {
		border-right: none;
		box-shadow: none;

		&.secondary {
			box-shadow: none;
			border-right: none;
		}

	}

	.currentSite a#edit_membership { border-left: none; }

}

// Site Header
#siteHeader {
	height: 0;
	min-height: 0;
	@include center($maxWidth);
	z-index: 3;

	h1,
	.bannerGraphicTextBanner h1 { display: none; }

	.adminToggle,
	#adminNav a {
		background: #FCCD22;
		z-index: 999;
		height: 30px;
		transition: $transition-standard;

		&:before {
			content: 'Edit/User';
			display: block;
			position: absolute;
			height: 30px;
			width: 100%;
			text-align: center;
			line-height: 30px;
			font-size: 12px;
			color: $black;
			text-indent: 0;
			transition: $transition-standard;

		}

		&:hover {
			background: $dark;

			&:before { color: white; }

		}

	}

	#adminNav,
	.adminToggle {
		height: 30px;
		top: 0;
		position: absolute;
		width: 100px;
	}

	.adminToggle {
		border-left: solid 1px #dda301;
	}

	#adminNav {
		right: 100px;

		a {
			width: 100px;
			border-right: solid 1px #dda301;

			img { display: none; }

		}

	}

	.adminToggle:before {
		content: 'Admin Panel';
	}

}

.desktop.logged_in.scrolling {

	#siteHeader {
		width: 100%;
		max-width: 100%;
		height: 30px;
		position: fixed;
		background: white;
		z-index: 999;
		box-shadow: 0 0 10px rgba(0,0,0,.3);

		#adminNav,
		.adminToggle {
			position: fixed;
		}

		.adminToggle {
			right: calc(50% - 100px);
		}

		#adminNav { right: 50%; }

	}
}

// Display Body Header
h2#displayBodyHeader {
	background: transparent;
	color: $mainColor;
	font-family: $fontOne;
	border-bottom: solid 1px $gray;

	span {
		font-weight: $bold;
		text-transform: none;
		font-size: 2.5rem;
		line-height: normal;
		padding: 10px 0;
	}

	.orgTitle { display: none; }

}

// Clickable Items
.clickable {

	.pageElement {
		border: none;
		border-bottom: solid 4px $gray;
		transition: $transition-standard;
	}

	&:hover .pageElement { border-bottom: solid 4px $green; }

}

// Custom Backgrounds
.gray,
.red,
.blue {

	.pageElement { padding: 30px; }

	.contentTabs.layoutContainerTabs {
		height: 45px !important;
		padding-top: 10px;
		margin: 0 30px;
	}

}

.gray .pageElement { background: $gray; }

.blue .pageElement { background: $mainColor; }

.red .pageElement { background: $accentColor; }



// Social Media
.beSocial {

	a {
		color: white;
    text-indent: -9999px;
    position: relative;
    height: 30px;
    width: 30px;
    float: left;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 6px;
    transition: $transition-standard;

    &[href*='facebook'] { @include bgImg('connect/facebook.svg'); }
    &[href*='twitter'] { @include bgImg('connect/twitter.svg'); }
		&[href*='youtube'] { @include bgImg('connect/youtube.svg'); }
		&[href*='instagram'] { @include bgImg('connect/instagram.svg'); }
		&[href*='vimeo'] { @include bgImg('connect/vimeo.svg'); }
		&[href*='vine'] { @include bgImg('connect/vine.svg'); }
		&[href*='google'] { @include bgImg('connect/google.svg'); }

		&:last-of-type { margin-right: 0; }

		&:hover { opacity: .75; }

	}

}

// Stay Connected
.stayConnected {

	.pageElement {
		background: $accentColor;
		padding: 50px 30px 60px;

		h3 {
			color: white;
			font-family: $fontOne;
			font-size: 2rem;
			margin-bottom: 2%;

			span {
				font-weight: $semibold;
				color: white;
			}

		}

	}

	a {
		color: white;
    text-indent: -9999px;
    position: relative;
    height: 60px;
    width: 70px;
    display: inline-block;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    transition: $transition-standard;

    &[href*='facebook'] { @include bgImg('connect/facebook_white.svg'); }
    &[href*='twitter'] { @include bgImg('connect/twitter_white.svg'); }
		&[href*='youtube'] { @include bgImg('connect/youtube_white.svg'); }
		&[href*='instagram'] { @include bgImg('connect/instagram_white.svg'); }
		&[href*='vimeo'] { @include bgImg('connect/vimeo_white.svg'); }
		&[href*='vine'] { @include bgImg('connect/vine_white.svg'); }
		&[href*='google'] { @include bgImg('connect/google_white.svg'); }

		&:last-of-type { margin-right: 0; }

		&:hover { opacity: .75; }

	}

}

// Media Queries

@media screen and (max-width: 1450px) {

	.desktop {

		#topNav,
		#network { padding: 0 20px; }

	}

}

@media screen and (max-width: 1416px){

	#pageContentContainer { padding: 15px; }

}

@media screen and (max-width: 1024px){

	// .logged_in.scrolling #siteHeader { display: none; }

	#network { display: none; }

	.siteContainer#siteContainer2.two_panel {

		#panelOne {
			max-width: 100%;

			#leftHandGraphics {
		    display: inline-block;
		    margin: 0 calc(50% - 150px);
			}

		}

		#panelTwo { max-width: 100%; }

		#pageContentContainer { padding: 15px; }

	}

	#pageContentContainer { padding: 15px; }

	.siteContainer#siteContainer2.two_panel #panelTwo { margin-right: 0; }

	.siteContainer#siteContainer2.two_panel #pageContentContainer { padding-right: 0; }

}

// NEW RIGHT-RAIL
.user_mode.right-rail-layout {

	#panelTwo {
		width: calc(100% - 300px) !important;
	}

	#panelThree {
		float: left;
    max-width: 300px;
    padding-top: 15px;
	}

	@media only screen and (max-width: 768px){
		#panelTwo { width: 100% !important; }
		#panelThree { max-width: 100%; }
	}

}

// Media Queries

@media screen and (max-width: 768px) {

	.column { width: 100% !important; }

	#siteHeader {

		#adminNav,
		.adminToggle { display: none; }

	}

	#accountNav { display: none; }

	.gray, .red, .blue {

		.pageElement { padding: 15px; }

	}

	.layoutContainer {
		padding-left: 0;

	}

	.gameShow #siteContainer2.one_panel #panelTwo { max-width: 100%; }

}

.textBlockElement {
	.text {
		h1, h2, h3, h4, h5, h6 {
			a {
				color: #ffb005 !important;
			}
		}
	}
}
