// *** Navigation Styles *** //



#topNav {
	height: auto;
	background: $black;
	border-bottom: none;

	.beSocial {
		padding: 35px 0;
  	height: 100px;
  	display: inline-block;
  	float: right;
	}

	table {
		@include center(1340px);

		tr {

			td {
				padding: 30px 0;
				float: left;
				margin-right: 1.5%;

				&.disabled {

					a { color: #777777; }

				}

				// Home Link
				&#homeLink {
					padding: 0 1% 0 0;
					margin-right: 0;

					a {
						height: 100px;
						width: 150px;
						display: inline-block;
						padding: 0;
						@include bgImg('logo_new.png');
						// @include bgImg('logo.svg');
						background-size: auto 85%;
						background-repeat: no-repeat;
						background-position: left center;
						transition: $transition-standard;

						&:after { display: none; }

						&:hover,
						&.hover {
							opacity: .85;

							&:after { display: none; }

						}

					}

				}

				> a {
					background: transparent;
					position: relative;
					font-family: $fontTwo;
					text-transform: uppercase;
					font-weight: 400;
					letter-spacing: .025rem;
					border-left: none;
					border-right: none;
					display: inline-block;
					padding: 5px 0;
					transition: $transition-standard;
					font-size: 12px;
    			letter-spacing: .1rem;

					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						width: 100%;
						height: 4px;
						background: $black;
						bottom: 0;
						left: 0;
						transition: $transition-standard;
					}

					// Link Hover
					&:hover,
					&.hover {

						&:after { background: white; }

					}

				}

				// Get rid of default hover styles
				&.selected,
				&:hover,
				&.hover {

					> a {
						border-right: none;
						border-left: none;
						box-shadow: none;
						color: white;
						background: transparent;

						&:after { background: white; }

					}

				}

				// Dropdown Styles
				ul {
					background: $light;
					border-bottom: solid 3px $gray;

					.dropdown-disclosure { display: none !important; }

					&.dropdown {
						margin-left: 0;
						left: 0;
						border-bottom: solid 3px $gray;
						padding: 0;
						box-shadow: none;

						&:before { display: none; }

					}

					li {
						border-right: solid 4px $light;
						transition: $transition-standard;

						&:hover {
							border-right: solid 4px $green;

							a { background: $light; }

						}

						&:first-of-type { padding-top: 5px; }

						&:last-of-type { padding-bottom: 5px; }

						a {
							color: $coal;
							font-family: $fontTwo;
							font-weight: 600;
							font-size: .75rem;
							letter-spacing: .05rem;

							&.disabled { color: #777777; }

						}

						ul {
							background: $light;
							top: 0;
							left: calc(100% + 4px);
							box-shadow: none;
							border-bottom: solid 3px $gray;
							padding: 0;

							li {

								a {
									color: $black !important;
									font-family: $fontTwo;
									font-weight: 600;
								}

								&:hover {
									border-right: solid 4px $green;

									a { background: $light; }

								}

							}
						}
					}
				}

			} // End td
		}
	}
}

&.logged_in #topNav table tr td {
	margin-right: 10px;

	> a {
		font-size: .6rem;
	}

}



// .desktop.scrolling {

// 	#topNav {
// 		width: 100%;
// 		position: fixed;
// 		top: 0;
// 		z-index: 999;

// 		table tr {

// 			td {
// 				padding: 8px 0;

// 				&.selected,
// 				&:hover {

// 					> a {
// 						color: white;

// 						&:after { display: none; }

// 					}

// 				}

// 				&#homeLink {
// 					padding: 2px 5px 0 0;
//     			margin-right: 2%;

// 					a {
// 						height: 50px;
// 						width: 100px;
// 					}

// 				}

// 			}

// 		}

// 		.beSocial {
// 			padding: 12px 0;
// 			height: 50px;
// 		}

// 	}

// }

// .edit_mode {

// 	#topNav .beSocial { display: none; }

// 	&.scrolling {

// 		#topNav .beSocial { display: none; }

// 	}

// }

// Search Modal
#navLinksKill {
  background: transparent;

  span span {
    color: white;
    font-family: $fontTwo;
    text-shadow: none;
    font-weight: normal;
    display: none;
  }

}

#navLinksForm {
  background: $black;
  border-bottom: none;
  border-radius:0;
  padding: 10px;

  input {
    font-size: 14px;
    padding: 5px;
    width: 100%;
  }

}

#navLinksContainer {
  box-shadow: none;
  background: $gray;
  width: 100%;
  max-width: 200px;
  border-radius: 0;
}

#navLinksResults {
	border-radius: 0 !important;

  li {
  	font-family: $fontTwo;

    a {
      background: $gray;
      padding: 5px 15px;
      border-bottom: 1px solid $dark;
      transition: $transition-standard;

      b {
        color: $coal;
        text-transform: none;
        font: 400 0.813rem/1.5rem $fontTwo;
        line-height: normal;
      }

      small {
        color: $dark;
      }

      &.selected {
	      background: transparent;
	      border-bottom: none;
	      border-bottom: 2px solid #8c9192;

	      b, small { color: $coal; }

	    }

	  &:hover {
	  	background: $light;
	  	color: $coal;
	  	border-bottom: 1px solid #8c9192;

	  	b, small { color: $coal; }

	  }

    }

  }

}

p#navLinksStatus {
  background: $mainColor;
  color: white;
  font-family: $fontOne;
  font-size: 1rem;
  padding: 5px 0 5px 15px;
  border-bottom: none;

  #navLinksShowAll {
    background: $accentColor;
    text-shadow: none;
    text-decoration: none;
    font-family: $fontOne;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
    margin: 0;
    box-shadow: none;
    padding: 8px 10px 9px;
    margin-top: -5px;
  }

}

// Netwirk Bar

// Network

#network {
	height: 50px;
	background: $black;
	border-bottom: solid 1px $coal;
	display: none;

	.networkTeams {
		@include center($maxWidth);
		height: 50px;

		a {
			height: 50px;
			float: left;
			text-align: center;
			margin: 0 4px;
			line-height: 67px;
			transition: $transition-standard;

			&:hover { opacity: .75; }

			img { height: 65%; }

		}

	}

}


// Network Dropdown Menu

#home {

	#networkDropMenu {

		.bar { @include center(1340px); }

		.menuWrap { @include center(1370px); }

	}

}

#networkDropMenu {
	height: 35px;
	background: $black;
	position: relative;
	z-index: 4;
	border-bottom: solid 1px $coal;

	.bar {
		@include center($maxWidth);
		position: relative;
	}

	#dropBtn {
		height: 35px;
		line-height: 35px;
		color: white;
		display: block;
		font-family: $fontTwo;
		font-size: 12px;
		color: $gray;
		float: left;
		transition: $transition-standard;

		&:after {
			content: '\f107';
			display: inline-block;
			margin-left: 5px;
			font-family: $fa;
		}

		&.opened {
			color: $green;

			&:after { content: '\f106'; }

		}

		&:hover { cursor: pointer; }

	}

	.menuWrap {
		@include center($maxWidth);
		top: 35px;
	}

	.networkDropTeams {
		position: absolute;
		padding: 30px 30px 10px 30px;
		background: white;
		top: 0;
		left: 0;
		border: solid 1px $gray;
		border-top: none;
		width: 100%;
		display: none;

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			height: 75%;
    	width: 1px;
    	background: $light;
    	left: 48%;
    	top: 15%;
		}

		h1 {
			font-family: $fontOne;
			font-size: 1.5rem;
			font-weight: 600;
			color: $mainColor;
			width: 45%;
			float: left;
			margin-bottom: 25px;

			&.westTitle {
				margin-right: 10%;
			}

		}

		.pageElement.heroPhotoElement {
			margin: 0 0 20px 0;
			float: left;
			clear: none;
			width: 33.333333333%;

			.cutline {
				font-family: $fontTwo;
				color: $coal;
				font-style: normal;
				line-height: 40px;
				font-size: 12px;
			}

		}

		a {
			height: 45px;
			width: 60px;
			float: left;
			transition: $transition-standard;
			margin-right: 5px;

			&:hover { opacity: .75; }

			img { height: 100%; }

		}

		.west,
		.east {
			width: 45%;
			float: left;
		}

		.west { margin-right: 10%; }

	}

}


// Media Queries

@media screen and (max-width: 1400px) {

 	#home #topNav { padding: 0 15px !important; }

	#home #networkDropMenu { padding: 0 15px; }

}

@media screen and (max-width: 1200px) {

	#topNav .beSocial { display: none; }

	#topNav { padding: 0 15px !important; }

	#networkDropMenu { padding: 0 15px; }

}

@media screen and (max-width: 1024px) {

	#networkDropMenu { display: none; }

}
