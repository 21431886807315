// *** Call to Action Styles *** //



// Standard Call to Action
.pageElement {

	.sn-call-to-action {
		background: black;
		height: auto !important;

		img { 
			opacity: .8;
			filter: blur(1px);
			transition: $transition-standard;
			width: calc(100% + 1px);
			height: auto !important;
		}

		&:hover {

			img { 
				filter: blur(0); 
				opacity: .9;
			}

		}

		.sn-call-to-action-title {
		    font-size: .75rem;
		    background: $accentColor;
		    padding: 5px 10px;
		    display: inline-block;
		    font-family: $fontTwo;
		    margin-bottom: 15px;
		    letter-spacing: .05rem;
		}

		p.sn-call-to-action-subtitle {
			font-family: $fontOne;
			font-size: 3vw;
			font-weight: 600;

			span { line-height: normal; }

		}

		&.removeTitle { 

			.sn-call-to-action-title { display: none; }

		}

	}

}	

// Custom Text Block Call to Action
.customCta {

	.pageElement.textBlockElement {
		background: white;
		padding: 30px;
		transition: $transition-standard;

		&:hover {

			.leftTextImage,
			.rightTextImage { border: solid 8px $accentColor; }

			.text p a { 
				color: $mainColor; 

				&:after { color: $accentColor; }

			}

		}

		.leftTextImage,
		.rightTextImage {
			position: relative;
			height: 150px;
			width: 150px;
			border-radius: 100px;
			margin: 0 calc(50% - 75px);
			overflow: hidden;
			margin-bottom: 15px;
			border: solid 3px $coal;
			transition: $transition-standard;

			img { 

				&.wide { 
					height: 100%; 
					width: auto;
					position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				}

				&.tall {
					height: auto;
					width: 100%;
				}

			}

		}	

		.text {

			p {
				font-family: $fontOne;
				color: $mainColor;
				font-size: 2.5vw;
				font-weight: $semibold;
				line-height: normal;
				max-width: 75%;
				margin: auto;

				a {
					color: $accentColor;
					font-size: 1.25rem;
					font-weight: $bold;
					text-decoration: none;
					position: relative;
					display: block;
					transition: $transition-standard;

					&:before {
						content: '';
						position: absolute;
						display: inline-block;
						width: 90%;
						height: 2px;
						background: $accentColor;
						left: 5%;
						top: -25px;
					}

					&:after {
						content: '\f054';
						font-family: $fa;
						display: inline-block;
						position: relative;
						margin-left: 7px;
						font-size: 1rem;
						color: $accentColor;
					}
				}

			}

		}
	}

}

// Alternative Call to Action Style
.altCta {

	.pageElement .sn-call-to-action {

		img { 
			opacity: .7;
			filter: blur(0); 
		}

		&:hover img { opacity: .9; }

		.sn-call-to-action-overlay-text {
			vertical-align: bottom;

			p.sn-call-to-action-subtitle { 
				margin-bottom: 0; 
				text-align: left;
				font-weight: $regular;
			}

		}

		.sn-call-to-action-title {
			display: block;
			background: transparent;
			text-align: left;
			font-size: 4vw;
			font-weight: $semibold;
			margin-bottom: 0;
			padding: 0 10px;
			line-height: normal;
		}

	}	

}


// Media Queries

@media screen and (max-width: 768px) {

	.customCta .pageElement.textBlockElement .text p { font-size: 7.5vmin; }

	.altCta .pageElement .sn-call-to-action {

		.sn-call-to-action-title { font-size: 10vmin; }

		.sn-call-to-action-subtitle { font-size: 8vmin; }

	}	

	.pageElement .sn-call-to-action p.sn-call-to-action-subtitle span { font-size: 8vmin; }

}



















