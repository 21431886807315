//*** PDL Responsive Styles ***//

// Banner Top
.bannerWrap {
	display: none;

	.bannerTitle {
		float: left;
		color: white;
		font-family: $fontOne;
		line-height: 50px;
		width: 70%;
    text-align: center;
    display: none;
	}

	.bannerLogo {
		width: 15%;
    display: block;
    height: 50px;
    float: left;
    @include bgImg('logo_new.png');
    background-size: auto 70%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: calc(100% - 100px);
    margin: 0 50px;
	}

}


// Mobile Nav
#menuBtn{
	padding: 10px;
	cursor: pointer;
	display: none;
	height: 50px;
	position: relative;
	display: none;
	width: 50px;
	float: left;

	span{
		display: block;
		height: 30px;
		width: 30px;
		z-index: 3;
		padding-top: 11px;
    margin: auto;
    padding-left: 2px;

		i, i:after, i:before{
			background-color: #fff;
			border-radius: 1px;
			content: '';
			display: block;
			height: 3px;
			max-width: 25px;
			position: absolute;
			transition: $transition-standard;
			width: 100%;
		}

		i:before {
			top: -7px;
		}

		i:after {
			bottom: -7px;
		}

	}
}

#playerMenu {
  height: 50px;
  width: 60px;
  float: right;
  position: relative;
  margin-right: 10px;
  display: none;

  // &:before {
  //   content: '';
  //   font-family: $fa;
  //   position: absolute;
  //   height: 50px;
  //   width: 50px;
  //   color: white;
  //   text-align: center;
  //   line-height: 40px;
  //   font-size: 1.5rem;
  //   @include bgImg('player-silhouette.svg');
  //   background-size: 50%;
  //   background-repeat: no-repeat;
  //   background-position: center 8px;
  // }

  &:after {
    content: 'USL Network';
    color: white;
    width: 60px;
    height: 50px;
    display: inline-block;
    bottom: 5px;
    right: 0;
    text-align: center;
    font-size: 10px;
    position: absolute;
    line-height: 60px;
  }

}

// Mobile Search

.mobile {

  #navLinksContainer {
    position: relative;
    max-width: 100% !important;
    border-radius: 0;
    opacity: 0;
    z-index: -999;
    left: 0 !important;
    top: 2px !important;
  }

  #navLinksStatus,
  #navLinksShowAll,
  #navLinksForm { background: $black !important; }

  #navLinksForm input { font-family: $fontOne; }

  #navLinksResults li a {
    border-radius: 0;
    border-bottom: 1px solid $dark !important;
  }

  #navLinksKill { display: none; }

  #navLinksLink {

    span {
      background: transparent;
      position: relative;

      span span {
        background: transparent;
        padding-right: 0;

        &:before {
          content: '\f002';
          display: inline-block;
          font-family: $fa;
          color: $accentColor;
          margin-left: 5px;
          font-size: 12px;
          position: absolute;
          left: -25px;
          transform: rotate(90deg);
        }

      }

    }

  }

}

.desktop.scrolling {

  #topNav {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;

    table tr {

      td {
        padding: 8px 0;

        &.selected,
        &:hover {

          > a {
            color: white;

            &:after { display: none; }

          }

        }

        &#homeLink {
          padding: 2px 5px 0 0;
          margin-right: 1%;

          a {
            height: 50px;
            width: 70px;
          }

        }

      }

    }

    .beSocial {
      padding: 12px 0;
      height: 50px;
    }

  }

}

#mobileNav{
  width: 100%;
  display: none;
  // top: 50px;
  position: relative;
  z-index: 9999;
  border-bottom: solid 4px $gray;

  #topNav{
    display: none;
    float: left;
    width: 100%;
    background: $light;

    .beSocial {
    	float: none;
    	width: 100%;
    	padding: 15px 0;
    	height: 60px;

    	p {
    		text-align: center;

    		a {
    			float: none;
    			display: inline-block;

          &[href*='facebook'] { @include bgImg('connect/facebook.svg'); }
          &[href*='twitter'] { @include bgImg('connect/twitter.svg'); }
          &[href*='youtube'] { @include bgImg('connect/youtube.svg'); }
          &[href*='instagram'] { @include bgImg('connect/instagram.svg'); }
          &[href*='vimeo'] { @include bgImg('connect/vimeo.svg'); }
          &[href*='vine'] { @include bgImg('connect/vine.svg'); }
          &[href*='google'] { @include bgImg('connect/google.svg'); }

    		}

    	}

    }

    .dropdown-disclosure{
      background: none;
    }

    table {
      width: 100%;

      tbody {

        tr {
          width: 100%;

          td {
            display: block;
            width: 100%;
            padding: 0;
            margin-right: 0;
            border-bottom: solid 1px $gray;

            &:hover,
            &.hover {

            	a { background: transparent; }

	            > a:after { display: none; }

          	}

            &.selected {

            	> a { background: transparent; }

            	> a:after { display: none; }

            }

            &#homeLink { display: none; }

            &:hover{

              ul{
                display: none;
              }

            }
            &.hover{

              ul{
                display: none;
              }

              li.hover{

                > a{
                  // color: #fff;

                  &:before{
                    color: #fff;
                  }

                }
              }
            }

            > a:after { display: none; }

            a{
              width: 100%;
              padding: 10px 5px;
              text-align: left;
              color: $black;

              &.disabled{
                opacity: 0.6;
              }

              span{
                  width: 92%;
                  display: inline-block;
              }

              &:before{
                display: inline-block;
                position: relative;
                width: 8%;
                content: '';
              }

              &.nested{

                span {
                  width: 92%;
                  display: inline-block;
                }

                &:before{
                  display: inline-block;
                  position: relative;
                  top:0px;
                  width: 8%;
                  content: '\f107';
                  @include transform(rotate(0deg));
                  color: $accentColor;
                  font:100 1.2em/1em $fa;
                  font-weight: bold;
                  text-align: center;
                  transition: $transition-standard;
                  opacity: 1;
                }

                &.listOpen {

                  &:before {
                    content: '\f106';
                    @include transform(rotate(360deg));
                    top:3px;
                    position: relative;
                    opacity: 1;
                  }

                }

              }
            }

            ul{
              width: 100%;
              position: relative;
              left:0;

              li {
              	border-right: 0;

              	&:first-of-type { padding-top: 0; }

              	&:last-of-type { padding-bottom: 0; }

              	> a.listOpen {
              		background-color: $accentColor !important;
              		color: white !important;

              		&:before { color: white !important; }

              	}

                > a{
                  padding: 10px;
                  background-color: white !important;
                  color: $accentColor;
                  border-top: 1px solid #efefef;

                  &:hover{
                    background-color: $accentColor;
                  }

                  &.nested{

                    &:before{
                      color: $accentColor;
                      font-weight: bold;
                    }

                  }
                }

                ul{
                	width: 100%;
                	position: relative;
                	left: 0;
                	border-bottom: none;

                   li{

                     a{
                        padding-left: 30px;
                        background-color: #efefef;
                        border-top: 1px solid #ccc;
                        color: $coal !important;

                     }

                   }
                }
              }
            }
          }
        }
      }
    }
  }
}

body.desktop #pageContentContainer { opacity: 1 !important; }

.menuOn{

  // #siteHeader { position: relative !important; }

  &.mobile #navLinksContainer {
    opacity: 1;
    z-index: 998;
  }

  &.desktop #pageContentContainer { opacity: 1; }

	#pageContentContainer { opacity: .2; }

  #menuBtn{

    span{

      i{
        background: $green;

        &:before, &:after{
          background: $green;
        }

      }

    }
  }
}

// Mobile Player Menu

.desktop {

  #playerMenuContainer { display: none !important; }

  .mobileSearch { display: none !important; }

  #mobileNav { border-bottom: none; }

  #siteHeader #navLinksContainer { display: none; }

}

#teamLogoContainer {
  display: none;
  position: relative;
  background: white;
  width: 100%;
  border-bottom: solid 4px $gray;
  padding: 15px 10%;

  .teamLogos {

    a {
      width: 50%;
      display: inline-block;
      height: 100px;
      text-align: center;
      padding: 5%;
      border-bottom: solid 1px $light;
      border-right: solid 1px $light;

      &:first-of-type,
      &:nth-of-type(2) { border-top: solid 1px $light; }

      &:nth-of-type(odd) { border-left: solid 1px $light; }

      img { height: 100%; }

    }

  }

}

.menuVisible #pageContentContainer { opacity: .2; }

// Media Queries

@media screen and (max-width: 1024px) {

	#menuBtn{
    display: inline-block;
  }

  #mobileNav{

    #topNav{
      display: block;
      padding: 0 !important;

      .beSocial { display: block; }

    }

  }

  #topNav{
    display: none;
  }

    #siteHeader{
    float: none;
    height: 50px;
    background: $black;
    // position: fixed;
  }

  .logged_in.scrolling #siteHeader {
    top: 0;
    background: $black;
    height: 50px;
    display: block;
  }

  .scrolling #siteHeader {
    background: $black;
    height: 50px;
    // position: fixed;
  }

  .bannerWrap{
  	display: block;

    .bannerSocial{
      display: none;
    }

  }

  .scoreboardBanner #masterScoreboard .scoreboardRibbon {

    .previous_button { margin-left: 0; }

    .next_button { margin-right: 0; }

  }

}

@media (min-width: 640px) and (max-width: 1024px) {

  #teamLogoContainer .teamLogos {

    a { padding: 2% 5%; }

  }

}

// Animations

.fadeIn {
  animation: opacity .25s ease-in;
}

@keyframes opacity {

  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}
