// *** Site Variables *** //

//Max Width
$maxWidth: 1200px;

$maxWidthHome: 1400px;


// Fonts
$fontOne: 'Orbitron', sans-serif;
$fontTwo: 'Roboto', sans-serif;
$fa: 'FontAwesome';

$extraBold: 800;
$bold: 700;
$semibold: 600;
$regular: 300;

// Colors
$black: #111111;
$coal: #2d2d2d;
$dark: #8c9192;
$gray: #c2c6ca;
$light: #eeeeee;

$mainColor: #111111;
$accentColor: #0b9444;
$green: #ffc423;
$darkRed: #096831;

// Transitions
$transition-standard: all .2s ease-in-out;


// Custom Aggregators
$aggPadding: 50px;

// Assets Path
$assetPath: 'http://app-assets2.sportngin.com';