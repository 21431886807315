@charset "UTF-8";
@-webkit-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@-moz-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@-o-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

html {
  overflow-x: hidden;
  font-size: 18px;
}

html, body {
  background: #eeeeee;
}

.siteContainer {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 100%;
}

.siteContainer#siteContainer2.one_panel #panelOne, .siteContainer#siteContainer2.two_panel #panelOne {
  width: 100%;
  max-width: 20%;
}

.siteContainer#siteContainer2.one_panel #panelTwo, .siteContainer#siteContainer2.two_panel #panelTwo {
  box-shadow: none;
  background: transparent;
}

.siteContainer#siteContainer2.one_panel #yieldContent, .siteContainer#siteContainer2.two_panel #yieldContent {
  background: transparent;
}

.siteContainer#siteContainer2.two_panel #panelOne {
  width: 100%;
  max-width: 300px;
}

.siteContainer#siteContainer2.two_panel #panelOne #leftHandGraphics {
  max-width: 300px;
}

.siteContainer#siteContainer2.two_panel #panelTwo {
  box-shadow: none;
  background: transparent;
  width: 100%;
  margin-left: 0;
  padding: 0;
}

.siteContainer#siteContainer2.two_panel #pageContentContainer {
  padding-top: 15px;
}

.newsPage .siteContainer#siteContainer2.two_panel #panelTwo {
  max-width: calc(100% - 315px);
  margin-right: 15px;
}

.gameShow #siteContainer2.one_panel #panelTwo {
  box-shadow: none;
  background: transparent;
  width: 100%;
  max-width: calc(100% - 315px);
  margin-right: 15px;
  margin-left: 0;
  padding: 0;
}

.user_mode #panelOne .subNavContainer {
  display: none;
}

.pageElement {
  font-family: "Roboto", sans-serif;
}

#pageContentContainer {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1200px;
  background: transparent;
  padding: 0;
}

#accountNav {
  background: #111111;
  border-bottom: solid 1px #2d2d2d;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

#accountNav li a {
  border-right: none;
  box-shadow: none;
}

#accountNav li a.secondary {
  box-shadow: none;
  border-right: none;
}

#accountNav .currentSite a#edit_membership {
  border-left: none;
}

#siteHeader {
  height: 0;
  min-height: 0;
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1200px;
  z-index: 3;
}

#siteHeader h1,
#siteHeader .bannerGraphicTextBanner h1 {
  display: none;
}

#siteHeader .adminToggle,
#siteHeader #adminNav a {
  background: #FCCD22;
  z-index: 999;
  height: 30px;
  transition: all 0.2s ease-in-out;
}

#siteHeader .adminToggle:before,
#siteHeader #adminNav a:before {
  content: 'Edit/User';
  display: block;
  position: absolute;
  height: 30px;
  width: 100%;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #111111;
  text-indent: 0;
  transition: all 0.2s ease-in-out;
}

#siteHeader .adminToggle:hover,
#siteHeader #adminNav a:hover {
  background: #8c9192;
}

#siteHeader .adminToggle:hover:before,
#siteHeader #adminNav a:hover:before {
  color: white;
}

#siteHeader #adminNav,
#siteHeader .adminToggle {
  height: 30px;
  top: 0;
  position: absolute;
  width: 100px;
}

#siteHeader .adminToggle {
  border-left: solid 1px #dda301;
}

#siteHeader #adminNav {
  right: 100px;
}

#siteHeader #adminNav a {
  width: 100px;
  border-right: solid 1px #dda301;
}

#siteHeader #adminNav a img {
  display: none;
}

#siteHeader .adminToggle:before {
  content: 'Admin Panel';
}

.desktop.logged_in.scrolling #siteHeader {
  width: 100%;
  max-width: 100%;
  height: 30px;
  position: fixed;
  background: white;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.desktop.logged_in.scrolling #siteHeader #adminNav,
.desktop.logged_in.scrolling #siteHeader .adminToggle {
  position: fixed;
}

.desktop.logged_in.scrolling #siteHeader .adminToggle {
  right: calc(50% - 100px);
}

.desktop.logged_in.scrolling #siteHeader #adminNav {
  right: 50%;
}

h2#displayBodyHeader {
  background: transparent;
  color: #111111;
  font-family: "Orbitron", sans-serif;
  border-bottom: solid 1px #c2c6ca;
}

h2#displayBodyHeader span {
  font-weight: 700;
  text-transform: none;
  font-size: 2.5rem;
  line-height: normal;
  padding: 10px 0;
}

h2#displayBodyHeader .orgTitle {
  display: none;
}

.clickable .pageElement {
  border: none;
  border-bottom: solid 4px #c2c6ca;
  transition: all 0.2s ease-in-out;
}

.clickable:hover .pageElement {
  border-bottom: solid 4px #ffc423;
}

.gray .pageElement,
.red .pageElement,
.blue .pageElement {
  padding: 30px;
}

.gray .contentTabs.layoutContainerTabs,
.red .contentTabs.layoutContainerTabs,
.blue .contentTabs.layoutContainerTabs {
  height: 45px !important;
  padding-top: 10px;
  margin: 0 30px;
}

.gray .pageElement {
  background: #c2c6ca;
}

.blue .pageElement {
  background: #111111;
}

.red .pageElement {
  background: #0b9444;
}

.beSocial a {
  color: white;
  text-indent: -9999px;
  position: relative;
  height: 30px;
  width: 30px;
  float: left;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 6px;
  transition: all 0.2s ease-in-out;
}

.beSocial a[href*='facebook'] {
  background-image: url("../images/connect/facebook.svg");
}

.beSocial a[href*='twitter'] {
  background-image: url("../images/connect/twitter.svg");
}

.beSocial a[href*='youtube'] {
  background-image: url("../images/connect/youtube.svg");
}

.beSocial a[href*='instagram'] {
  background-image: url("../images/connect/instagram.svg");
}

.beSocial a[href*='vimeo'] {
  background-image: url("../images/connect/vimeo.svg");
}

.beSocial a[href*='vine'] {
  background-image: url("../images/connect/vine.svg");
}

.beSocial a[href*='google'] {
  background-image: url("../images/connect/google.svg");
}

.beSocial a:last-of-type {
  margin-right: 0;
}

.beSocial a:hover {
  opacity: .75;
}

.stayConnected .pageElement {
  background: #0b9444;
  padding: 50px 30px 60px;
}

.stayConnected .pageElement h3 {
  color: white;
  font-family: "Orbitron", sans-serif;
  font-size: 2rem;
  margin-bottom: 2%;
}

.stayConnected .pageElement h3 span {
  font-weight: 600;
  color: white;
}

.stayConnected a {
  color: white;
  text-indent: -9999px;
  position: relative;
  height: 60px;
  width: 70px;
  display: inline-block;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.stayConnected a[href*='facebook'] {
  background-image: url("../images/connect/facebook_white.svg");
}

.stayConnected a[href*='twitter'] {
  background-image: url("../images/connect/twitter_white.svg");
}

.stayConnected a[href*='youtube'] {
  background-image: url("../images/connect/youtube_white.svg");
}

.stayConnected a[href*='instagram'] {
  background-image: url("../images/connect/instagram_white.svg");
}

.stayConnected a[href*='vimeo'] {
  background-image: url("../images/connect/vimeo_white.svg");
}

.stayConnected a[href*='vine'] {
  background-image: url("../images/connect/vine_white.svg");
}

.stayConnected a[href*='google'] {
  background-image: url("../images/connect/google_white.svg");
}

.stayConnected a:last-of-type {
  margin-right: 0;
}

.stayConnected a:hover {
  opacity: .75;
}

@media screen and (max-width: 1450px) {
  .desktop #topNav,
  .desktop #network {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1416px) {
  #pageContentContainer {
    padding: 15px;
  }
}

@media screen and (max-width: 1024px) {
  #network {
    display: none;
  }
  .siteContainer#siteContainer2.two_panel #panelOne {
    max-width: 100%;
  }
  .siteContainer#siteContainer2.two_panel #panelOne #leftHandGraphics {
    display: inline-block;
    margin: 0 calc(50% - 150px);
  }
  .siteContainer#siteContainer2.two_panel #panelTwo {
    max-width: 100%;
  }
  .siteContainer#siteContainer2.two_panel #pageContentContainer {
    padding: 15px;
  }
  #pageContentContainer {
    padding: 15px;
  }
  .siteContainer#siteContainer2.two_panel #panelTwo {
    margin-right: 0;
  }
  .siteContainer#siteContainer2.two_panel #pageContentContainer {
    padding-right: 0;
  }
}

.user_mode.right-rail-layout #panelTwo {
  width: calc(100% - 300px) !important;
}

.user_mode.right-rail-layout #panelThree {
  float: left;
  max-width: 300px;
  padding-top: 15px;
}

@media only screen and (max-width: 768px) {
  .user_mode.right-rail-layout #panelTwo {
    width: 100% !important;
  }
  .user_mode.right-rail-layout #panelThree {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .column {
    width: 100% !important;
  }
  #siteHeader #adminNav,
  #siteHeader .adminToggle {
    display: none;
  }
  #accountNav {
    display: none;
  }
  .gray .pageElement, .red .pageElement, .blue .pageElement {
    padding: 15px;
  }
  .layoutContainer {
    padding-left: 0;
  }
  .gameShow #siteContainer2.one_panel #panelTwo {
    max-width: 100%;
  }
}

.textBlockElement .text h1 a, .textBlockElement .text h2 a, .textBlockElement .text h3 a, .textBlockElement .text h4 a, .textBlockElement .text h5 a, .textBlockElement .text h6 a {
  color: #ffb005 !important;
}

#splash #siteHeader #menuBtn,
#splash #siteHeader #playerMenu,
.newsPage #siteHeader #menuBtn,
.newsPage #siteHeader #playerMenu {
  display: none;
}

#home #displayBodyHeader {
  display: none;
}

#home #pageContentContainer {
  max-width: 1400px;
}

#home #pageContentContainer #prospectsContainer {
  width: 30%;
  display: block;
  float: left;
}

#home #network .networkTeams {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1400px;
  padding: 0 25px;
}

#home #siteHeader {
  max-width: 1400px;
}

#home #topNav table {
  max-width: 1340px;
}

#home #siteContainer2.one_panel {
  margin-left: 0;
}

#home #siteContainer2.one_panel#yieldContent,
#home #siteContainer2.one_panel .yieldPageContent {
  padding: 0;
}

#home #siteContainer2.two_panel #panelTwo {
  margin-right: 0;
  max-width: calc(100% - 300px);
}

#home #siteContainer2.two_panel #panelTwo .layoutContainer {
  padding-left: 0;
}

#home.topProspects #panelTwo {
  max-width: 70%;
}

#home.topProspects #siteFooter #footerUpdates {
  display: block;
}

#home .largeHeader {
  font-size: 2.5rem;
  padding: 30px 0 0 30px;
  color: #2d2d2d;
}

#home #siteFooter #footerUpdates .updates,
#home #siteFooter #megaFooter {
  max-width: 1400px;
}

#home #siteFooter #footerUpdates {
  display: block;
}

#home #sidePanelContainer .homeSidePanel {
  padding-left: 0;
}

#home #sidePanelContainer .homeSidePanel .pageElement {
  margin-right: 0;
}

#home #sidePanelContainer .adOne,
#home #sidePanelContainer .adTwo {
  margin-bottom: 15px;
}

#home #sidePanelContainer .twitterFeed {
  margin-bottom: 8px;
}

#assets.user_mode .players .pageEl {
  width: 33.3333333%;
  display: inline-block;
  margin-bottom: 10px;
}

#assets.user_mode .players .pageEl .pageElement {
  background: white;
}

#assets.user_mode .footerRight .heroPhotoElement img {
  max-width: 250px;
}

#assets.edit_mode .players .pageEl {
  width: 100%;
}

#assets.edit_mode .players .pageEl .pageElement {
  background: white;
  padding: 0 15px;
  position: relative;
}

#assets.edit_mode .players .pageEl .pageElement .text {
  margin-top: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

#assets.edit_mode .players .pageEl .pageElement .text h1, #assets.edit_mode .players .pageEl .pageElement .text h2, #assets.edit_mode .players .pageEl .pageElement .text h3, #assets.edit_mode .players .pageEl .pageElement .text h4 {
  text-transform: uppercase;
  line-height: normal;
}

#assets.edit_mode .players .pageEl .pageElement .text h1 {
  color: #111111;
  font-size: 4vw;
}

#assets.edit_mode .players .pageEl .pageElement .text h2 {
  font-size: 1vw;
}

#assets.edit_mode .players .pageEl .pageElement .text h3 {
  color: #2d2d2d;
}

#assets.edit_mode .players .pageEl .pageElement .text h4 {
  color: #2d2d2d;
  font-size: 3vw;
  margin-top: -10px;
}

#assets.edit_mode .players .drag {
  width: 33.3333333%;
  float: left;
  margin-bottom: 10px;
}

#assets.edit_mode .players .tabContainer .drag:first-of-type {
  width: 33.3333333%;
}

#team-logos .networkLogos .heroPhotoElement img {
  max-width: 100px;
}

.gameShow #sidePanelContainer {
  margin: 0;
  float: left;
}

.user_mode.newsPage h2#displayBodyHeader {
  display: none;
}

.user_mode.newsPage .newsTags a {
  font-size: .7rem;
  transition: all 0.2s ease-in-out;
}

.user_mode.newsPage .newsTags a:hover {
  opacity: .75;
}

.user_mode.newsPage .bottomOptions .pageOptions li a {
  font-size: 12px;
}

.user_mode.newsPage .layoutContainer.newsItemColumn {
  margin: 0 0 1.6875rem;
}

.user_mode.newsPage .layoutContainer .pageElement.newsItemElement {
  border-bottom: 1px solid #ffc423;
}

.user_mode.newsPage .layoutContainer .heroPhotoElement img {
  margin-bottom: 1.6875rem;
}

.user_mode.newsPage .newsItemElement h2 {
  font-family: "Orbitron", sans-serif;
  font-size: 3rem;
  color: #111111;
  font-weight: 700;
}

.user_mode.newsPage .newsItemElement .newsDate {
  font-family: "Roboto", sans-serif;
  font-size: .8rem;
  color: #8c9192;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
  padding-right: 5px;
}

.user_mode.newsPage .newsItemElement h4 {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: #2d2d2d;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em;
}

.user_mode.newsPage .newsItemElement .newsAuthor {
  font-family: "Roboto", sans-serif;
  font-size: .75rem;
  font-weight: 500;
  color: #8c9192;
  padding-left: 10px;
  border-left: solid 1px #c2c6ca;
}

.user_mode.newsPage .textBlockElement p {
  font-family: "Orbitron", sans-serif;
  color: #222;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 1.5em;
  font-weight: 400;
}

.user_mode.newsPage .textBlockElement h2, .user_mode.newsPage .textBlockElement h1 {
  font-family: "Orbitron", sans-serif;
  color: #15315a;
  font-size: 1.6875rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0;
}

.user_mode.newsPage .textBlockElement h3 {
  font-family: "Orbitron", sans-serif;
  color: #111111;
  font-size: 1.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.user_mode.newsPage .textBlockElement a {
  color: #ffb005;
  font-weight: 700;
  text-decoration: none;
  border-bottom: transparent solid 3px;
  transition: all 0.25s ease-out;
}

.user_mode.newsPage .textBlockElement a:hover {
  border-bottom: 3px solid;
  transition: all 0.25s ease-in;
}

.user_mode.newsPage .pageElement h3 {
  font-family: "Orbitron", sans-serif;
  color: #111111;
  font-size: 1.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.user_mode.newsPage .paginationElement,
.user_mode.newsPage .directorySearchElement,
.user_mode.newsPage .filterElement {
  background: none;
}

.user_mode.newsPage .paginationElement h4 {
  font-family: "Orbitron", sans-serif;
  color: #222;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.user_mode.newsPage .paginationElement .results {
  font-family: "Orbitron", sans-serif;
  color: #8c9192;
  font-size: .75rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.user_mode.newsPage .pagination {
  font-family: "Orbitron", sans-serif;
  color: #222;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.user_mode.newsPage .pagination a {
  color: #ec1c2d;
}

.user_mode.newsPage .user_filters select {
  font-family: "Orbitron", sans-serif;
  color: #222;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 400;
}

.user_mode.newsPage .user_filters .button-small {
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  background: #ec1c2d;
  border-radius: 0;
  color: #fff;
  font-size: 1em;
  padding: .25em .5em;
  box-shadow: none;
  text-shadow: none;
}

.user_mode.newsPage .user_filters .button-small:hover {
  background: #d0112b;
}

.user_mode.newsPage #siteFooter {
  background: black;
}

.user_mode.newsPage #siteFooter ul {
  text-align: center;
}

.user_mode.newsPage #siteFooter #poweredByNGIN {
  float: none;
  width: 100%;
  margin: 4px 0;
}

.user_mode .socialSharing {
  margin-left: 15px;
}

.user_mode .socialSharing li {
  width: 40px;
  height: 39px;
  background-color: #999;
  display: inline-block;
  margin: 0 5px 5px 0;
  float: left;
}

.user_mode .socialSharing li:hover {
  opacity: .75;
}

.user_mode .socialSharing li a {
  width: 40px;
  height: 39px;
  display: block;
  transition: all 0.2s ease-in-out;
}

.user_mode .socialSharing .socialTitle {
  display: none;
}

.user_mode .socialSharing .socialComments {
  background-image: url("../images/connect/socialComments.svg");
  background-position: center;
  background-color: #8c9192;
  display: none;
  background-repeat: no-repeat;
}

.user_mode .socialSharing .socialFacebook {
  background-image: url("../images/connect/socialFacebook.svg");
  background-position: center;
  background-size: auto 50%;
  background-color: #3b5998;
  background-repeat: no-repeat;
}

.user_mode .socialSharing .socialTwitter {
  background-image: url("../images/connect/socialTwitter.svg");
  background-position: center;
  background-color: #019FE9;
  background-size: 50% auto;
  background-repeat: no-repeat;
}

.user_mode .socialSharing .socialEmail {
  background-image: url("../images/connect/socialEmail.svg");
  background-position: center;
  background-color: #76868D;
  background-size: 50% auto;
  background-repeat: no-repeat;
}

.user_mode .playerProfile {
  margin-bottom: 15px;
  margin-right: 15px;
}

.user_mode .playerProfile .rowOne,
.user_mode .playerProfile .rowTwo {
  position: relative;
}

.user_mode .playerProfile .layoutInner {
  border-bottom: solid 3px #0b9444;
  max-width: 855px;
}

.user_mode .playerProfile .pageEl {
  display: inline-block;
  float: left;
}

.user_mode .playerProfile .pageEl:last-of-type .pageElement {
  margin-right: 0;
}

.user_mode .playerProfile .pageEl .pageElement {
  margin-bottom: 0;
}

.user_mode .playerProfile .playerPhoto {
  width: 25%;
}

.user_mode .playerProfile .playerPhoto .heroPhotoElement {
  position: relative;
  text-align: center;
}

.user_mode .playerProfile .statsOne {
  width: 31%;
}

.user_mode .playerProfile .statsOne .textBlockElement h3 {
  color: #111111;
  margin-bottom: 10px;
}

.user_mode .playerProfile .statsOne .textBlockElement h3 span {
  font-size: 1rem;
}

.user_mode .playerProfile .statsOne .textBlockElement .text {
  margin-top: 0;
}

.user_mode .playerProfile .statsOne .textBlockElement .text .number {
  color: #111111;
  font-size: 4vw;
  font-weight: 600;
  margin-bottom: 10px;
}

.user_mode .playerProfile .statsOne .textBlockElement .text .name {
  color: #111111;
  font-weight: 600;
  font-size: 2vw;
  line-height: normal;
  margin-bottom: 0;
}

.user_mode .playerProfile .statsOne .textBlockElement .text .name strong {
  font-size: 2.5vw;
}

.user_mode .playerProfile .statsOne .textBlockElement .text .position {
  font-weight: 600;
  margin-top: -9px;
}

.user_mode .playerProfile .statsOne .textBlockElement .text .position u {
  color: #0b9444;
  text-decoration: none;
}

.user_mode .playerProfile .statsTwo {
  width: 25%;
}

.user_mode .playerProfile .statsTwo .pageElement.textBlockElement .text p {
  margin-bottom: 5px;
}

.user_mode .playerProfile .playerLogo {
  width: 15%;
  margin: 0 2%;
}

#assets.edit_mode .footerRight .heroPhotoElement img {
  max-width: 250px;
}

#assets.edit_mode .sidePanel .drag,
#assets.edit_mode .homeSidePanel .drag {
  width: 33.3333333333%;
  float: left;
}

#assets.user_mode .homeSidePanel .pageEl,
#assets.user_mode .sidePanel .pageEl {
  width: 33.3333333333%;
  float: left;
}

#assets.user_mode .homeSidePanel .pageEl .pageElement,
#assets.user_mode .sidePanel .pageEl .pageElement {
  margin: 0 15px 15px 0;
}

#assets.user_mode .homeSidePanel .pageEl .aggregatorElement.newsAggregatorElement .item > a,
#assets.user_mode .sidePanel .pageEl .aggregatorElement.newsAggregatorElement .item > a {
  display: none;
}

#assets.user_mode .homeSidePanel .pageEl .aggregatorElement.newsAggregatorElement .item h4 a,
#assets.user_mode .sidePanel .pageEl .aggregatorElement.newsAggregatorElement .item h4 a {
  color: #111111;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  transition: all 0.2s ease-in-out;
}

#assets.user_mode .homeSidePanel .pageEl .aggregatorElement.newsAggregatorElement .item h4 a:hover,
#assets.user_mode .sidePanel .pageEl .aggregatorElement.newsAggregatorElement .item h4 a:hover {
  color: #0b9444;
}

#assets.user_mode .homeSidePanel .pageEl .aggregatorElement.newsAggregatorElement .item .details,
#assets.user_mode .sidePanel .pageEl .aggregatorElement.newsAggregatorElement .item .details {
  padding-left: 0;
}

#assets.user_mode .homeSidePanel .pageEl .aggregatorElement.newsAggregatorElement .item .details .dateAuthor,
#assets.user_mode .sidePanel .pageEl .aggregatorElement.newsAggregatorElement .item .details .dateAuthor {
  display: none;
}

#assets.user_mode .homeSidePanel .pageEl .aggregatorElement.newsAggregatorElement .item .commentsAndMore, #assets.user_mode .homeSidePanel .pageEl .aggregatorElement.newsAggregatorElement .item.extendedOptions,
#assets.user_mode .sidePanel .pageEl .aggregatorElement.newsAggregatorElement .item .commentsAndMore,
#assets.user_mode .sidePanel .pageEl .aggregatorElement.newsAggregatorElement .item.extendedOptions {
  display: none;
}

#assets.user_mode .homeSidePanel .pageEl .aggregatorElement.newsAggregatorElement .item:last-of-type,
#assets.user_mode .sidePanel .pageEl .aggregatorElement.newsAggregatorElement .item:last-of-type {
  border-bottom: none;
}

#sport-header #snm_advert {
  display: none;
}

@media only screen and (min-width: 1300px) {
  .user_mode .socialSharing {
    position: fixed;
    top: 270px;
    margin-left: -50px;
  }
  .user_mode .socialSharing ul {
    position: relative;
  }
  .user_mode .socialSharing li {
    display: block;
    margin: 5px auto;
    float: none;
  }
  .user_mode.newsPage .layoutContainer.newsItemColumn {
    margin: 0 0 1.6875rem;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .user_mode .playerProfile .statsOne {
    width: 55%;
  }
  .user_mode .playerProfile .statsOne .textBlockElement .text .number {
    line-height: 1rem;
  }
  .user_mode .playerProfile .statsOne .textBlockElement .text .name {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .user_mode .playerProfile .statsOne .textBlockElement .text .name strong {
    font-size: 2rem;
    line-height: 1.5rem;
  }
  .user_mode .playerProfile .statsTwo {
    width: 100%;
    padding-top: 10px;
    position: relative;
  }
  .user_mode .playerProfile .statsTwo:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    background: #0b9444;
    top: 0;
  }
  .user_mode .playerProfile .statsTwo .pageElement.textBlockElement .text {
    margin-bottom: 10px;
  }
  .user_mode .playerProfile .statsTwo .pageElement.textBlockElement .text p {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 0;
    line-height: normal;
  }
}

@media screen and (max-width: 1024px) {
  #home #siteContainer2.two_panel #panelTwo {
    max-width: 100%;
  }
}

@media screen and (max-width: 990px) {
  .user_mode .playerProfile .statsOne {
    width: 60%;
    float: none;
  }
  .user_mode .playerProfile .statsOne .textBlockElement h3,
  .user_mode .playerProfile .statsOne .textBlockElement .text h3 {
    margin-bottom: 3px;
  }
  .user_mode .playerProfile .statsOne .textBlockElement h3 span,
  .user_mode .playerProfile .statsOne .textBlockElement .text h3 span {
    font-size: .75rem;
  }
  .user_mode .playerProfile .statsOne .textBlockElement .number,
  .user_mode .playerProfile .statsOne .textBlockElement .text .number {
    font-size: 2rem;
    line-height: 1rem;
    margin-bottom: 2px;
  }
  .user_mode .playerProfile .statsOne .textBlockElement .name,
  .user_mode .playerProfile .statsOne .textBlockElement .text .name {
    font-size: 1.25rem;
  }
  .user_mode .playerProfile .statsOne .textBlockElement .name strong,
  .user_mode .playerProfile .statsOne .textBlockElement .text .name strong {
    font-size: 1.5rem;
    line-height: 1rem;
  }
  .user_mode .playerProfile .statsOne .textBlockElement .position,
  .user_mode .playerProfile .statsOne .textBlockElement .text .position {
    font-size: 1rem;
    line-height: 2rem;
  }
  .user_mode .playerProfile .statsTwo {
    width: 100%;
    border-top: solid 3px #0b9444;
    padding-top: 5px;
  }
  .user_mode .playerProfile .statsTwo:before {
    display: none;
  }
  .user_mode .playerProfile .statsTwo .pageElement.textBlockElement .text {
    margin-bottom: 10px;
  }
  .user_mode .playerProfile .statsTwo .pageElement.textBlockElement .text p {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 0;
    line-height: normal;
  }
  .user_mode .playerProfile .playerPhoto {
    width: 40%;
  }
  .user_mode .playerProfile .rowOne {
    position: relative;
    margin-bottom: 15px;
  }
  .user_mode .playerProfile .rowOne:after {
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #0b9444;
    visibility: visible;
  }
  .user_mode .playerProfile .playerPhoto {
    width: 40%;
    float: none;
  }
  .user_mode .playerProfile .playerLogo {
    width: 40%;
    margin: 0;
  }
  .user_mode .playerProfile .playerLogo .heroPhotoElement {
    text-align: center;
  }
  .user_mode .playerProfile .playerLogo .heroPhotoElement img {
    max-width: 100px;
    padding: 0 0 15px;
    display: inline-block;
  }
  .user_mode .playerProfile .statsTwo {
    border-top: none;
    width: 60%;
  }
  .user_mode .playerProfile .statsTwo .pageElement.textBlockElement .text p {
    display: block;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1024px) {
  .newsPage .siteContainer#siteContainer2.two_panel #panelTwo {
    max-width: 100% !important;
  }
}

#topNav {
  height: auto;
  background: #111111;
  border-bottom: none;
}

#topNav .beSocial {
  padding: 35px 0;
  height: 100px;
  display: inline-block;
  float: right;
}

#topNav table {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1340px;
}

#topNav table tr td {
  padding: 30px 0;
  float: left;
  margin-right: 1.5%;
}

#topNav table tr td.disabled a {
  color: #777777;
}

#topNav table tr td#homeLink {
  padding: 0 1% 0 0;
  margin-right: 0;
}

#topNav table tr td#homeLink a {
  height: 100px;
  width: 150px;
  display: inline-block;
  padding: 0;
  background-image: url("../images/logo_new.png");
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: left center;
  transition: all 0.2s ease-in-out;
}

#topNav table tr td#homeLink a:after {
  display: none;
}

#topNav table tr td#homeLink a:hover, #topNav table tr td#homeLink a.hover {
  opacity: .85;
}

#topNav table tr td#homeLink a:hover:after, #topNav table tr td#homeLink a.hover:after {
  display: none;
}

#topNav table tr td > a {
  background: transparent;
  position: relative;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: .025rem;
  border-left: none;
  border-right: none;
  display: inline-block;
  padding: 5px 0;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  letter-spacing: .1rem;
}

#topNav table tr td > a:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 4px;
  background: #111111;
  bottom: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
}

#topNav table tr td > a:hover:after, #topNav table tr td > a.hover:after {
  background: white;
}

#topNav table tr td.selected > a, #topNav table tr td:hover > a, #topNav table tr td.hover > a {
  border-right: none;
  border-left: none;
  box-shadow: none;
  color: white;
  background: transparent;
}

#topNav table tr td.selected > a:after, #topNav table tr td:hover > a:after, #topNav table tr td.hover > a:after {
  background: white;
}

#topNav table tr td ul {
  background: #eeeeee;
  border-bottom: solid 3px #c2c6ca;
}

#topNav table tr td ul .dropdown-disclosure {
  display: none !important;
}

#topNav table tr td ul.dropdown {
  margin-left: 0;
  left: 0;
  border-bottom: solid 3px #c2c6ca;
  padding: 0;
  box-shadow: none;
}

#topNav table tr td ul.dropdown:before {
  display: none;
}

#topNav table tr td ul li {
  border-right: solid 4px #eeeeee;
  transition: all 0.2s ease-in-out;
}

#topNav table tr td ul li:hover {
  border-right: solid 4px #ffc423;
}

#topNav table tr td ul li:hover a {
  background: #eeeeee;
}

#topNav table tr td ul li:first-of-type {
  padding-top: 5px;
}

#topNav table tr td ul li:last-of-type {
  padding-bottom: 5px;
}

#topNav table tr td ul li a {
  color: #2d2d2d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: .75rem;
  letter-spacing: .05rem;
}

#topNav table tr td ul li a.disabled {
  color: #777777;
}

#topNav table tr td ul li ul {
  background: #eeeeee;
  top: 0;
  left: calc(100% + 4px);
  box-shadow: none;
  border-bottom: solid 3px #c2c6ca;
  padding: 0;
}

#topNav table tr td ul li ul li a {
  color: #111111 !important;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

#topNav table tr td ul li ul li:hover {
  border-right: solid 4px #ffc423;
}

#topNav table tr td ul li ul li:hover a {
  background: #eeeeee;
}

.logged_in #topNav table tr td {
  margin-right: 10px;
}

.logged_in #topNav table tr td > a {
  font-size: .6rem;
}

#navLinksKill {
  background: transparent;
}

#navLinksKill span span {
  color: white;
  font-family: "Roboto", sans-serif;
  text-shadow: none;
  font-weight: normal;
  display: none;
}

#navLinksForm {
  background: #111111;
  border-bottom: none;
  border-radius: 0;
  padding: 10px;
}

#navLinksForm input {
  font-size: 14px;
  padding: 5px;
  width: 100%;
}

#navLinksContainer {
  box-shadow: none;
  background: #c2c6ca;
  width: 100%;
  max-width: 200px;
  border-radius: 0;
}

#navLinksResults {
  border-radius: 0 !important;
}

#navLinksResults li {
  font-family: "Roboto", sans-serif;
}

#navLinksResults li a {
  background: #c2c6ca;
  padding: 5px 15px;
  border-bottom: 1px solid #8c9192;
  transition: all 0.2s ease-in-out;
}

#navLinksResults li a b {
  color: #2d2d2d;
  text-transform: none;
  font: 400 0.813rem/1.5rem "Roboto", sans-serif;
  line-height: normal;
}

#navLinksResults li a small {
  color: #8c9192;
}

#navLinksResults li a.selected {
  background: transparent;
  border-bottom: none;
  border-bottom: 2px solid #8c9192;
}

#navLinksResults li a.selected b, #navLinksResults li a.selected small {
  color: #2d2d2d;
}

#navLinksResults li a:hover {
  background: #eeeeee;
  color: #2d2d2d;
  border-bottom: 1px solid #8c9192;
}

#navLinksResults li a:hover b, #navLinksResults li a:hover small {
  color: #2d2d2d;
}

p#navLinksStatus {
  background: #111111;
  color: white;
  font-family: "Orbitron", sans-serif;
  font-size: 1rem;
  padding: 5px 0 5px 15px;
  border-bottom: none;
}

p#navLinksStatus #navLinksShowAll {
  background: #0b9444;
  text-shadow: none;
  text-decoration: none;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  border-radius: 0;
  border: none;
  margin: 0;
  box-shadow: none;
  padding: 8px 10px 9px;
  margin-top: -5px;
}

#network {
  height: 50px;
  background: #111111;
  border-bottom: solid 1px #2d2d2d;
  display: none;
}

#network .networkTeams {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1200px;
  height: 50px;
}

#network .networkTeams a {
  height: 50px;
  float: left;
  text-align: center;
  margin: 0 4px;
  line-height: 67px;
  transition: all 0.2s ease-in-out;
}

#network .networkTeams a:hover {
  opacity: .75;
}

#network .networkTeams a img {
  height: 65%;
}

#home #networkDropMenu .bar {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1340px;
}

#home #networkDropMenu .menuWrap {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1370px;
}

#networkDropMenu {
  height: 35px;
  background: #111111;
  position: relative;
  z-index: 4;
  border-bottom: solid 1px #2d2d2d;
}

#networkDropMenu .bar {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1200px;
  position: relative;
}

#networkDropMenu #dropBtn {
  height: 35px;
  line-height: 35px;
  color: white;
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #c2c6ca;
  float: left;
  transition: all 0.2s ease-in-out;
}

#networkDropMenu #dropBtn:after {
  content: '\f107';
  display: inline-block;
  margin-left: 5px;
  font-family: "FontAwesome";
}

#networkDropMenu #dropBtn.opened {
  color: #ffc423;
}

#networkDropMenu #dropBtn.opened:after {
  content: '\f106';
}

#networkDropMenu #dropBtn:hover {
  cursor: pointer;
}

#networkDropMenu .menuWrap {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1200px;
  top: 35px;
}

#networkDropMenu .networkDropTeams {
  position: absolute;
  padding: 30px 30px 10px 30px;
  background: white;
  top: 0;
  left: 0;
  border: solid 1px #c2c6ca;
  border-top: none;
  width: 100%;
  display: none;
}

#networkDropMenu .networkDropTeams:before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 75%;
  width: 1px;
  background: #eeeeee;
  left: 48%;
  top: 15%;
}

#networkDropMenu .networkDropTeams h1 {
  font-family: "Orbitron", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #111111;
  width: 45%;
  float: left;
  margin-bottom: 25px;
}

#networkDropMenu .networkDropTeams h1.westTitle {
  margin-right: 10%;
}

#networkDropMenu .networkDropTeams .pageElement.heroPhotoElement {
  margin: 0 0 20px 0;
  float: left;
  clear: none;
  width: 33.333333333%;
}

#networkDropMenu .networkDropTeams .pageElement.heroPhotoElement .cutline {
  font-family: "Roboto", sans-serif;
  color: #2d2d2d;
  font-style: normal;
  line-height: 40px;
  font-size: 12px;
}

#networkDropMenu .networkDropTeams a {
  height: 45px;
  width: 60px;
  float: left;
  transition: all 0.2s ease-in-out;
  margin-right: 5px;
}

#networkDropMenu .networkDropTeams a:hover {
  opacity: .75;
}

#networkDropMenu .networkDropTeams a img {
  height: 100%;
}

#networkDropMenu .networkDropTeams .west,
#networkDropMenu .networkDropTeams .east {
  width: 45%;
  float: left;
}

#networkDropMenu .networkDropTeams .west {
  margin-right: 10%;
}

@media screen and (max-width: 1400px) {
  #home #topNav {
    padding: 0 15px !important;
  }
  #home #networkDropMenu {
    padding: 0 15px;
  }
}

@media screen and (max-width: 1200px) {
  #topNav .beSocial {
    display: none;
  }
  #topNav {
    padding: 0 15px !important;
  }
  #networkDropMenu {
    padding: 0 15px;
  }
}

@media screen and (max-width: 1024px) {
  #networkDropMenu {
    display: none;
  }
}

.pageElement h3 {
  font-family: "Orbitron", sans-serif;
  color: #2d2d2d;
  font-weight: 600;
}

.pageElement h3 span {
  font-weight: 600;
}

.scoreboardBanner {
  height: auto;
}

#masterScoreboard,
#scoreboard_container {
  display: block;
  padding: 0 !important;
  border-bottom: none;
  background: transparent;
}

#masterScoreboard .scoreboardRibbon,
#scoreboard_container .scoreboardRibbon {
  background: #111111;
  border-bottom: solid 1px #2d2d2d;
  border-top: solid 1px #2d2d2d;
  border-radius: 0;
}

#masterScoreboard .scoreboardRibbon .container,
#scoreboard_container .scoreboardRibbon .container {
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
}

#masterScoreboard .scoreboardRibbon .previous_button,
#masterScoreboard .scoreboardRibbon .next_button,
#scoreboard_container .scoreboardRibbon .previous_button,
#scoreboard_container .scoreboardRibbon .next_button {
  background: #111111;
  transition: all 0.2s ease-in-out;
  height: 86px;
  border-radius: 0;
}

#masterScoreboard .scoreboardRibbon .previous_button:before,
#masterScoreboard .scoreboardRibbon .next_button:before,
#scoreboard_container .scoreboardRibbon .previous_button:before,
#scoreboard_container .scoreboardRibbon .next_button:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: #c2c6ca;
  font-family: "FontAwesome";
  height: 86px;
  width: 30px;
  line-height: 86px;
  text-align: center;
  font-size: 1rem;
}

#masterScoreboard .scoreboardRibbon .previous_button:hover,
#masterScoreboard .scoreboardRibbon .next_button:hover,
#scoreboard_container .scoreboardRibbon .previous_button:hover,
#scoreboard_container .scoreboardRibbon .next_button:hover {
  background: #111111;
}

#masterScoreboard .scoreboardRibbon .previous_button,
#scoreboard_container .scoreboardRibbon .previous_button {
  margin-left: -30px;
  border-right: 1px solid #2d2d2d;
  left: 0;
}

#masterScoreboard .scoreboardRibbon .previous_button:before,
#scoreboard_container .scoreboardRibbon .previous_button:before {
  content: '\f100';
}

#masterScoreboard .scoreboardRibbon .next_button,
#scoreboard_container .scoreboardRibbon .next_button {
  margin-right: -30px;
  border-left: 1px solid #2d2d2d;
  right: 0;
}

#masterScoreboard .scoreboardRibbon .next_button:before,
#scoreboard_container .scoreboardRibbon .next_button:before {
  content: '\f101';
}

#masterScoreboard .scoreboardRibbon:hover .previous_button,
#scoreboard_container .scoreboardRibbon:hover .previous_button {
  margin-left: 0;
}

#masterScoreboard .scoreboardRibbon:hover .next_button,
#scoreboard_container .scoreboardRibbon:hover .next_button {
  margin-right: 0;
}

#masterScoreboard .scoreboardRibbon:hover .container,
#scoreboard_container .scoreboardRibbon:hover .container {
  left: 30px;
  right: 30px;
}

#masterScoreboard .scoreboardRibbon .game,
#scoreboard_container .scoreboardRibbon .game {
  width: 200px;
  height: 86px;
  padding: 0 5px;
  position: relative;
}

#masterScoreboard .scoreboardRibbon .game .name,
#scoreboard_container .scoreboardRibbon .game .name {
  font-size: 12px;
  max-width: 100px;
}

#masterScoreboard .scoreboardRibbon .gameLink,
#scoreboard_container .scoreboardRibbon .gameLink {
  border: none;
  border-radius: 0;
  background: transparent;
  border-right: 1px solid #2d2d2d;
  margin-top: 0;
  height: 86px;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}

#masterScoreboard .scoreboardRibbon .gameLink:hover,
#scoreboard_container .scoreboardRibbon .gameLink:hover {
  background: #111111;
}

#masterScoreboard .scoreboardRibbon .date, #masterScoreboard .scoreboardRibbon .team, #masterScoreboard .scoreboardRibbon .status, #masterScoreboard .scoreboardRibbon .name, #masterScoreboard .scoreboardRibbon .score,
#scoreboard_container .scoreboardRibbon .date,
#scoreboard_container .scoreboardRibbon .team,
#scoreboard_container .scoreboardRibbon .status,
#scoreboard_container .scoreboardRibbon .name,
#scoreboard_container .scoreboardRibbon .score {
  font-weight: 400;
  font-size: 1rem;
}

#masterScoreboard .scoreboardRibbon .date,
#scoreboard_container .scoreboardRibbon .date {
  color: #8c9192;
  padding: 15px 5px 10px;
  font-size: 10px;
  font-family: "Roboto", sans-serif;
}

#masterScoreboard .scoreboardRibbon .team,
#scoreboard_container .scoreboardRibbon .team {
  color: white;
  padding: 5px 6px;
  font-size: 1rem;
  line-height: normal;
}

#masterScoreboard .scoreboardRibbon .status,
#scoreboard_container .scoreboardRibbon .status {
  color: #8c9192;
  font-size: .8rem;
  letter-spacing: .05rem;
  position: absolute;
  top: 5px;
  right: 10px;
  display: none;
}

#scoreboard_container .scoreboardRibbon {
  margin: 0;
  border: none;
}

#scoreboard_container .scoreboardRibbon .previous_button {
  margin-left: 0;
  height: 90px;
}

#scoreboard_container .scoreboardRibbon .previous_button:before {
  height: 90px;
}

#scoreboard_container .scoreboardRibbon .next_button {
  margin-right: 0px;
  height: 90px;
}

#scoreboard_container .scoreboardRibbon .next_button:before {
  height: 90px;
  line-height: 90px;
}

#scoreboard_container .scoreboardRibbon .container {
  left: 30px;
  right: 30px;
}

#scoreboard_container .scoreboardRibbon .game {
  height: 90px;
  line-height: 90px;
}

#scoreboard_container .scoreboardRibbon .game {
  width: 150px;
}

.user_mode .pageElement.tabbedElement {
  padding: 0;
  border: none;
  margin-bottom: 15px;
}

.user_mode .contentTabs,
.user_mode ul.contentTabs.layoutContainerTabs {
  border-bottom: 1px solid #8c9192;
  height: 35px;
}

.user_mode .contentTabs li span a,
.user_mode ul.contentTabs.layoutContainerTabs li span a {
  background: transparent;
  color: #111111;
  font-family: "Roboto", sans-serif;
  font-size: .8rem;
  padding: 5px 0;
  margin-right: 15px;
  letter-spacing: .025rem;
  transition: all 0.2s ease-in-out;
}

.user_mode .contentTabs li.selected span a,
.user_mode ul.contentTabs.layoutContainerTabs li.selected span a {
  border-bottom: solid 4px #111111;
}

.user_mode .contentTabs li:hover span a,
.user_mode ul.contentTabs.layoutContainerTabs li:hover span a {
  color: #8c9192;
}

.widget-gameTable a.icon.goToLink {
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
}

.widget-gameTable a.icon.goToLink:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

.pageElement.linkElement {
  padding: 0 30px 30px;
}

.pageElement.linkElement h4 {
  margin: 0 10px;
}

.pageElement.linkElement h4 a {
  padding: 15px;
  background: #0b9444;
  color: white;
  font-family: "Orbitron", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.25rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
}

.pageElement.linkElement h4 a:after {
  display: none;
}

.pageElement.linkElement h4 a:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

.sponsorElement {
  background: white;
  border: solid 1px #c2c6ca;
  padding: 15px;
}

.sponsorElement .description {
  margin-left: 110px;
}

.sponsorElement .description a {
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
  display: inline-block;
  margin-top: 10px;
}

.sponsorElement .description a:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

.sponsorElement .description a:after {
  display: none;
}

.sponsorElement .imageLink {
  margin: 0;
}

.pageElement.textBlockElement h3 {
  color: #111111;
  font-family: "Orbitron", sans-serif;
  padding: 5px 0;
  border-bottom: solid 1px #0b9444;
}

.pageElement.textBlockElement h3 span {
  font-weight: 600;
  font-size: 1.5rem;
}

.pageElement.textBlockElement .text p {
  font-family: "Roboto", sans-serif;
  color: #2d2d2d;
  font-size: 14px;
  line-height: 1.75rem;
}

.pageElement.textBlockElement .text p span {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
}

.pageElement.textBlockElement .text p a {
  text-decoration: none;
  color: #00B663;
  font-size: 14px;
}

.pageElement.textBlockElement .text ol li {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.pageElement.textBlockElement .text h1, .pageElement.textBlockElement .text h2, .pageElement.textBlockElement .text h3, .pageElement.textBlockElement .text h4, .pageElement.textBlockElement .text h5 {
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  padding: 0;
}

.pageElement.textBlockElement .text h1 {
  color: #2d2d2d;
  font-size: 2rem;
}

.pageElement.textBlockElement .text h2 {
  color: #0b9444;
  font-size: 2rem;
}

.pageElement.textBlockElement .text h3 {
  color: #2d2d2d;
  font-size: 1.5rem;
  border-bottom: none;
}

.pageElement.textBlockElement .text h4 {
  color: #0b9444;
  font-size: 1.5rem;
}

.pageElement.textBlockElement .text h5 {
  color: #2d2d2d;
  font-size: 1rem;
}

.surveyLinkElement {
  border-radius: 0;
}

.surveyLinkElement a {
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
}

.surveyLinkElement a:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
  color: white;
}

.surveyLinkElement a:hover span {
  color: white;
}

.surveyLinkElement a span {
  color: white;
  font-size: 1.2rem;
  text-decoration: none;
}

.pageElement .statTable,
.pageElement .dataTable {
  border: none;
}

.pageElement .statTable th,
.pageElement .dataTable th {
  background: #c2c6ca;
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
  padding: 10px;
  font-size: 1.1rem;
  font-weight: 700;
  min-width: initial;
  text-align: left;
  letter-spacing: .025rem;
}

.pageElement .statTable tr,
.pageElement .dataTable tr {
  border-bottom: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
  border-right: solid 1px #c2c6ca;
}

.pageElement .statTable tr.highlight, .pageElement .statTable tr.odd, .pageElement .statTable tr.even,
.pageElement .dataTable tr.highlight,
.pageElement .dataTable tr.odd,
.pageElement .dataTable tr.even {
  background: white;
}

.pageElement .statTable tr td,
.pageElement .dataTable tr td {
  padding: 10px;
  font-size: .8rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: .025rem;
  text-align: left;
}

.pageElement .statTable tr td a,
.pageElement .dataTable tr td a {
  color: #2d2d2d;
}

.pageElement .statTable tr td a.game_link_referrer.addButton,
.pageElement .dataTable tr td a.game_link_referrer.addButton {
  position: relative;
  display: block;
  right: 0;
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
  padding: 5px 10px;
  font-family: "Orbitron", sans-serif;
  color: white;
  font-size: .75rem;
}

.pageElement .statTable tr td a.game_link_referrer.addButton:hover,
.pageElement .dataTable tr td a.game_link_referrer.addButton:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

.pageElement .statTable tr td a.game_link_referrer.addButton:after,
.pageElement .dataTable tr td a.game_link_referrer.addButton:after {
  content: '\203A';
  display: inline-block;
  position: relative;
  margin-left: 7px;
}

.pageElement .statTable tr td a.game_link_referrer.addButton img,
.pageElement .dataTable tr td a.game_link_referrer.addButton img {
  display: none;
}

.pageElement .statTable tr td a.game_link_referrer.addButton.finished:after,
.pageElement .dataTable tr td a.game_link_referrer.addButton.finished:after {
  content: 'Finished \203A';
  text-transform: none;
}

.pageElement .statTable tr td .teamLogo-tiny,
.pageElement .dataTable tr td .teamLogo-tiny {
  display: none;
}

#tab_completegamelist_content {
  overflow-x: scroll;
}

.pageElement.countdownElement {
  background: #c2c6ca;
  font-family: "Orbitron", sans-serif;
  padding: 0;
  text-shadow: none;
}

.pageElement.countdownElement h2 {
  color: white;
  margin: 0;
  padding: 15px;
  background: transparent;
  color: #2d2d2d;
  border-bottom: solid 2px #8c9192;
  margin: 0 30px;
  font-size: 2.5rem;
  padding: 30px 0;
}

.pageElement.countdownElement abbr {
  background: transparent;
  color: white;
  margin-top: 1px;
  padding: 5px;
  color: #2d2d2d;
  padding: 30px 0;
}

.pageElement.countdownElement abbr b {
  font-size: 2.5rem;
}

.pageElement.countdownElement span {
  margin: 5px 3%;
}

.pageElement.countdownElement small {
  font-size: 1rem;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline {
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  max-width: 60%;
  left: 5%;
  bottom: 7%;
  padding: 2%;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline h1 {
  margin-bottom: 10px;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline h1 a {
  color: #111111;
  font-family: "Orbitron", sans-serif;
  font-size: 2vw;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline p {
  line-height: normal;
  height: auto;
  color: #2d2d2d;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline p span {
  color: #2d2d2d;
  display: block;
  margin-bottom: 5px;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline p a.newsSlideShow-more {
  color: white;
  background: #0b9444;
  box-shadow: none;
  border-radius: 0;
  font-family: "Orbitron", sans-serif;
  margin: 0;
  padding: 7px 15px;
  transition: all 0.2s ease-in-out;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline p a.newsSlideShow-more:hover {
  text-decoration: none !important;
  background: #8c9192;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-thumbnails {
  width: 90%;
  padding: 0;
  margin: 15px 5%;
  text-align: left;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-thumbnails li {
  width: 12px;
  margin: 0 3px;
  border-radius: 100px;
  overflow: hidden;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-thumbnails li a {
  border: none;
  width: 12px;
  height: 12px;
  background: white;
}

body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-thumbnails li a img {
  display: none;
}

.pageElement.mediaSlider h2 {
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  padding: 0;
}

.pageElement.mediaSlider .sn-media-slider .slide-title,
.pageElement.mediaSlider .sn-media-slider .slide-description {
  font-family: "Orbitron", sans-serif;
  margin: 5px;
}

.column .pageElement.widget-teamButtons .teamBtnCol a {
  background: transparent;
  border: none;
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
  transition: all 0.2s ease-in-out;
  height: 50px;
  margin: 0 0 10px;
  background: white;
  border: solid 1px #c2c6ca;
  position: relative;
}

.column .pageElement.widget-teamButtons .teamBtnCol a:after {
  content: '\f105';
  display: inline-block;
  position: absolute;
  height: 50px;
  width: 20px;
  right: 10px;
  top: 0;
  line-height: 50px;
  font-family: "FontAwesome";
  text-align: center;
  visibility: visible;
}

.column .pageElement.widget-teamButtons .teamBtnCol a:hover {
  color: #0b9444;
  background: #eeeeee;
}

.column .pageElement.widget-teamButtons .teamBtnCol a:hover .sport-colors .teamLogo-small {
  border: none;
  border-radius: 0;
  border-right: solid 1px #c2c6ca;
}

.column .pageElement.widget-teamButtons .teamBtnCol a .sport-colors {
  border: none;
  padding: 7px 0px;
}

.column .pageElement.widget-teamButtons .teamBtnCol a .sport-colors .color0-sm,
.column .pageElement.widget-teamButtons .teamBtnCol a .sport-colors .color1-sm,
.column .pageElement.widget-teamButtons .teamBtnCol a .sport-colors .color2-sm {
  display: none;
}

.column .pageElement.widget-teamButtons .teamBtnCol a .sport-colors .teamLogo-small {
  border: none;
  border-radius: 0;
  height: 48px !important;
  width: 50px !important;
  top: 0;
  background-size: auto 100%;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;
  border-right: solid 1px #c2c6ca;
  background-size: auto 80%;
  left: 0;
  background-color: white;
  display: none;
}

.column .pageElement.widget-teamButtons .teamname {
  font: 600 normal 1rem "Roboto", sans-serif;
  padding: 5px 5px 5px 15px;
  color: #111111;
}

.galleryElement h3 {
  padding: 10px 0;
}

.pageElement.contactElement {
  font-family: "Orbitron", sans-serif;
  color: #2d2d2d;
}

.pageElement.contactElement h3 {
  font-family: "Orbitron", sans-serif;
  color: #111111;
  padding: 0;
}

.pageElement.contactElement h3 span {
  font-weight: 600;
}

.pageElement.contactElement .title {
  color: #0b9444;
}

.hrElement {
  border-top: 1px solid #c2c6ca;
}

.pageElement.heroPhotoElement .cutline {
  border-top: none;
  background: transparent;
  font-family: "Orbitron", sans-serif;
}

.pageElement.pollElement {
  background: white;
  border: none;
}

.pageElement.pollElement h3 {
  background: #111111;
  color: white;
}

.pageElement.pollElement h3 span {
  font-size: 1.5rem;
  line-height: normal;
}

.pageElement.pollElement ul li.pollChoice label {
  font-family: "Orbitron", sans-serif;
  font-size: 1rem;
  color: #2d2d2d;
}

.pageElement.pollElement ul li.voteButton input {
  background: #0b9444;
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  padding: 8px 30px 3px;
  border-bottom: 4px solid #096831;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
}

.pageElement.pollElement ul li.voteButton input:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

.pageElement .cutline {
  padding: 5px 0;
}

.user_mode select {
  background: white;
  border: 0;
  border-radius: 0;
  height: 36px;
  font-family: "Roboto", sans-serif;
  font-size: .75rem;
}

.user_mode button#user-filters-submit {
  background: #0b9444;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding: 8px 30px 3px;
  border-bottom: 4px solid #096831;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  border-radius: 0;
  text-shadow: none;
  text-transform: uppercase;
  box-shadow: none;
  color: white;
}

.user_mode button#user-filters-submit:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

.icon.excelLink {
  font-family: "Orbitron", sans-serif;
  color: white !important;
  font-weight: 600;
  background: #111111;
  font-size: 1rem !important;
  padding: 10px !important;
  margin: 0 !important;
  transition: all 0.2s ease-in-out;
}

.icon.excelLink:before {
  content: '\f15c';
  display: inline-block;
  position: relative;
  margin-right: 7px;
  font-family: "FontAwesome";
}

.icon.excelLink:hover {
  background: #8c9192;
}

.pageElement.documentElement .excel.iconSizeM {
  height: auto;
  padding-left: 0;
  padding-top: auto;
}

.pageElement.documentElement .excel.iconSizeM a {
  top: auto;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  background: #111111;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
}

.pageElement.documentElement .excel.iconSizeM a:before {
  content: '\f15b';
  display: inline-block;
  position: relative;
  margin-right: 7px;
  font-family: "FontAwesome";
}

.pageElement.documentElement .excel.iconSizeM a:hover {
  background: #8c9192;
}

.reportTableElement .filtered_survey_actions {
  margin-bottom: 10px;
}

.pageElement.galleryElement .image_title {
  background: transparent;
}

.pageElement.galleryElement .prev_btn,
.pageElement.galleryElement .next_btn {
  top: calc(50% + 23px);
}

.pageElement.galleryElement .controls {
  display: none !important;
}

.pageElement.rankingsElement .logoLink {
  display: none;
}

.pageElement.rankingsElement .statTable tr td.name a {
  color: #0b9444;
}

.pageElement.rankingsElement .up,
.pageElement.rankingsElement .down {
  background: transparent;
  position: relative;
}

.pageElement.rankingsElement .up:before,
.pageElement.rankingsElement .down:before {
  content: '\f077';
  display: block;
  position: absolute;
  font-family: "FontAwesome";
  top: -3px;
  left: 0;
  width: 100%;
  color: #111111;
}

.pageElement.rankingsElement .down:before {
  content: '\f078';
  color: #0b9444;
}

/* Contact Form */
.user_mode .contactForm {
  background: #c2c6ca;
  padding: 3em 1em;
}

.user_mode .contactForm label {
  font-family: "klavika-web", sans-serif;
  color: #222;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 400;
  margin: 0 1em;
  display: inline-block;
  width: 30%;
  text-align: right;
  vertical-align: top;
}

.user_mode .contactForm input {
  font-family: "klavika-web", sans-serif;
  color: #222;
  font-size: 1.125rem;
  font-weight: 400;
  border: none;
  padding: .25em .5em;
  margin-bottom: .5em;
  width: 50%;
  display: inline-block;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.user_mode .contactForm textarea {
  font-family: "klavika-web", sans-serif;
  color: #222;
  font-size: 1.125rem;
  font-weight: 400;
  border: none;
  padding: .5em;
  margin-bottom: .75em;
  width: 50%;
  display: inline-block;
  min-height: 3em;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.user_mode .contactForm .submit {
  background: #ec1c2d;
  color: #fff;
  font-family: "klavika-web", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 4px solid #d0112b;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  width: 50%;
  margin-left: calc(30% + 2em);
  transition: box-shadow .2s ease-in-out, background .2s ease-in-out;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.user_mode .contactForm .submit:hover {
  background: #d0112b;
  box-shadow: inset 0 0 20px #ec1c2d;
  transition: box-shadow .2s ease-in-out, background .2s ease-in-out;
}

.user_mode .contactForm input[type=checkbox] {
  -webkit-appearance: checkbox;
}

.pageElement.siteMapElement .unlimitedHierarchy {
  background: transparent;
}

.pageElement.siteMapElement .unlimitedHierarchy li {
  background: transparent;
}

.pageElement.siteMapElement .unlimitedHierarchy li div {
  background: transparent;
}

.pageElement.siteMapElement .unlimitedHierarchy li div h3 {
  font-size: 1rem;
  color: #111111;
}

.pageElement.siteMapElement .unlimitedHierarchy li div h3 a {
  color: #2d2d2d;
  transition: all 0.2s ease-in-out;
}

.pageElement.siteMapElement .unlimitedHierarchy li div h3 a:hover {
  text-decoration: none;
  color: #0b9444;
}

.pageElement.siteMapElement .unlimitedHierarchy .lastLi {
  background: transparent;
}

#prospectsContainer,
#playerMenuContainer {
  padding-top: 15px;
  position: relative;
}

#prospectsContainer .prospects:after,
#playerMenuContainer .prospects:after {
  content: '';
  display: block;
  position: absolute;
  width: calc(100% - 15px);
  background: white;
  height: 10px;
  bottom: 15px;
  left: 0;
}

#prospectsContainer .prospects,
#prospectsContainer .players,
#playerMenuContainer .prospects,
#playerMenuContainer .players {
  padding-left: 0;
  margin-right: 15px;
  background: white;
  padding-bottom: 15px;
}

#prospectsContainer .prospects .pageEl,
#prospectsContainer .players .pageEl,
#playerMenuContainer .prospects .pageEl,
#playerMenuContainer .players .pageEl {
  padding: 0 15px;
  position: relative;
}

#prospectsContainer .prospects .pageEl:after,
#prospectsContainer .players .pageEl:after,
#playerMenuContainer .prospects .pageEl:after,
#playerMenuContainer .players .pageEl:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 3px;
  left: 0;
  height: 2px;
  width: calc(100% - 30px);
  background: #c2c6ca;
  visibility: visible;
  margin: 0 15px;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement,
#prospectsContainer .players .pageEl .pageElement.textBlockElement,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement {
  margin: 0;
  padding: 15px 0 0;
  position: relative;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text {
  margin-top: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text h1, #prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text h2, #prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text h3, #prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text h4,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text h1,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text h2,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text h3,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text h4,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text h1,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text h2,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text h3,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text h4,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text h1,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text h2,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text h3,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text h4 {
  text-transform: uppercase;
  line-height: normal;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text h1,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text h1,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text h1,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text h1 {
  color: #111111;
  font-size: 4vw;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text h2,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text h2,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text h2,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text h2 {
  font-size: 1vw;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text h3,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text h3,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text h3,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text h3 {
  color: #111111;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement .text h4,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .text h4,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .text h4,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .text h4 {
  color: #111111;
  font-size: 3vw;
  margin-top: -10px;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement .rightTextImage,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .rightTextImage,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .rightTextImage,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .rightTextImage {
  margin: 0;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement .rightTextImage img,
#prospectsContainer .players .pageEl .pageElement.textBlockElement .rightTextImage img,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement .rightTextImage img,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement .rightTextImage img {
  filter: grayscale(100%);
  transition: all 0.2s ease-in-out;
  height: 175px;
  width: auto;
}

#prospectsContainer .prospects .pageEl .pageElement.textBlockElement:hover .rightTextImage img,
#prospectsContainer .players .pageEl .pageElement.textBlockElement:hover .rightTextImage img,
#playerMenuContainer .prospects .pageEl .pageElement.textBlockElement:hover .rightTextImage img,
#playerMenuContainer .players .pageEl .pageElement.textBlockElement:hover .rightTextImage img {
  filter: grayscale(0%);
}

#prospectsContainer .prospects .pageEl .pageElement.tabbedElement,
#prospectsContainer .players .pageEl .pageElement.tabbedElement,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement {
  padding: 0 !important;
  margin-right: 0;
  overflow: visible;
}

#prospectsContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs,
#prospectsContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs {
  background: #8c9192;
  height: 45px !important;
  position: relative;
  border-bottom: none;
  text-align: center;
}

#prospectsContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:before, #prospectsContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:after,
#prospectsContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:before,
#prospectsContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:after,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:before,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:after,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:before,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 15px;
  background: #8c9192;
  top: 0;
}

#prospectsContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:before,
#prospectsContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:before,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:before,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:before {
  right: 100%;
}

#prospectsContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:after,
#prospectsContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:after,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:after,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs:after {
  left: 100%;
}

#prospectsContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li,
#prospectsContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li {
  line-height: 45px;
  float: none;
  display: inline-block;
}

#prospectsContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li span a,
#prospectsContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li span a,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li span a,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li span a {
  color: white !important;
  padding: 9px 0 !important;
}

#prospectsContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li.selected span a,
#prospectsContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li.selected span a,
#playerMenuContainer .prospects .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li.selected span a,
#playerMenuContainer .players .pageEl .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li.selected span a {
  border-bottom: solid 4px #0b9444 !important;
}

.bottomOptions .pageOptions li {
  font-size: 12px;
}

.pageElement .selectedNodes {
  background: #c2c6ca;
  border: none;
}

.pageElement .selectedNodes h3 {
  font-family: "Roboto", sans-serif;
  font-size: .9rem;
}

.nodePickerContainer .tab {
  background: transparent;
}

.nodePickerContainer .tab a {
  background: #8c9192;
  margin-right: 0;
  color: white;
  text-shadow: none;
  letter-spacing: .025rem;
  font-weight: 300;
  font-size: 12px;
  padding: 0 10px;
}

.nodePickerContainer .nodePickerSlideBox {
  height: auto !important;
}

.user_mode .clubButtons .alpha .pageElement.textBlockElement:before {
  display: none;
}

.user_mode .clubButtons .alpha .pageElement.textBlockElement:hover {
  background: white;
}

.user_mode .clubButtons .pageElement.textBlockElement {
  background: white;
  border: solid 1px #c2c6ca;
  transition: all 0.2s ease-in-out;
  position: relative;
  margin-bottom: 5px;
}

.user_mode .clubButtons .pageElement.textBlockElement:hover {
  background: #eeeeee;
}

.user_mode .clubButtons .pageElement.textBlockElement:before {
  content: '\f105';
  display: inline-block;
  position: absolute;
  right: 15px;
  top: calc(50% - 7px);
  font-family: "FontAwesome";
}

.user_mode .clubButtons .pageElement.textBlockElement .leftTextImage,
.user_mode .clubButtons .pageElement.textBlockElement .rightTextImage {
  width: 50px !important;
  min-height: 40px !important;
  margin: 0;
  padding: 5px 0 3px;
  border-right: solid 1px #c2c6ca;
}

.user_mode .clubButtons .pageElement.textBlockElement img {
  width: 40px !important;
  height: 40px !important;
  margin-left: 4px;
}

.user_mode .clubButtons .pageElement.textBlockElement .text p a {
  color: #2d2d2d;
  font-size: 18px;
  line-height: 45px;
  margin-left: 60px;
  display: block;
}

.trimStandings .npAccordion {
  width: 100%;
  display: inline-table;
  display: none;
}

.trimStandings .accordionBtn {
  display: block;
  font-family: "Roboto", sans-serif;
  height: 40px;
  color: #c2c6ca;
  text-align: center;
  background: white;
  border-bottom: solid 1px #c2c6ca;
  border-right: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.trimStandings .accordionBtn:hover {
  color: #8c9192;
  cursor: pointer;
}

.trimStandings .accordionBtn:before {
  content: 'Full Standings \f107';
  display: inline-block;
  font-family: "FontAwesome";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 40px;
  text-align: center;
  width: 100%;
  font-size: .8rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.trimStandings .accordionBtn.npVisible:before {
  content: 'Show Less \f106';
}

.trimStandings .tabContainer {
  margin-top: -5px;
}

.trimStandings .tabbedElement .contentTabs {
  margin-bottom: 0;
  padding: 0 5px;
  border-bottom: none !important;
}

.trimStandings .tabbedElement .contentTabs li span a {
  color: #8c9192 !important;
  letter-spacing: .05rem !important;
  font-family: "Roboto", sans-serif !important;
  font-size: .75rem !important;
}

.trimStandings .widgetHierarchy ul li .sportTableLabel h4 {
  display: none;
}

.trimStandings .widgetHierarchy ul li table.statTable .teamLogo-small {
  display: block;
  border: none;
  border-radius: 0;
  background-size: auto 100%;
  height: 30px !important;
  width: 30px !important;
}

.trimStandings .statTable,
.trimStandings .npAccordion {
  background: white;
  border-right: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
}

.trimStandings .statTable th,
.trimStandings .npAccordion th {
  background: white;
  font-family: "Roboto", sans-serif;
  font-size: .8rem;
  padding: 15px 10px;
  display: none;
  border-top: solid 1px #c2c6ca;
}

.trimStandings .statTable tr,
.trimStandings .npAccordion tr {
  border-bottom: solid 1px #c2c6ca;
}

.trimStandings .statTable tr td.gamesPlayed, .trimStandings .statTable tr td.pointsScored, .trimStandings .statTable tr td.name, .trimStandings .statTable tr th.gamesPlayed, .trimStandings .statTable tr th.pointsScored, .trimStandings .statTable tr th.name,
.trimStandings .npAccordion tr td.gamesPlayed,
.trimStandings .npAccordion tr td.pointsScored,
.trimStandings .npAccordion tr td.name,
.trimStandings .npAccordion tr th.gamesPlayed,
.trimStandings .npAccordion tr th.pointsScored,
.trimStandings .npAccordion tr th.name {
  display: table-cell;
}

.trimStandings .statTable tr td.name, .trimStandings .statTable tr th.name,
.trimStandings .npAccordion tr td.name,
.trimStandings .npAccordion tr th.name {
  width: 80%;
}

.trimStandings .statTable tr td.gamesPlayed, .trimStandings .statTable tr td.pointsScored, .trimStandings .statTable tr th.gamesPlayed, .trimStandings .statTable tr th.pointsScored,
.trimStandings .npAccordion tr td.gamesPlayed,
.trimStandings .npAccordion tr td.pointsScored,
.trimStandings .npAccordion tr th.gamesPlayed,
.trimStandings .npAccordion tr th.pointsScored {
  width: 10%;
}

.trimStandings .statTable tr td,
.trimStandings .npAccordion tr td {
  border-bottom: solid 1px #c2c6ca;
  padding: 10px;
  display: none;
  color: #2d2d2d;
}

.trimStandings .statTable tr.nonPlayoff,
.trimStandings .npAccordion tr.nonPlayoff {
  display: none;
}

.trimStandings .statTable tr.nonPlayoff td,
.trimStandings .npAccordion tr.nonPlayoff td {
  background: #eeeeee !important;
}

.trimStandings .statTable .name,
.trimStandings .npAccordion .name {
  display: table-cell;
}

.trimStandings .statTable .name.condensedView,
.trimStandings .npAccordion .name.condensedView {
  display: none;
}

@media screen and (max-width: 1024px) {
  #masterScoreboard .scoreboardRibbon .container,
  #scoreboard_container .scoreboardRibbon .container {
    left: 30px;
    right: 30px;
  }
  #masterScoreboard .scoreboardRibbon .game,
  #scoreboard_container .scoreboardRibbon .game {
    width: 150px;
  }
}

@media screen and (max-width: 990px) {
  body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline h1 a {
    font-size: 3vw;
    line-height: normal;
  }
}

@media screen and (max-width: 768px) {
  body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-thumbnails {
    display: none;
  }
  body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline {
    left: 0;
    bottom: 0;
    max-width: 100%;
    border-bottom: 3px solid #c2c6ca;
  }
  body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline h1 {
    margin-bottom: 0;
    text-align: center;
  }
  body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline p a.newsSlideShow-more {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline h1 a {
    font-size: 4vw;
  }
  .user_mode .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs {
    height: auto;
    border-bottom: none;
  }
  .user_mode .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li {
    display: block;
    width: 100%;
  }
  .user_mode .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li span a {
    display: block;
    text-align: center;
    border-bottom: solid 2px #8c9192;
  }
  .user_mode .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li.selected span a {
    border-bottom: solid 4px #111111;
    color: #111111;
  }
  .pageElement.countdownElement h2 {
    font-size: 1.75rem;
  }
  .pageElement.countdownElement abbr b {
    font-size: 2rem;
  }
}

.pullDown {
  animation-name: pullDown;
  -webkit-animation-name: pullDown;
  animation-duration: .1s;
  -webkit-animation-duration: .1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
}

@keyframes pullDown {
  0% {
    transform: scaleY(0.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes pullDown {
  0% {
    -webkit-transform: scaleY(0.1);
  }
  100% {
    -webkit-transform: scaleY(1);
  }
}

.aggregatorElement {
  border: none;
  background: transparent;
}

.aggregatorElement h3 {
  background: transparent;
  padding: 5px 0;
  color: #111111;
  border-bottom: solid 1px #0b9444;
}

.aggregatorElement h3 span {
  font-size: 1.5rem;
}

.aggregatorElement .item {
  box-shadow: none;
  border-bottom: none;
  margin: 0 15px;
  padding: 15px 0;
  border-bottom: solid 1px #ddd;
}

.aggregatorElement .item.extendedOptions {
  background: transparent;
}

.aggregatorElement .item .teaser {
  margin-top: 10px;
  font-size: .9rem;
  letter-spacing: .025rem;
}

.aggregatorElement .newsItemElement h4 {
  float: left;
  width: 60%;
  clear: none;
  color: #2d2d2d;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.aggregatorElement .newsItemElement .newsDate, .aggregatorElement .newsItemElement .newsAuthor {
  display: block;
  margin-bottom: 0;
}

.aggregatorElement .newsItemElement .newsItemHeader a {
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  height: 125px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-right: 3%;
  float: left;
  width: 250px;
}

.aggregatorElement .newsItemElement .newsItemHeader a img {
  margin: 0;
}

.aggregatorElement .newsItemElement .newsItemHeader h2 {
  width: 60%;
  display: inline-block;
  vertical-align: top;
  float: none;
  font-size: 18px;
}

.aggregatorElement .newsItemElement .newsItemHeader h2 a {
  width: 100%;
  height: auto;
  color: #0b9444;
  transition: all 0.2s ease-in-out;
}

.aggregatorElement .newsItemElement .newsItemHeader h2 a:hover {
  color: #8c9192;
}

.aggregatorElement.newsAggregatorElement h3 {
  border-bottom: none;
}

.aggregatorElement.newsAggregatorElement .item {
  margin: 0;
  padding: 10px;
  background: white;
  border-left: solid 1px #c2c6ca;
  border-right: solid 1px #c2c6ca;
}

.aggregatorElement.newsAggregatorElement .item:first-of-type {
  border-top: solid 1px #c2c6ca;
}

.aggregatorElement.newsAggregatorElement .item > a {
  height: 65px;
  width: 110px;
  float: left;
  overflow: hidden;
  position: relative;
  background: white;
}

.aggregatorElement.newsAggregatorElement .item > a img.tall {
  height: auto;
  width: 100%;
}

.aggregatorElement.newsAggregatorElement .item > a img.wide {
  height: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.aggregatorElement.newsAggregatorElement .item h4 a {
  color: #2d2d2d;
  font-size: .8rem;
  letter-spacing: .025rem;
  font-weight: 700;
  line-height: normal;
  transition: all 0.2s ease-in-out;
  font-family: "Roboto", sans-serif;
}

.aggregatorElement.newsAggregatorElement .item h4 a:hover {
  color: #0b9444;
}

.aggregatorElement.newsAggregatorElement .item .details {
  padding-left: 0;
  width: calc(100% - 125px);
  float: left;
  margin-left: 10px;
}

.aggregatorElement.newsAggregatorElement .item .details .dateAuthor {
  margin-top: 5px;
}

.aggregatorElement.newsAggregatorElement .item .details .teaser {
  font-size: .65rem;
  letter-spacing: .025rem;
  font-family: "Roboto", sans-serif;
}

.aggregatorElement.newsAggregatorElement .item .commentsAndMore, .aggregatorElement.newsAggregatorElement .item.extendedOptions {
  display: none;
}

.aggregatorElement.newsAggregatorElement .item .teaser {
  margin-top: 2px;
}

.aggregatorElement.newsAggregatorElement .item:last-of-type {
  border-bottom: none;
}

.aggregatorElement.eventAggregatorElement h3 {
  border-bottom: none;
}

.aggregatorElement.eventAggregatorElement .vevent {
  padding: 15px;
  box-shadow: none;
  border-bottom: solid 1px #c2c6ca;
  margin: 0;
  background: white;
  border-right: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
}

.aggregatorElement.eventAggregatorElement .vevent:first-of-type {
  border-top: solid 1px #c2c6ca;
}

.aggregatorElement.eventAggregatorElement .vevent .summary {
  margin-bottom: 5px;
}

.aggregatorElement.eventAggregatorElement .vevent .summary a {
  color: #2d2d2d;
  transition: all 0.2s ease-in-out;
  font-family: "Roboto", sans-serif;
  font-size: .9rem;
  letter-spacing: .025rem;
  font-weight: 600;
  line-height: normal;
}

.aggregatorElement.eventAggregatorElement .vevent .summary a:hover {
  color: #ffc423;
}

.aggregatorElement.eventAggregatorElement .vevent .details {
  font-weight: 300;
  color: #8c9192;
  font-family: "Roboto", sans-serif;
}

.aggregatorElement.eventAggregatorElement .vevent .details .time {
  font-weight: 300;
  font-size: .7rem;
}

.aggregatorElement.eventAggregatorElement .dateImage {
  border: none;
  background: transparent;
}

.aggregatorElement.eventAggregatorElement .dateImage .month {
  background: transparent;
  border-top: none;
  color: #2d2d2d;
  font-weight: 300;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.aggregatorElement.eventAggregatorElement .dateImage .date {
  font-weight: 600;
  color: #111111;
  font-size: 1.75rem;
}

.aggregatorElement.eventAggregatorElement .item {
  color: #eeeeee;
}

.aggregatorElement.eventAggregatorElement .extendedOptions a {
  font-family: "Roboto", sans-serif;
  font-size: .8rem;
  font-weight: 500;
}

.aggregatorElement.eventAggregatorElement .extendedOptions a[title*='View All'] {
  display: none;
}

.user_mode .pe33 .aggregatorElement .item .details h4 a,
.user_mode .pe25 .aggregatorElement .item .details h4 a {
  font-size: 14px !important;
  line-height: normal !important;
  padding: 0 15px;
}

.user_mode .pe33 .aggregatorElement .item .details,
.user_mode .pe25 .aggregatorElement .item .details {
  padding: 0 20px !important;
}

.user_mode .pe25 .aggregatorElement .item .details {
  width: 100% !important;
}

.user_mode .pe25 .aggregatorElement .item .details h4 a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_mode .customAgg:hover .aggregatorElement .item {
  border-bottom: solid 4px #0b9444;
}

.user_mode .customAgg .aggregatorElement .aggHeader {
  display: none;
}

.user_mode .customAgg .aggregatorElement .item {
  margin: 0;
  padding: 0;
  background: #111111;
  position: relative;
  border-bottom: solid 4px #c2c6ca;
  transition: all 0.2s ease-in-out;
  margin-bottom: 15px;
}

.user_mode .customAgg .aggregatorElement .item.extendedOptions {
  display: none;
}

.user_mode .customAgg .aggregatorElement .item a {
  display: block;
  width: auto;
  height: auto;
  float: none;
  background: black;
}

.user_mode .customAgg .aggregatorElement .item a img {
  width: calc(100% + 1px);
  height: auto;
  opacity: .8;
  transition: all 0.2s ease-in-out;
  display: block;
}

.user_mode .customAgg .aggregatorElement .item a img.tall {
  width: calc(100% + 1px);
}

.user_mode .customAgg .aggregatorElement .item a img.wide {
  height: auto;
  width: calc(100% + 1px);
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

.user_mode .customAgg .aggregatorElement .item .details {
  position: absolute;
  bottom: 25px;
  padding-left: 0;
  padding: 0 50px;
  width: auto;
  margin-left: 0;
  float: none;
}

.user_mode .customAgg .aggregatorElement .item .details h4 a {
  font-family: "Orbitron", sans-serif;
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  background: transparent;
  letter-spacing: .05rem;
}

.user_mode .customAgg .aggregatorElement .item .details .aggTag {
  font-family: "Orbitron", sans-serif;
  color: white;
  text-transform: uppercase;
  padding: 5px 10px;
  background: #0b9444;
  margin-right: 10px;
  padding-left: 50px;
  background: #0b9444;
  font-size: 1rem;
  color: white;
  font-family: "Orbitron", sans-serif;
  left: 0;
  position: absolute;
  top: -40px;
}

.user_mode .customAgg .aggregatorElement .item .details .dateAuthor {
  position: absolute;
  top: -30px;
  font-size: 14px;
  color: white;
  font-family: "Orbitron", sans-serif;
  left: 0;
}

.user_mode .customAgg .aggregatorElement .item .details .dateAuthor .expandedView {
  display: block;
  float: left;
}

.user_mode .customAgg .aggregatorElement .item .details .teaser {
  color: white;
}

.user_mode .customAgg .aggregatorElement .item .details .commentsAndMore {
  display: none;
}

.user_mode .customAgg .aggregatorElement .item:hover a img {
  opacity: 1;
}

.spotlight .pageElement.aggregatorElement {
  background: #0b9444;
  font-family: "Orbitron", sans-serif;
  padding: 30px;
}

.spotlight .pageElement.aggregatorElement h3.aggHeader {
  background: transparent;
  font-family: "Orbitron", sans-serif;
}

.spotlight .pageElement.aggregatorElement h3.aggHeader span {
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: .025rem;
  font-weight: 600;
}

.spotlight .pageElement.aggregatorElement h3.aggHeader span span {
  display: block;
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: .025rem;
  font-weight: 300;
}

.spotlight .pageElement.aggregatorElement .item {
  border-bottom: none;
  box-shadow: none;
}

.spotlight .pageElement.aggregatorElement .item .newsItemElement {
  position: relative;
}

.spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader > a {
  width: 40%;
  display: inline-block;
  float: left;
  background: #111111;
  margin-right: 0;
  height: auto;
}

.spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader > a img {
  width: 100%;
  opacity: .8;
  transition: all 0.2s ease-in-out;
  margin: 0;
}

.spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader h2 {
  width: 47%;
  margin-left: 13%;
  float: left;
}

.spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader h2 a {
  color: white;
  font-size: 1.5vw;
  font-weight: 300;
  line-height: normal;
  border-bottom: solid 4px #0b9444;
  padding-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader h2 a:hover {
  border-bottom: solid 4px white;
}

.spotlight .pageElement.aggregatorElement .item .newsItemElement .newsDate,
.spotlight .pageElement.aggregatorElement .item .newsItemElement .newsAuthor {
  display: none;
}

.spotlight .pageElement.aggregatorElement .item .newsItemElement h4 {
  line-height: 130%;
  display: inline-block;
  color: white;
  font-size: 3vw;
  top: -5px;
  position: absolute;
  left: 44%;
  font-weight: 600;
  width: auto;
}

.spotlight .pageElement.aggregatorElement .item.extendedOptions {
  display: none;
}

.spotlight .pageElement.aggregatorElement .item:hover .newsItemElement .newsItemHeader > a img {
  opacity: 1;
}

.aggregatorElement a img.wide {
  height: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.aggregatorElement a img.tall {
  height: auto;
  width: 100%;
}

#sidePanelContainer {
  max-width: 300px;
  margin: 0 calc(50% - 150px);
}

#sidePanelContainer .sidePanel {
  padding-left: 0;
}

#sidePanelContainer .sidePanel .pageElement {
  margin-right: 0;
}

#sidePanelContainer .sidePanel .aggregatorElement h3 {
  border-bottom: none;
}

#sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item {
  margin: 0;
  padding: 10px;
  background: white;
  border-left: solid 1px #c2c6ca;
  border-right: solid 1px #c2c6ca;
}

#sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item:first-of-type {
  border-top: solid 1px #c2c6ca;
}

#sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item .details {
  padding-left: 0;
}

#sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item .details .dateAuthor {
  display: none;
}

#sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item .details .teaser {
  font-size: .65rem;
  letter-spacing: .025rem;
  font-family: "Roboto", sans-serif;
}

#sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item .teaser {
  margin-top: 2px;
}

#sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item .commentsAndMore, #sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item.extendedOptions {
  display: none;
}

#sidePanelContainer .sidePanel .aggregatorElement.newsAggregatorElement .item:last-of-type {
  border-bottom: none;
}

#NewsArticles .item {
  background: white !important;
}

#NewsArticles .item h4 a {
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 1024px) {
  .user_mode .customAgg .aggregatorElement .item .details h4 a {
    font-size: 5vmin;
  }
  .user_mode #sidePanelContainer {
    max-width: 100%;
    margin: auto;
  }
  .mobileMargin {
    max-width: 300px;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .user_mode .pe33 .aggregatorElement .item > a,
  .user_mode .pe25 .aggregatorElement .item > a {
    width: 100%;
    height: 140px;
  }
  .user_mode .pe33 .aggregatorElement .item .details,
  .user_mode .pe25 .aggregatorElement .item .details {
    margin-left: 0;
    margin-top: 10px;
  }
  .user_mode .pe33 .aggregatorElement .item .details h4 a,
  .user_mode .pe25 .aggregatorElement .item .details h4 a {
    font-size: 14px !important;
    line-height: normal !important;
  }
  .user_mode .pe33 .aggregatorElement .item .details,
  .user_mode .pe25 .aggregatorElement .item .details {
    padding: 0 !important;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .spotlight .pageElement.aggregatorElement h3.aggHeader span {
    font-size: 6vw;
  }
  .spotlight .pageElement.aggregatorElement .item .newsItemElement h4 {
    font-size: 8vmin;
  }
  .spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader h2 a {
    font-size: 3vmin;
  }
  .aggregatorElement .newsItemElement h4 {
    text-align: center;
    width: 100%;
  }
  .aggregatorElement .newsItemElement .newsDate,
  .aggregatorElement .newsItemElement .newsAuthor {
    width: 100%;
    text-align: center;
  }
  .aggregatorElement .newsItemElement .newsItemHeader a {
    width: 100%;
    text-align: center;
  }
  .aggregatorElement .newsItemElement .newsItemHeader a img {
    float: none;
  }
  .aggregatorElement .newsItemElement .newsItemHeader h2 {
    width: 100%;
    margin-top: 15px;
  }
  .user_mode .pe33 .aggregatorElement .item .details h4 a,
  .user_mode .pe25 .aggregatorElement .item .details h4 a {
    font-size: 5vmin;
  }
  .user_mode .customAgg .aggregatorElement .item .details {
    width: 100%;
  }
  .user_mode .customAgg .aggregatorElement .item .details .teaser {
    height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 640px) {
  .spotlight .pageElement.aggregatorElement {
    padding: 15px;
  }
  .spotlight .pageElement.aggregatorElement .item .newsItemElement h4 {
    font-size: 8vmin;
    width: 60%;
    text-align: left;
  }
  .spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader a img {
    float: left;
  }
  .spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader h2 {
    margin-top: 0;
  }
  .spotlight .pageElement.aggregatorElement .item .newsItemElement .newsItemHeader h2 a {
    font-size: 4vmin;
    text-align: left;
  }
  .aggregatorElement.newsAggregatorElement .item h4 a {
    font-size: 1rem;
  }
}

.widgetHierarchy {
  background: transparent;
}

.widgetHierarchy li li li {
  background: #c2c6ca;
}

.widgetHierarchy .singleLevel {
  background: transparent;
  border: none;
  padding: 15px;
}

.widgetHierarchy .singleLevel h4 {
  color: #2d2d2d;
}

.widgetHierarchy .singleLevel h4 a {
  color: #111111;
}

.widgetHierarchy ul li {
  background: transparent;
  border: none;
  overflow-x: scroll;
}

.widgetHierarchy ul li::-webkit-scrollbar {
  background: transparent;
}

.widgetHierarchy ul li .sportTableLabel h4 {
  color: #2d2d2d;
}

.widgetHierarchy ul li .sportTableLabel h4 a, .widgetHierarchy ul li .sportTableLabel h4 span {
  color: #111111;
  font-family: "Roboto", sans-serif;
}

.widgetHierarchy ul li table.statTable .teamLogo-small {
  display: none;
}

.widgetHierarchy ul li table.statTable .playerTeam {
  display: block;
  color: #6a6a6a;
  font-size: .7rem;
}

.widgetHierarchy ul li table.statTable .leaderName {
  float: none;
}

.widgetHierarchy.widget-roster-player table {
  background: transparent;
}

.widgetHierarchy.widget-roster-player .leader-highlight {
  background: transparent;
}

.widgetHierarchy.widget-roster-player .leader-highlight .sport-colors {
  display: none;
}

.widgetHierarchy.widget-roster-player .leader-highlight .stats {
  margin: 0;
}

.widgetHierarchy.widget-roster-player .leader-highlight .stats .stats-minor {
  float: left;
}

.widgetHierarchy.widget-roster-player .leader-highlight .stats .stats-major {
  background: transparent;
  padding: 0;
  margin-left: 5px !important;
  font-size: 13px;
  float: left;
}

.widgetHierarchy .sportTableLabel .teamLogo-tiny {
  display: none;
}

.widgetHierarchy.widget-leader li {
  background: transparent;
  border: none;
}

.widgetHierarchy.widget-leader li .sportTableLabel {
  display: none;
}

.widgetHierarchy.widget-leader li h4 {
  font-size: 1.75rem;
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  line-height: normal;
}

.widgetHierarchy.widget-leader li h4 a {
  color: #2d2d2d;
}

.widgetHierarchy.widget-leader li .leader-highlight {
  background: transparent;
  text-align: left;
}

.widgetHierarchy.widget-leader li .leader-highlight .sport-colors {
  min-height: 0;
  height: 0;
  border-bottom: none;
  float: none;
}

.widgetHierarchy.widget-leader li .leader-highlight .sport-colors .color0-m,
.widgetHierarchy.widget-leader li .leader-highlight .sport-colors .color1-m,
.widgetHierarchy.widget-leader li .leader-highlight .sport-colors .color2-m,
.widgetHierarchy.widget-leader li .leader-highlight .sport-colors .edge {
  display: none;
}

.widgetHierarchy.widget-leader li .leader-highlight .sport-colors .color0-m {
  display: none;
}

.widgetHierarchy.widget-leader li .leader-highlight .sport-colors div.player_photo {
  position: relative;
  float: left;
  left: 0;
  border: solid 3px #2d2d2d;
  border-radius: 100px;
  height: 90px;
  width: 90px;
}

.widgetHierarchy.widget-leader li .leader-highlight .sport-colors div.player_photo a img {
  width: 100%;
}

.widgetHierarchy.widget-leader li .leader-highlight .stats {
  margin: 0 0 10px 120px;
}

.widgetHierarchy.widget-leader li .leader-highlight .stats .stats-major {
  background: transparent;
  color: #444444;
  font-size: 4rem;
  line-height: 3rem;
  font-family: "Orbitron", sans-serif;
  padding-top: 15px;
}

.widgetHierarchy.widget-leader li .leader-highlight .stats .stats-minor a {
  color: #444444;
  font-size: 2rem;
  line-height: normal;
  font-family: "Orbitron", sans-serif;
  font-weight: 300;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable {
  background: transparent;
  border: none;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable th {
  background: #c2c6ca;
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
  padding: 10px;
  font-size: 1rem;
  font-weight: 600;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable th:first-of-type {
  padding: 5px 10px;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr {
  border-bottom: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
  border-right: solid 1px #c2c6ca;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr.highlight, .widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr.odd, .widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr.even {
  background: white !important;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr td {
  padding: 5px 10px;
  font-size: .9rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: .025rem;
  border-right: solid 1px #c2c6ca;
  font-weight: 600;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr td:first-of-type {
  padding: 15px 10px;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr td a {
  color: #2d2d2d;
  transition: all 0.2s ease-in-out;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr td a:hover {
  color: #ffc423;
}

.widgetHierarchy.widget-leader li .NginTableWrapper table.statTable tr td .teamLogo-tiny {
  display: none;
}

.widgetHierarchy.widget-gameTable .statTable {
  border: none;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.widgetHierarchy.widget-gameTable .statTable tr {
  background: white !important;
}

.widgetHierarchy.widget-gameTable .statTable .even {
  background: transparent;
}

.widgetHierarchy.widget-gameTable .statTable .game-location-small-list {
  border-top: none;
}

.widgetHierarchy.widget-gameTable .statTable td {
  border: solid 1px #c2c6ca;
  padding: 0 !important;
}

.widgetHierarchy.widget-gameTable .statTable td:nth-of-type(2) {
  text-align: center;
  background: #0b9444;
  border: none;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.widgetHierarchy.widget-gameTable .statTable td:nth-of-type(2):hover {
  background: #ffc423;
}

.widgetHierarchy.widget-gameTable .statTable td .game-status {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}

.widgetHierarchy.widget-gameTable .statTable td .game-status:after {
  content: '\f105';
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 0;
  font-family: "FontAwesome";
  font-size: 1rem;
  height: 100%;
  line-height: 103px;
  width: calc(100% - 30px);
  text-align: right;
}

.widgetHierarchy.widget-gameTable .statTable td .game-info-div {
  color: white;
}

.widgetHierarchy.widget-gameTable .statTable td .game-date,
.widgetHierarchy.widget-gameTable .statTable td .game-location {
  font-size: 12px !important;
  color: #f1f1f1;
}

.widgetHierarchy.widget-gameTable .statTable td .gameListTeam {
  height: auto;
  line-height: 40px;
  border-bottom: 1px solid #c2c6ca;
  margin-bottom: 0;
  background: white;
  padding: 0 10px;
}

.widgetHierarchy.widget-gameTable .statTable td .gameListTeam:last-of-type {
  border-bottom: none;
}

.widgetHierarchy.widget-gameTable .statTable td .gameListTeam .gameListTeamName {
  padding: 10px 0;
  height: 50px;
  font-family: "Roboto", sans-serif;
  font-size: .9rem;
}

.widgetHierarchy.widget-gameTable .statTable td .gameListTeam .gameListTeamName a {
  color: #2d2d2d;
  font-weight: 600;
}

.widgetHierarchy.widget-gameTable .statTable td .gameListTeam .gameListTeamName a.teamLogo-tiny {
  height: 30px !important;
  width: 30px !important;
  border-radius: 0;
  border: none;
  background-size: 100%;
}

.widgetHierarchy.widget-gameTable .statTable td .gameListTeam .gameListTeamName a.teamName {
  line-height: 30px;
}

.widgetHierarchy.widget-gameTable .statTable td .score {
  float: right;
  margin-top: 5px;
  font-family: "Orbitron", sans-serif;
  color: #2d2d2d;
}

.widgetHierarchy.widget-gameTable .statTable .game-location-small-list {
  color: #0b9444;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Orbitron", sans-serif;
}

.widgetHierarchy.widget-gameTable .statTable .game-location-small-list .game-status {
  color: #0b9444;
}

.widgetHierarchy.widget-gameTable .statTable .game-location-small-list:after {
  content: '\f054';
  position: relative;
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 9px;
  margin-left: 5px;
}

.updates .widgetHierarchy {
  padding: 0 15px 15px;
}

.boxscore-extras.in_progress p.details {
  background: transparent;
}

.boxscore-extras.in_progress p.details a {
  padding-left: 0;
}

.boxscore-container {
  background: #8c9192 !important;
  border-radius: 0;
  padding: 15px 0 0;
  font-family: "Roboto", sans-serif;
  position: relative;
}

.boxscore-container:before {
  content: 'VS';
  display: block;
  width: 100%;
  position: absolute;
  color: #2d2d2d;
  text-align: center;
  top: 40px;
}

.boxscore-container .team-score {
  height: 100px;
  width: 50%;
}

.boxscore-container .team-score .teamLogo-medium {
  display: none !important;
}

.boxscore-container .team-score .abbr {
  top: 40px;
  background: transparent;
  width: 100%;
}

.boxscore-container .team-score p {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  color: #2d2d2d;
  font-weight: 600;
}

.boxscore-container .boxscore-extras {
  border-radius: 0;
  border: none;
  background: #c2c6ca;
  padding: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.boxscore-container .boxscore-extras:hover {
  background: #ffc423;
}

.boxscore-container .boxscore-extras .key,
.boxscore-container .boxscore-extras .value {
  font-family: "Roboto", sans-serif;
  color: #2d2d2d;
  font-size: .75rem;
}

.boxscore-container .boxscore-extras .details {
  float: right;
}

.boxscore-container .boxscore-extras .details a {
  position: relative;
  margin-right: 10px;
  float: right;
}

.boxscore-container .boxscore-extras .details a:before {
  content: 'See More »';
  display: inline-block;
  position: relative;
  color: #0b9444;
  top: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
}

.boxscore-container .boxscore-extras .details a:hover:before {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

.boxscore-container .boxscore-extras .details a img {
  display: none;
}

.boxscore-container .winners-notch {
  background: transparent;
}

.boxscore-container .winners-notch:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #c2c6ca;
  top: 0;
  left: 0;
}

.pe33 .boxscore-container .boxscore-extras, .pe25 .boxscore-container .boxscore-extras {
  padding-bottom: 0;
}

.pe33 .boxscore-container .boxscore-extras .details, .pe25 .boxscore-container .boxscore-extras .details {
  width: 100%;
  margin-top: 10px;
}

.pe33 .boxscore-container .boxscore-extras .details a, .pe25 .boxscore-container .boxscore-extras .details a {
  width: 100%;
  padding: 0;
}

.pe33 .boxscore-container .boxscore-extras .details a:before, .pe25 .boxscore-container .boxscore-extras .details a:before {
  width: 100%;
  padding: 7px 10px 5px;
}

.pageElement .boxscore-container {
  position: relative;
  border-radius: 0;
}

.pe50 .boxscore-container, .pe66 .boxscore-container, .pe100 .boxscore-container, #tab_boxscores_content .boxscore-container {
  padding-top: 0;
  background: transparent !important;
}

.pe50 .boxscore-container:before, .pe66 .boxscore-container:before, .pe100 .boxscore-container:before, #tab_boxscores_content .boxscore-container:before {
  display: none;
}

.pe50 .boxscore-container .team-score, .pe66 .boxscore-container .team-score, .pe100 .boxscore-container .team-score, #tab_boxscores_content .boxscore-container .team-score {
  display: none;
}

.pe50 .boxscore-container .boxscore-inner-container, .pe66 .boxscore-container .boxscore-inner-container, .pe100 .boxscore-container .boxscore-inner-container, #tab_boxscores_content .boxscore-container .boxscore-inner-container {
  display: block;
  margin: 0 !important;
  position: relative;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore, .pe66 .boxscore-container .boxscore-inner-container .boxscore, .pe100 .boxscore-container .boxscore-inner-container .boxscore, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore th, .pe66 .boxscore-container .boxscore-inner-container .boxscore th, .pe100 .boxscore-container .boxscore-inner-container .boxscore th, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore th {
  background: #c2c6ca;
  color: #2d2d2d;
  font-size: 1rem;
  padding: 10px 15px;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore th.colgroup-edge, .pe66 .boxscore-container .boxscore-inner-container .boxscore th.colgroup-edge, .pe100 .boxscore-container .boxscore-inner-container .boxscore th.colgroup-edge, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore th.colgroup-edge {
  border-left: none;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore th.team, .pe50 .boxscore-container .boxscore-inner-container .boxscore th:last-child, .pe66 .boxscore-container .boxscore-inner-container .boxscore th.team, .pe66 .boxscore-container .boxscore-inner-container .boxscore th:last-child, .pe100 .boxscore-container .boxscore-inner-container .boxscore th.team, .pe100 .boxscore-container .boxscore-inner-container .boxscore th:last-child, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore th.team, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore th:last-child {
  border-radius: 0;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-away td,
.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-home td, .pe66 .boxscore-container .boxscore-inner-container .boxscore .team-away td,
.pe66 .boxscore-container .boxscore-inner-container .boxscore .team-home td, .pe100 .boxscore-container .boxscore-inner-container .boxscore .team-away td,
.pe100 .boxscore-container .boxscore-inner-container .boxscore .team-home td, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-away td,
#tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-home td {
  background: transparent;
  border-bottom: solid 1px #c2c6ca;
  padding: 15px;
  font-size: 1rem;
  color: #2d2d2d;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team,
.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team, .pe66 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team,
.pe66 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team, .pe100 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team,
.pe100 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-away td.team,
#tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-home td.team {
  background: transparent;
  border-bottom: solid 1px #c2c6ca;
  padding: 15px;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team .teamLogo-small,
.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team .teamLogo-small, .pe66 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team .teamLogo-small,
.pe66 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team .teamLogo-small, .pe100 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team .teamLogo-small,
.pe100 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team .teamLogo-small, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-away td.team .teamLogo-small,
#tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-home td.team .teamLogo-small {
  display: none;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team a,
.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team a, .pe66 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team a,
.pe66 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team a, .pe100 .boxscore-container .boxscore-inner-container .boxscore .team-away td.team a,
.pe100 .boxscore-container .boxscore-inner-container .boxscore .team-home td.team a, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-away td.team a,
#tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-home td.team a {
  color: #2d2d2d;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-away td.colgroup-edge,
.pe50 .boxscore-container .boxscore-inner-container .boxscore .team-home td.colgroup-edge, .pe66 .boxscore-container .boxscore-inner-container .boxscore .team-away td.colgroup-edge,
.pe66 .boxscore-container .boxscore-inner-container .boxscore .team-home td.colgroup-edge, .pe100 .boxscore-container .boxscore-inner-container .boxscore .team-away td.colgroup-edge,
.pe100 .boxscore-container .boxscore-inner-container .boxscore .team-home td.colgroup-edge, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-away td.colgroup-edge,
#tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore .team-home td.colgroup-edge {
  border-left: none;
  background: transparent;
  color: #2d2d2d;
  font-weight: 700;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore-extras, .pe66 .boxscore-container .boxscore-inner-container .boxscore-extras, .pe100 .boxscore-container .boxscore-inner-container .boxscore-extras, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore-extras {
  background: #c2c6ca;
}

.pe50 .boxscore-container .boxscore-inner-container .boxscore-extras p, .pe66 .boxscore-container .boxscore-inner-container .boxscore-extras p, .pe100 .boxscore-container .boxscore-inner-container .boxscore-extras p, #tab_boxscores_content .boxscore-container .boxscore-inner-container .boxscore-extras p {
  font-size: .8rem;
  font-weight: 600;
  letter-spacing: .025rem;
}

.pe25 .boxscore-container, .pe33 .boxscore-container, .pe50 .boxscore-container, .pe66 .boxscore-container, .pe100 .boxscore-container {
  background: white !important;
  border: solid 1px #c2c6ca;
}

.pe25 .boxscore-container .teamLogo-medium, .pe33 .boxscore-container .teamLogo-medium, .pe50 .boxscore-container .teamLogo-medium, .pe66 .boxscore-container .teamLogo-medium, .pe100 .boxscore-container .teamLogo-medium {
  border: none;
  background-size: auto 100%;
  width: 100% !important;
  margin: 0 0 5px 0;
  height: 75px !important;
}

@media screen and (max-width: 768px) {
  .widgetHierarchy.widget-gameTable .statTable td {
    width: calc(100% - 15px);
    display: block;
  }
  .widgetHierarchy.widget-gameTable .statTable td:nth-of-type(2) {
    padding: 20px 0 !important;
  }
  .widgetHierarchy.widget-gameTable .statTable td .game-status:after {
    top: -10px;
  }
}

@media screen and (max-width: 480px) {
  .widgetHierarchy.widget-leader li .leader-highlight .stats .stats-minor a {
    font-size: 1.5rem;
  }
}

.pageElement .sn-call-to-action {
  background: black;
  height: auto !important;
}

.pageElement .sn-call-to-action img {
  opacity: .8;
  filter: blur(1px);
  transition: all 0.2s ease-in-out;
  width: calc(100% + 1px);
  height: auto !important;
}

.pageElement .sn-call-to-action:hover img {
  filter: blur(0);
  opacity: .9;
}

.pageElement .sn-call-to-action .sn-call-to-action-title {
  font-size: .75rem;
  background: #0b9444;
  padding: 5px 10px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  letter-spacing: .05rem;
}

.pageElement .sn-call-to-action p.sn-call-to-action-subtitle {
  font-family: "Orbitron", sans-serif;
  font-size: 3vw;
  font-weight: 600;
}

.pageElement .sn-call-to-action p.sn-call-to-action-subtitle span {
  line-height: normal;
}

.pageElement .sn-call-to-action.removeTitle .sn-call-to-action-title {
  display: none;
}

.customCta .pageElement.textBlockElement {
  background: white;
  padding: 30px;
  transition: all 0.2s ease-in-out;
}

.customCta .pageElement.textBlockElement:hover .leftTextImage,
.customCta .pageElement.textBlockElement:hover .rightTextImage {
  border: solid 8px #0b9444;
}

.customCta .pageElement.textBlockElement:hover .text p a {
  color: #111111;
}

.customCta .pageElement.textBlockElement:hover .text p a:after {
  color: #0b9444;
}

.customCta .pageElement.textBlockElement .leftTextImage,
.customCta .pageElement.textBlockElement .rightTextImage {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 100px;
  margin: 0 calc(50% - 75px);
  overflow: hidden;
  margin-bottom: 15px;
  border: solid 3px #2d2d2d;
  transition: all 0.2s ease-in-out;
}

.customCta .pageElement.textBlockElement .leftTextImage img.wide,
.customCta .pageElement.textBlockElement .rightTextImage img.wide {
  height: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customCta .pageElement.textBlockElement .leftTextImage img.tall,
.customCta .pageElement.textBlockElement .rightTextImage img.tall {
  height: auto;
  width: 100%;
}

.customCta .pageElement.textBlockElement .text p {
  font-family: "Orbitron", sans-serif;
  color: #111111;
  font-size: 2.5vw;
  font-weight: 600;
  line-height: normal;
  max-width: 75%;
  margin: auto;
}

.customCta .pageElement.textBlockElement .text p a {
  color: #0b9444;
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  display: block;
  transition: all 0.2s ease-in-out;
}

.customCta .pageElement.textBlockElement .text p a:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 90%;
  height: 2px;
  background: #0b9444;
  left: 5%;
  top: -25px;
}

.customCta .pageElement.textBlockElement .text p a:after {
  content: '\f054';
  font-family: "FontAwesome";
  display: inline-block;
  position: relative;
  margin-left: 7px;
  font-size: 1rem;
  color: #0b9444;
}

.altCta .pageElement .sn-call-to-action img {
  opacity: .7;
  filter: blur(0);
}

.altCta .pageElement .sn-call-to-action:hover img {
  opacity: .9;
}

.altCta .pageElement .sn-call-to-action .sn-call-to-action-overlay-text {
  vertical-align: bottom;
}

.altCta .pageElement .sn-call-to-action .sn-call-to-action-overlay-text p.sn-call-to-action-subtitle {
  margin-bottom: 0;
  text-align: left;
  font-weight: 300;
}

.altCta .pageElement .sn-call-to-action .sn-call-to-action-title {
  display: block;
  background: transparent;
  text-align: left;
  font-size: 4vw;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0 10px;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .customCta .pageElement.textBlockElement .text p {
    font-size: 7.5vmin;
  }
  .altCta .pageElement .sn-call-to-action .sn-call-to-action-title {
    font-size: 10vmin;
  }
  .altCta .pageElement .sn-call-to-action .sn-call-to-action-subtitle {
    font-size: 8vmin;
  }
  .pageElement .sn-call-to-action p.sn-call-to-action-subtitle span {
    font-size: 8vmin;
  }
}

.sport-tools {
  background: transparent;
}

#sport-header {
  float: left;
  display: block;
  width: 100%;
  border-top: none;
}

#sport-header .teamLogo {
  left: 15px;
  height: 40px;
  width: 120px;
}

#sport-header.noDrop #megaDropDown-seasons {
  padding-right: 10px !important;
}

#sport-header #snm_advert {
  display: none;
  text-decoration: none;
  padding: 10px;
  background: transparent;
  position: absolute;
  z-index: 1;
  opacity: 0.8;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#sport-header #snm_advert:before {
  width: 30px;
  height: 25px;
  position: absolute;
  display: block;
  font: normal 14px/1em "Orbitron", sans-serif;
  font-size: 1.75em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  background: url("http://app-assets2.sportngin.com/app_images/marketing/mobilePhone.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#sport-header #snm_advert:hover {
  opacity: 1;
}

#sport-header #snm_advert span,
#sport-header #snm_advert strong {
  color: #444;
  font-size: 9px;
  text-align: left;
  display: block;
  line-height: 13px;
  display: block;
  padding-left: 33px;
}

#sport-header .sport-tools {
  margin: 10px 0px 20px 0px;
  width: 100%;
  display: block;
  background: none;
  border-top: 1px solid #efefef;
  float: left;
  padding: 0px;
  background: transparent;
}

#sport-header .sport-tools ul {
  width: 100%;
  z-index: 1;
  position: relative;
}

#sport-header .sport-tools ul:after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: #efefef;
  float: left;
  z-index: -1;
  position: absolute;
  opacity: 1;
  visibility: visible;
  bottom: 0px;
}

#sport-header .sport-tools ul li {
  display: inline-block;
  margin: auto;
  background: none;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 3px solid #eee;
  float: left;
  list-style: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

#sport-header .sport-tools ul li a {
  display: inline-block;
  text-align: center;
  padding: 10px;
  background: none;
  border: none;
  list-style: none;
  cursor: pointer;
  color: #2d2d2d;
  display: block;
  font: 0.8rem "Roboto", sans-serif;
  outline: medium none;
  text-decoration: none;
  width: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

#sport-header .sport-tools ul li a:before {
  font: normal normal normal 14px/1em "FontAwesome";
  padding: 0px;
  display: none;
}

#sport-header .sport-tools ul li:hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-bottom: 3px solid #0b9444;
}

#sport-header .sport-tools ul li:hover a {
  color: #000;
}

#sport-header .sport-tools ul .selected {
  border-bottom: 3px solid #0b9444;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
}

#sport-header .sport-tools ul .selected:hover a {
  color: #0b9444;
}

#sport-header .sport-tools ul .selected a {
  color: #0b9444;
}

#sport-header .sport-tools ul #tool-home a span {
  background: none;
  text-indent: 0px;
  width: auto;
  height: auto;
}

#sport-header .sport-tools ul #tool-home a:before {
  content: "\f015";
}

#sport-header .sport-tools ul #tool-roster a:before {
  content: "\f007";
}

#sport-header .sport-tools ul #tool-game-schedule a:before {
  content: "\f133";
}

#sport-header .sport-tools ul #tool-player-stats a:before {
  content: "\f080";
}

#sport-header .sport-tools ul #tool-team-stats a:before {
  content: "\f1fe";
}

#sport-header .sport-tools ul #tool-standings a:before {
  content: "\f1b3";
}

#sport-header .sport-tools ul #tool-bracket a:before {
  content: "\f0e8";
}

#sport-header .sport-tools ul #tool-photo a:before {
  content: "\f03e";
}

#sport-header .sport-tools ul #tool-video a:before {
  content: "\f1c8";
}

#sport-header .sport-tools ul #tool-posts a:before {
  content: "\f086";
}

#sport-header .sport-tools ul .tool-yellow-admin a:before {
  content: "\f013";
}

#sport-header .sport-tools ul .tool-yellow a:before {
  content: "\f013";
}

#sport-header .sport-tools ul .tool-yellow-manage-team a:before {
  content: "\f024";
}

#sport-header .sport-tools ul.tools {
  width: 100%;
}

#sport-header .sport-tools .button-tool {
  float: left;
}

#sport-header .sport-tools li,
#sport-header .sport-tools #autoRefreshBar li {
  display: inline;
  float: left;
  border-right: 1px solid #eee;
}

#sport-header .sport-tools li.tool-yellow {
  border-color: #edc52c;
}

#sport-header .sport-tools li.tool-yellow a {
  background: #fddd40 url("http://app-assets2.sportngin.com/app_images/sport-header/btnsBG-yl.gif") 0 0 repeat-x;
  border-color: #ffc;
  color: #807540;
}

#sport-header .sport-tools li.tool-yellow:hover {
  border-color: #dda300;
  color: #594100;
}

#sport-header .sport-tools li.tool-yellow:hover a {
  background-color: #fddd40;
  background-position: 0 -80px;
  border-color: #ffc;
  color: #1a1a1a;
}

#sport-header .sport-tools li.tool-yellow:active {
  border-color: #dda300;
  color: #594100;
}

#sport-header .sport-tools li.tool-yellow:active a {
  background-color: #fddd40;
  background-position: 0 -160px;
  color: #333;
}

#sport-crumbs {
  position: relative;
  float: left;
  display: block;
  width: 100%;
  background: transparent;
  border-bottom: solid 1px #0b9444;
}

#sport-crumbs .sport-crumbs-btns {
  display: block;
  float: left;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

#sport-crumbs .sport-crumbs-btns::-webkit-scrollbar {
  display: none;
}

#sport-crumbs ul {
  display: inline-block;
  float: left;
}

#sport-crumbs ul li {
  float: left;
  list-style: none;
}

#sport-crumbs ul li a {
  padding: 0px;
  font-family: "Orbitron", sans-serif;
  color: #2d2d2d;
  font-size: .9rem;
  transition: all 0.2s ease-in-out;
}

#sport-crumbs ul li a:hover {
  color: green;
}

#sport-crumbs ul li a span {
  font-size: .9rem;
  transition: all 0.2s ease-in-out;
}

#sport-crumbs ul li a span:hover {
  color: #ffc423;
}

#sport-crumbs ul li a:before {
  content: "\f054";
  color: #0b9444;
  display: inline-block;
  font: normal 10px/1em "FontAwesome";
  padding: 2px;
  position: relative;
  margin: 0 0 0 10px;
}

#sport-crumbs ul li:first-child a:before {
  display: none;
}

#sport-crumbs ul li.crumbSelected {
  position: relative;
}

#sport-crumbs ul li.crumbSelected a {
  color: #000;
}

#sport-crumbs ul li.crumbSelected .arrow-down:after {
  content: "\f077";
  color: #000;
}

#sport-crumbs .sport-crumbs-list ul li a {
  padding: 10px 20px;
}

#sport-crumbs a {
  color: white;
  display: block;
  outline: medium none;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  font-weight: normal;
  background: none;
}

#sport-crumbs a.last_child {
  background: none;
}

#sport-crumbs a.last_child:hover {
  background: none;
}

#sport-crumbs a span {
  display: inline-block;
  font-size: 0.85em;
  padding: 10px;
  text-align: center;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#sport-crumbs a span:after {
  border: 1px solid #777;
  border-radius: 20px;
  font: 12px/1em "FontAwesome";
  margin: auto;
  padding: 4px;
  text-align: center;
}

#sport-crumbs a span.division, #sport-crumbs a span.league, #sport-crumbs a span.team {
  background: none;
  padding: 5px 0 5px 5px;
}

#sport-crumbs a .arrow-down {
  background: none;
  display: block;
  height: auto;
  margin: auto;
  opacity: 0.75;
  padding: 0;
  position: relative;
  right: 0px;
  top: 50%;
  width: auto;
  display: inline-block;
  padding-left: 10px;
}

#sport-crumbs a:hover {
  color: #eeeeee;
  background: none;
}

#sport-crumbs a.wide {
  padding: 0px;
}

#sport-crumbs .arrow-down:after {
  content: "\f078";
  display: inline-block;
}

#sport-crumbs .sport-crumbs-list-wrapper {
  display: block;
  width: 100%;
}

#sport-crumbs .sport-crumbs-list {
  display: none;
  text-align: left;
  float: left;
  margin: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  max-width: 50%;
  z-index: 20;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

#sport-crumbs .sport-crumbs-list.thisCrumb {
  display: block !important;
  -o-animation: dropdown-season all 0.2s ease-in-out;
  -moz-animation: dropdown-season all 0.2s ease-in-out;
  -webkit-animation: dropdown-season all 0.2s ease-in-out;
  animation: dropdown-season all 0.2s ease-in-out;
}

#sport-crumbs .sport-crumbs-list ul {
  list-style: none;
  width: 100%;
}

#sport-crumbs .sport-crumbs-list ul li {
  width: 100%;
  display: block;
}

#sport-crumbs .sport-crumbs-list ul li a {
  background: #fff;
  color: #666;
  border-bottom: 1px solid #eee;
  display: block;
  font-size: 12px;
  line-height: 24px;
  padding: 5px 10px;
  text-decoration: none;
  width: 100%;
  display: block;
}

#sport-crumbs .sport-crumbs-list ul li a:before {
  display: none;
}

#sport-crumbs .sport-crumbs-list ul li a img {
  margin: 0 3px -3px 0;
}

#sport-crumbs .sport-crumbs-list ul li a:hover {
  color: #eeeeee;
  background-color: #fafafa;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close {
  display: block;
  background: #fff;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a {
  display: block;
  float: right;
  text-align: right;
  width: 50px;
  border-bottom: 0px;
  background: transparent;
  padding: 15px 15px 10px 10px;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:after {
  content: "\f00d";
  position: relative;
  font: normal normal normal 12px/1em "FontAwesome";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #666;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 40px;
  padding: 3px 5px;
  border: 1px solid #666;
  color: #666;
  opacity: 0.7;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:after:hover {
  color: #000;
  border-color: #000;
}

#sport-crumbs .sport-crumbs-list ul .sport-crumbs-list-close a:hover {
  background: #fff;
  color: #000;
}

.sport-colors {
  clear: both;
  overflow: visible;
  position: relative;
  float: left;
  width: 100%;
  border-bottom: 1px solid #c2c6ca;
}

.sport-colors .color0,
.sport-colors .color1,
.sport-colors .color2 {
  display: none;
}

.sport-colors .edge {
  display: none;
}

.sport-colors h2 {
  float: left;
  font: bold 2.3em/1.5em "Orbitron", sans-serif;
  max-width: 75%;
  padding: 15px 0px 15px 0px;
  position: relative;
  text-shadow: 1px 1px 0 #fff;
  width: 100%;
  left: 0;
  display: inline-block;
  width: 100%;
  top: 0;
  left: 0;
}

.sport-colors h2 a {
  display: inline-block;
  text-decoration: none;
  padding: 0px;
  color: #111111;
  text-shadow: none;
}

.sport-colors .teamLogo-large {
  height: 75px !important;
  width: 75px !important;
  position: relative;
  display: inline-block;
  float: left;
  border-radius: 0;
  border: none;
  left: 0;
  top: 0;
  margin: 15px;
  background-size: contain;
  display: none;
}

.sport-colors .teamLogo-large[title="League"] {
  display: none;
}

/* Seasons Mega Drop Down */
.dropdown-wrapper {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
}

.dropdown-wrapper.mdd-active-open #megaDropDown-seasons:after {
  content: "\f077";
}

.dropdown-wrapper.mdd-active-open #megaDropDown-seasons-callout {
  display: block;
  -o-animation: dropdown-season all 0.2s ease-in-out;
  -moz-animation: dropdown-season all 0.2s ease-in-out;
  -webkit-animation: dropdown-season all 0.2s ease-in-out;
  animation: dropdown-season all 0.2s ease-in-out;
}

.dropdown-wrapper #megaDropDown-seasons {
  background: #efefef;
  display: inline-block;
  margin: auto;
  outline: medium none;
  text-align: right;
  text-decoration: none;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px 50px 0px 10px !important;
  background-image: none !important;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.dropdown-wrapper #megaDropDown-seasons .season {
  color: #999;
  display: block;
  font: normal 10px/1em "Roboto", sans-serif;
  margin: auto;
  width: auto;
  margin-bottom: 5px;
}

.dropdown-wrapper #megaDropDown-seasons .subseason {
  color: #666;
  font: bold 16px/1em "Orbitron", sans-serif;
  font-weight: 500;
}

.dropdown-wrapper #megaDropDown-seasons:after {
  content: "\f078";
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border-radius: 100px;
  border: 1px solid #666;
  color: #666;
  font: normal 12px/1em "FontAwesome";
  padding: 4px;
}

.dropdown-wrapper #megaDropDown-seasons-callout {
  background: #fff;
  padding: 10px;
  text-align: left;
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 230px;
  z-index: 2;
  padding: 15px 15px 20px 15px;
  display: none;
  border: 2px solid #eee;
}

.dropdown-wrapper #megaDropDown-seasons-callout .season-title {
  display: block;
  font: bold 12px "Roboto", sans-serif;
  padding: 0 0 5px;
  margin: 5px 0;
}

.dropdown-wrapper #megaDropDown-seasons-callout .season-title span {
  float: left;
}

.dropdown-wrapper #megaDropDown-seasons-callout select {
  width: 100%;
  border: 0px;
  border: 2px solid #eee;
  background: #fff;
  color: #000;
  background-image: url("http://app-assets2.sportngin.com/app_images/select-down-arrow.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 27px;
  padding: 7px;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: .8rem;
}

.dropdown-wrapper #megaDropDown-seasons-callout a {
  display: block;
  font-size: 12px;
  padding: 2px 5px;
  text-decoration: none;
  background-color: #efefef;
  text-align: center;
  color: #666;
  border: 1px solid #fff;
}

.dropdown-wrapper #megaDropDown-seasons-callout a:hover {
  background: #efefef;
  border: 1px solid #e6e6e6;
}

.dropdown-wrapper #megaDropDown-seasons-callout a.close {
  background: #fff;
  border: 1px solid #777;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  color: #777;
  display: block;
  float: right;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding: 0px 7px 2px 7px;
  font-family: "Roboto", sans-serif;
  border: none;
}

.dropdown-wrapper #megaDropDown-seasons-callout a.current {
  background: #efefef none repeat scroll 0 0;
  border-radius: 0;
  color: #333;
  font-size: 11px;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
}

.dropdown-wrapper #megaDropDown-seasons-callout .footer {
  margin: 10px 0 0;
}

.dropdown-wrapper #megaDropDown-seasons-callout optgroup option {
  margin: 0;
  padding: 0 0 0 10px;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active {
  cursor: pointer;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active:after {
  display: block;
}

.dropdown-wrapper #megaDropDown-seasons.mdd-active:hover {
  background-color: #efefef;
}

.teamPage #rapidTabs.rapidTabsConstruction {
  background: none;
}

.yieldPageContent {
  clear: both;
  padding: 0;
}

.activePlayers h4 {
  font-size: 1rem;
  color: #111111;
  font-weight: 600;
}

#sport-slider.sport-tools {
  background: transparent;
  border: none;
  border-radius: 0;
}

#sport-slider.sport-tools #scroll-container {
  height: auto;
  overflow-x: scroll;
  position: relative;
  min-height: 75px;
}

#sport-slider.sport-tools #track {
  display: none;
}

#sport-slider.sport-tools li {
  border: none;
  border-radius: 0;
}

#sport-slider.sport-tools li a {
  background: #c2c6ca;
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
  border-radius: 0;
  font-size: 1rem;
  text-shadow: none;
  transition: all 0.2s ease-in-out;
  border: none;
}

#sport-slider.sport-tools li a:hover {
  background: #ffc423;
}

#sport-slider.sport-tools .players li a {
  padding: 15px;
}

.sport-tools {
  margin-left: 0;
}

.sport-tools .pill-buttons li {
  border: none;
  border-radius: 0;
}

.sport-tools .pill-buttons li a {
  font-family: "Orbitron", sans-serif;
  border: none;
  background: transparent;
  padding: 7px 10px;
  font-size: 1rem;
  border-radius: 0;
  color: #111111;
  transition: all 0.2s ease-in-out;
}

.sport-tools .pill-buttons li a:hover {
  color: #8c9192;
}

.sport-tools .pill-buttons li.selected a {
  background: transparent;
  border-bottom: solid 4px #111111;
}

.highlight {
  background: white !important;
}

th.highlight {
  background: #8c9192 !important;
}

#PlayerStatsDiv .sport-tools {
  background: transparent;
}

.pageElement.paginationElement {
  background: transparent;
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
  padding: 15px 10px;
}

.pageElement.paginationElement h4 {
  font-size: 1.25rem;
}

.pageElement.paginationElement .results {
  color: #2d2d2d;
  font-size: .75rem;
}

.pageElement.paginationElement .paginationNav a {
  font-size: 1rem;
  color: #2d2d2d;
  transition: all 0.2s ease-in-out;
}

.pageElement.paginationElement .paginationNav a:hover {
  color: #c2c6ca;
}

.pageElement.paginationElement .paginationNav em {
  font-size: 1rem;
  color: #0b9444;
}

#pageContentContainer h3.stat-category-header {
  border-top: none;
  background: transparent;
  border-radius: 0;
  line-height: normal;
  height: auto;
  padding: 5px 0;
  text-indent: 0;
  color: #111111;
  border-bottom: solid 3px #0b9444;
}

.pageElement.rosterPlayerInfoElement .defaultPlayerInfo h3 {
  background: #111111 !important;
  border-top: none !important;
  border-bottom: solid 1px #0b9444 !important;
  padding: 5px 0;
}

.pageElement.rosterPlayerInfoElement .defaultPlayerInfo h3 .playerName-bg {
  background: transparent;
}

.pageElement.rosterPlayerInfoElement .RosterPlayerInfoImage div {
  background: transparent;
}

#PreviewStatsDiv table tr.last {
  background: #c2c6ca;
}

#PreviewStatsDiv table tr.last td {
  color: #2d2d2d !important;
}

#PreviewStatsDiv .next_game {
  background: #c2c6ca;
  border-radius: 0;
  border-bottom: none;
}

#PreviewStatsDiv .next_game .info {
  width: 35%;
}

#PreviewStatsDiv .next_game .info .header {
  color: #0b9444;
}

#PreviewStatsDiv .next_game .info .time {
  color: #2d2d2d;
}

#PreviewStatsDiv .next_game .info .vs_or_at {
  color: #111111;
}

#PreviewStatsDiv .next_game .info a {
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
}

#PreviewStatsDiv .next_game .info a:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

#PreviewStatsDiv .team.away_team, #PreviewStatsDiv .team.home_team {
  background: transparent;
  box-shadow: none;
}

#PreviewStatsDiv .team.away_team .team_logo a, #PreviewStatsDiv .team.home_team .team_logo a {
  border: none !important;
  border-radius: 0;
  display: none;
}

#PreviewStatsDiv .team_name {
  color: #111111;
  font-size: 3vw;
  line-height: normal;
  height: auto;
  white-space: normal;
  font-weight: 600;
}

#preview-sm-soccer_goalkeeper .icon.goToLink {
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
  margin: 5px 0;
  display: inline-block;
  padding: 9px 15px 5px;
}

#preview-sm-soccer_goalkeeper .icon.goToLink:after {
  display: inline-block;
  content: '\f105';
  position: relative;
  font-family: "FontAwesome";
  margin-left: 10px;
}

#preview-sm-soccer_goalkeeper .icon.goToLink:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

#GameShowContent .awayTeam span.team_logo,
#GameShowContent .homeTeam span.team_logo {
  display: none;
}

.gameShow #pageContentContainer {
  padding: 15px;
}

.gameShow #GameShowContent ul.team_stats-list-header {
  box-shadow: none;
  border-radius: 0;
  background: #c2c6ca;
  border-bottom: none;
}

.gameShow #GameShowContent ul.team_stats-list-header li {
  line-height: normal;
  height: auto;
}

.gameShow #GameShowContent ul.team_stats-list-header li h3 {
  font-size: 1.25rem;
  text-shadow: none;
  font-family: "Orbitron", sans-serif;
  padding: 5px;
  color: #2d2d2d;
  font-weight: 600;
}

.gameShow #GameShowContent ul.team_stats-list-header li.team_logo span a {
  border: none;
  border-radius: 0;
}

.gameShow #GameShowContent ul.team_stats-list-header.awayTeam span.team_logo, .gameShow #GameShowContent ul.team_stats-list-header.homeTeam span.team_logo {
  display: none;
}

.gameShow #GameShowContent .table_container {
  box-shadow: none;
  border-radius: none;
  padding: 0;
  border: none;
}

.gameShow #GameShowContent .table_container table.player_stats-list th {
  background: #c2c6ca;
  border-right: none;
  padding: 10px;
  font-family: "Orbitron", sans-serif;
  color: #2d2d2d;
  font-size: 1rem;
}

.gameShow #GameShowContent .table_container table.player_stats-list td {
  border-right: none;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  color: #2d2d2d;
  font-size: 1rem;
}

.gameShow #GameShowContent .table_container table.player_stats-list tr.totals td {
  color: white;
}

.gameShow #GameShowContent .GameShowDataContainer.game_stats ul.team_stats-list li ul li {
  height: 40px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #2d2d2d;
}

.gameShow #GameShowContent .GameShowDataContainer .StatWidgetGroup {
  background: transparent;
  margin-right: 0;
}

.gameShow #GameShowContent .GameShowDataContainer .StatWidgetGroup .StatWidgetContainer-sub,
.gameShow #GameShowContent .GameShowDataContainer .StatWidgetGroup .StatWidget {
  margin-right: 0;
}

.gameShow #GameShowContent .GameShowDataContainer .StatWidgetGroup .StatWidget .player_stats-list-header {
  background: transparent !important;
}

.gameShow #GameShowContent .GameShowDataContainer .StatWidgetGroup .StatWidget .player_stats-list-header li {
  height: auto;
  line-height: normal;
  padding: 0;
  color: #2d2d2d;
  text-shadow: none !important;
  width: 100%;
  text-align: center;
}

.gameShow #GameShowContent .GameShowDataContainer .StatWidgetGroup .StatWidget .player_stats-list-header li h3 {
  text-shadow: none;
  font-size: 1.25rem;
  padding: 10px;
}

.gameShow #GameShowContent .GameShowDataContainer .StatWidgetGroup .StatWidgetContainer {
  padding: 0;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore h3 {
  color: #111111;
  font-family: "Orbitron", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 5px;
  border-bottom: none;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore h3 .team_logo {
  display: none;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary {
  font-family: "Roboto", sans-serif;
  color: #2d2d2d;
  font-size: .8rem;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li {
  padding: 10px;
  border-right: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li.interval_row {
  background: #c2c6ca;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li.interval_row ul li {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: .05rem;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li.interval_row ul li.teamScore {
  width: auto;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li.scoring_info.even, .gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li.scoring_info.odd {
  background: white;
  border-bottom: solid 1px #c2c6ca;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li ul {
  padding: 0;
  background: transparent;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li ul li {
  font-size: 16px;
  font-weight: 500;
}

.gameShow #GameShowContent .GameShowDataContainer.game_boxscore ul.scoring_summary > li ul li.game_clock {
  min-width: 4rem;
}

.gameShow #GameShowContent table.player_stats-list.stat_table {
  font-family: "Orbitron", sans-serif;
  color: #2d2d2d;
  background: transparent;
  padding: 30px;
}

.gameShow #GameShowContent table.player_stats-list.stat_table tr {
  background: white;
  border-right: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
}

.gameShow #GameShowContent table.player_stats-list.stat_table th {
  padding: 10px;
  background: #c2c6ca;
  font-size: 12px;
  border-right: none;
}

.gameShow #GameShowContent table.player_stats-list.stat_table .totals th {
  background: white;
  color: #2d2d2d;
}

.gameShow #GameShowContent table.player_stats-list.stat_table td {
  border-right: none;
  padding: 10px;
  border: none;
  border-bottom: solid 1px #c2c6ca;
  background: transparent;
  font-size: .8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.gameShow #GameShowContent table.player_stats-list.stat_table td.even, .gameShow #GameShowContent table.player_stats-list.stat_table td.odd {
  background: transparent;
}

.gameShow #GameShowContent table.player_stats-list.stat_table td a {
  color: #2d2d2d;
  line-height: normal;
}

#GameShowScoreboardSimple {
  background: transparent;
}

#GameShowScoreboardSimple .game_info_bar_v2 {
  background: white;
  border-left: solid 1px #c2c6ca;
}

#GameShowScoreboardSimple .game_info_bar_v2 .status {
  background: #0b9444;
}

#GameShowScoreboardSimple .game_info_bar_v2 li {
  line-height: 38px;
}

#GameShowScoreboardSimple .game_info_bar_v2 .date {
  color: #111111;
}

#GameShowScoreboardSimple .game_header_v2 {
  display: none;
}

#GameShowScoreboardSimple .game_header_v2 .team_logo .teamLogo-medium {
  border: none;
  border-radius: 0;
  background-size: auto 100%;
  width: 40px !important;
}

#GameShowScoreboardSimple .game_header_v2 h3 {
  color: #2d2d2d;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

#GameShowScoreboardSimple .game_header_v2 h3 span {
  color: #111111;
}

#GameShowScoreboardSimple .game_header_v2 h3 span:before {
  content: '-';
  display: inline-block;
  margin: 0 7px 0 5px;
  color: #2d2d2d;
}

#GameShowScoreboardSimple .player_stats-list {
  border: none;
}

#GameShowScoreboardSimple .player_stats-list th {
  background: #c2c6ca;
  color: #2d2d2d;
  font-size: 1rem;
  font-family: "Orbitron", sans-serif;
  padding: 5px 15px;
  border-right: none !important;
}

#GameShowScoreboardSimple .player_stats-list tr {
  border-right: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
}

#GameShowScoreboardSimple .player_stats-list td {
  font-family: "Roboto", sans-serif;
  color: #2d2d2d;
  font-weight: 600;
  padding: 10px;
  border-right: none !important;
  font-size: .9rem;
  background: white;
  border-right: solid 1px #c2c6ca;
}

#GameShowScoreboardSimple .box-score-border {
  border-top: none;
  border-bottom: solid 1px #c2c6ca;
}

#GameShowContent.game_show_nextgen ul.tab_menu-list {
  box-shadow: none;
  border-radius: 0;
}

#GameShowContent.game_show_nextgen .rapidContentGeneral,
#GameShowContent.game_show_nextgen #contentTabsLoader {
  box-shadow: none;
  border-radius: 0;
  border: none;
  background: transparent;
}

#GameShowContent .tab_menu #rapidTabs li a {
  background: transparent;
  color: #111111;
  font-family: "Roboto", sans-serif;
  font-size: .8rem;
  padding: 5px 0;
  margin-right: 15px;
  letter-spacing: .025rem;
  transition: all 0.2s ease-in-out;
  border-radius: 0;
  box-shadow: none;
  font-weight: 500;
}

#GameShowContent .tab_menu #rapidTabs li.selected a {
  border: none;
  border-bottom: solid 4px #111111;
  box-shadow: none;
  margin-top: 0;
}

#GameShowContent .tab_menu #rapidTabs li:hover a {
  color: #8c9192;
}

#GameShowContent .StatWidgetGroupContainer.game_preview-header .StatWidgetGroup {
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  border: none;
}

#GameShowContent .game_preview-header .awayTeam,
#GameShowContent .game_preview-header .homeTeam {
  background: #111111 !important;
}

#GameShowContent .awayTeam,
#GameShowContent .homeTeam {
  background: #2d2d2d !important;
  border: none;
  border-radius: 0;
  font-family: "Orbitron", sans-serif;
}

#GameShowContent .awayTeam span.team_logo,
#GameShowContent .homeTeam span.team_logo {
  text-align: center;
}

#GameShowContent .awayTeam span.team_logo a.teamLogo-medium,
#GameShowContent .homeTeam span.team_logo a.teamLogo-medium {
  width: 100% !important;
  height: 100px !important;
  border: none;
  border-radius: 0;
  background-size: auto 100%;
}

#GameShowContent .awayTeam h2,
#GameShowContent .homeTeam h2 {
  font-family: "Orbitron", sans-serif;
  line-height: 45px;
  font-size: 2rem;
}

#GameShowContent .stat-bar_graph div.awayTeam, #GameShowContent .stat-bar_graph div.homeTeam {
  border-radius: 0;
  background: transparent !important;
}

#GameShowContent .player_stat-leader .player_statbox span.statbox_stat {
  background: transparent;
}

#GameShowContent ul.team_stats-list {
  background: transparent;
}

#GameShowContent ul.team_stats-list .noEntry {
  background: transparent;
}

#GameShowContent ul.team_stats-list li ul {
  border-bottom: solid 1px #c2c6ca;
  border-left: solid 1px #c2c6ca;
  border-right: solid 1px #c2c6ca;
  margin-bottom: 0;
  background: white;
}

#GameShowContent ul.team_stats-list li ul li {
  font-size: 4px;
  font-weight: 500;
}

#GameShowContent ul.team_stats-list li ul li.team_stat {
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
}

#GameShowContent ul.team_stats-list li ul li.stat-bar_graph .homeTeam span {
  border-left: none !important;
}

#GameShowContent .GameShowDataContainer .StatWidgetGroup {
  background: white;
  box-shadow: none;
  border-radius: 0;
  border: none;
}

#GameShowContent .GameShowDataContainer .StatWidgetGroup .player_stat-leader {
  box-shadow: none;
  margin: 0;
  background: #eeeeee;
  margin-bottom: 20px;
}

#GameShowContent .StatWidget.player_stat-leader-header {
  margin: 0;
  box-shadow: none;
  text-shadow: none;
}

#GameShowContent .StatWidget.player_stat-leader-header div {
  box-shadow: none;
  border-radius: 0;
  background: #c2c6ca;
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
  text-shadow: none;
  padding: 7px 0;
  letter-spacing: .05rem;
}

#GameShowContent .StatWidget.player_stat-leader-header div span {
  font-size: 1rem;
}

#GameShowContent .StatWidget.player_stat-leader .noEntry {
  background: transparent;
}

#gameGalleryHeader .button {
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
}

#gameGalleryHeader .button:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

#GameShowContent.game_show_nextgen ul.scoring_summary li.scoring_info ul li.team_name {
  width: auto;
}

#GameShowContent ul.game_details li {
  font-size: .9rem;
  margin-right: 15px;
  color: #2d2d2d;
}

#GameShowContent ul.game_details a {
  color: #0b9444;
  transition: all 0.2s ease-in-out;
  display: none;
}

#GameShowContent ul.game_details a:hover {
  color: #8c9192;
  text-decoration: none;
}

#GameShowContent .StatWidgetGroup.inset.game_details .background {
  background: transparent;
}

#GameShowContent .StatWidgetContainer.game_details.header {
  background: transparent;
  height: 40px;
  text-indent: 0;
  margin-bottom: 10px;
}

#GameShowContent .StatWidgetContainer.game_details.header h3 {
  color: #111111;
  font-size: 1.5rem;
  font-family: "Orbitron", sans-serif;
  border-bottom: 1px solid #c2c6ca;
}

.gameShow .matchCenterHeader {
  background: #0b9444;
  height: 80px;
  color: white;
  font-family: "Orbitron", sans-serif;
  font-weight: 500;
}

.gameShow .matchCenterHeader .teamPanel {
  width: 45%;
  float: left;
}

.gameShow .matchCenterHeader .teamLogos {
  height: 80px;
  position: relative;
  width: 80px !important;
}

.gameShow .matchCenterHeader .teamLogos a {
  border: none;
  border-radius: 0;
  background-size: auto 100%;
  height: 110px !important;
  position: absolute;
  z-index: 1;
  top: -10px;
}

.gameShow .matchCenterHeader .teamAway .awayName {
  float: right;
  text-transform: uppercase;
  line-height: 80px;
  font-size: 2rem;
  margin-right: 15px;
  letter-spacing: .1rem;
  font-weight: 600;
}

.gameShow .matchCenterHeader .teamAway .awayLogo {
  float: right;
}

.gameShow .matchCenterHeader .teamHome .homeName {
  float: left;
  text-transform: uppercase;
  line-height: 80px;
  font-size: 2rem;
  margin-left: 15px;
  letter-spacing: .1rem;
  font-weight: 600;
}

.gameShow .matchCenterHeader .teamHome .homeLogo {
  float: left;
}

.gameShow .matchCenterHeader .gameScore {
  width: 10%;
  float: left;
}

.gameShow .matchCenterHeader .gameScore .scored {
  float: left;
  width: 0;
  line-height: 80px;
  font-size: 3rem;
  letter-spacing: .1rem;
  font-weight: 600;
  opacity: 0;
}

.gameShow .matchCenterHeader .gameScore .scoreDash {
  width: 100%;
  line-height: 80px;
  float: left;
  text-align: center;
  font-size: 1.5rem;
}

.gameShow .matchCenterHeader .gameScore .awayScore {
  text-align: right;
}

.gameShow #GS_Video {
  margin: 10px 0;
}

.gameShow #GS_Video iframe:first-of-type {
  width: 75%;
  float: left;
}

.gameShow #GS_Video iframe:nth-of-type(2) {
  width: 25% !important;
}

input.button-medium {
  padding: 7px 15px 5px;
  background: #0b9444;
  color: white;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: .8rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  text-shadow: none;
  border-radius: 0;
  box-shadow: none;
}

input.button-medium:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

#tab_gamelist_content .pageElement {
  overflow-x: scroll;
}

#tab_gamelist_content .pageElement::-webkit-scrollbar {
  background: transparent;
}

.prototip {
  color: #2d2d2d;
  font-family: "Orbitron", sans-serif;
}

.prototip .borderMiddle.borderRow {
  background: transparent !important;
}

.prototip .borderBottom.borderRow,
.prototip .borderTop.borderRow {
  display: none;
}

.prototip .score-away:after {
  content: 'VS';
  display: inline-block;
  position: absolute;
  color: #111111;
  font-family: "Orbitron", sans-serif;
  top: calc(50% - 16px);
  right: -10px;
}

.prototip .score-away,
.prototip .score-home {
  width: 159px;
  background: transparent;
  padding: 15px;
  position: relative;
}

.prototip .sport-colors {
  display: none;
}

.prototip .ngin .tooltip-outer {
  background: #c2c6ca;
  border: none;
}

.prototip .ngin .tooltip-outer h3 {
  font-family: "Orbitron", sans-serif;
  background: #8c9192;
  color: #2d2d2d;
  padding: 10px 15px;
  border-bottom: none;
  text-align: center;
}

#GS_Video {
  padding: 0;
}

@media only screen and (max-width: 1024px) {
  #sport-header {
    padding-right: 15px;
  }
  #sport-header .sport-tools ul li a {
    font-size: .9em;
  }
}

@media only screen and (max-width: 768px) {
  .dropdown-wrapper {
    margin-right: 15px;
  }
  .GameShowDataContainer.game_boxscore .StatWidgetColumn.width50 {
    width: 100%;
  }
  .sportPages .layoutContainer {
    padding-left: 0;
  }
  .sportPages .layoutContainer .pageElement {
    margin-right: 0;
  }
  #sport-header #sport-crumbs ul li.crumbSelected {
    position: static;
  }
  #sport-header #sport-crumbs .sport-crumbs-list {
    max-width: 100%;
  }
  #sport-header #sport-crumbs a span {
    font-size: 0.70em;
  }
  #sport-header #sport-crumbs a span:after {
    font: 10px/1em "FontAwesome";
  }
  #sport-header #sport-crumbs a .arrow-down {
    margin-left: 0px;
  }
  #sport-header .sport-tools ul li a {
    padding: 10px 2px;
    font-size: 0em;
  }
  #sport-header .sport-tools ul li a:before {
    display: block;
  }
  /* Seasons Mega Drop Down */
  .dropdown-wrapper {
    max-width: 100%;
    width: 100%;
  }
  .dropdown-wrapper:before {
    display: none;
  }
  .dropdown-wrapper #megaDropDown-seasons .season {
    font-size: 10px;
  }
  .dropdown-wrapper #megaDropDown-seasons .subseason {
    font-size: 9px;
  }
  .dropdown-wrapper #megaDropDown-seasons-callout {
    max-width: 100%;
  }
  .sport-colors .teamLogo-large {
    height: 45px !important;
    width: 45px !important;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
  }
  .gameShow #GS_Video iframe:first-of-type {
    width: 100%;
    float: none;
  }
  .gameShow #GS_Video iframe:nth-of-type(2) {
    width: 100% !important;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .sport-colors h2 {
    font-size: 1.5rem;
    line-height: normal;
  }
  .sport-colors .teamLogo-large {
    margin: 10px;
  }
  .yieldPageContent .sport-tools {
    margin: 0;
  }
  .yieldPageContent .sport-tools ul {
    float: none;
  }
  .yieldPageContent .sport-tools ul.games {
    float: left;
  }
  .yieldPageContent .sport-tools .pill-buttons li {
    width: 100%;
    margin-bottom: 5px;
  }
  .yieldPageContent .sport-tools .pill-buttons li a {
    text-align: center;
  }
  .sportsPage #pageContentContainer {
    padding-top: 0 !important;
  }
  #GameShowContent li.tab_menu-tab {
    width: 50%;
  }
  #GameShowContent .tab_menu #rapidTabs li {
    margin-bottom: 1px;
  }
  #GameShowContent .tab_menu #rapidTabs li a {
    color: white;
    background: #111111;
    padding: 10px 5px;
    text-align: center;
    margin-right: 1px;
  }
  #GameShowContent .tab_menu #rapidTabs li.selected a {
    background: #0b9444;
    border-bottom: none;
    color: white;
  }
  .gameShow #GS_Video iframe:first-of-type {
    width: 100%;
    float: none;
    height: 300px;
  }
  .gameShow #GS_Video iframe:nth-of-type(2) {
    width: 100% !important;
    margin-top: 10px;
  }
  .gameShow .matchCenterHeader {
    height: 50px;
  }
  .gameShow .matchCenterHeader .teamLogos {
    height: 50px;
  }
  .gameShow .matchCenterHeader .teamLogos a {
    height: 70px !important;
  }
  .gameShow .matchCenterHeader .teamAway .awayName,
  .gameShow .matchCenterHeader .teamHome .homeName {
    height: 50px;
    font-size: 1.25rem;
    margin: 0;
    line-height: 50px;
  }
  .gameShow .matchCenterHeader .gameScore .scored {
    height: 50px;
    font-size: 1.75rem;
    line-height: 50px;
  }
  .gameShow .matchCenterHeader .gameScore .scoreDash {
    line-height: 50px;
    font-size: 1rem;
  }
}

#home #siteFooter > ul {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1400px;
}

#siteFooter {
  background: #111111;
  max-width: 100%;
  padding: 0 0 15px;
  width: 100%;
}

#siteFooter > ul {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1200px;
  text-align: left;
  background: transparent;
  border-top: none;
}

#siteFooter > ul li a {
  transition: all 0.2s ease-in-out;
}

#siteFooter > ul li a:hover {
  color: #ffc423;
}

#siteFooter > ul li #poweredByNGIN {
  float: none;
  width: 100%;
}

#siteFooter #footerUpdates {
  background: #111111;
}

#siteFooter #footerUpdates .updates {
  background: #222222;
  position: relative;
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1200px;
  padding: 30px 15px;
}

#siteFooter #footerUpdates .updates .layoutInner > .column2 {
  width: calc(66.66666666% - 30px) !important;
  margin-left: 30px;
}

#siteFooter #footerUpdates .updates:before {
  right: 100%;
}

#siteFooter #footerUpdates .updates:after {
  left: 100%;
}

#siteFooter #footerUpdates .updates .pageElement.textBlockElement h3 {
  color: white;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement {
  margin-right: 0;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container {
  background: white;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore thead {
  display: none;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore .colgroup-edge {
  background: transparent !important;
  color: #2d2d2d !important;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore .team {
  padding-left: 0;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore .team a {
  color: #2d2d2d !important;
  font-weight: 600 !important;
  font-size: .9rem;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore .team .teamLogo-small {
  display: block;
  border: none;
  border-radius: 0;
  background-size: auto 100%;
  height: 30px !important;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore-inner-container .boxscore-extras {
  background: #eeeeee;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore-inner-container .boxscore-extras a span {
  color: #2d2d2d;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore-inner-container .boxscore-extras .details a:before {
  color: #0b9444;
}

#siteFooter #footerUpdates .updates .pageElement.widgetElement .boxscore-container .boxscore-inner-container .boxscore-extras .boxscore-game-location {
  display: none;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li span a {
  color: white;
  font-weight: 600;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement ul.contentTabs.layoutContainerTabs li.selected span a {
  border-bottom: solid 4px white;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement {
  background: transparent;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement .newsItemElement .newsDate {
  color: white;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement .newsItemElement .newsAuthor {
  display: none;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement .newsItemElement h4 {
  color: white;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement.newsAggregatorElement .item {
  background: transparent;
  border: none;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement.newsAggregatorElement .item .newsItemElement .newsItemHeader h2 a {
  font-size: 1rem;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement.newsAggregatorElement .item .newsItemElement .newsItemHeader a img.wide {
  position: relative;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement.newsAggregatorElement .item h4 a {
  color: white;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement.newsAggregatorElement .item > a {
  background: #111111;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement.newsAggregatorElement .item .details .dateAuthor {
  color: #f1f1f1;
  font-size: 12px;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement .item {
  border-bottom: none;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement .item.extendedOptions {
  border-top: solid 2px #8c9192;
  text-align: center !important;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement .item.extendedOptions a.icon {
  padding: 15px 30px;
  background: #0b9444;
  color: white;
  font-family: "Orbitron", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.25rem;
  border-bottom: solid 4px #096831;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

#siteFooter #footerUpdates .updates .pageElement.tabbedElement .tabContainer .pageElement.aggregatorElement .item.extendedOptions a.icon:hover {
  background: #096831;
  border-bottom: solid 4px #0b9444;
}

#siteFooter #footerUpdates .updates .boxscore-container {
  position: relative;
}

#siteFooter #megaFooter {
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 1200px;
  padding: 30px 15px;
  display: block;
}

#siteFooter #megaFooter .left {
  width: 70%;
  display: inline-block;
}

#siteFooter #megaFooter .left .footerLeft {
  padding-left: 0;
}

#siteFooter #megaFooter .left .pageElement.textBlockElement h3 {
  color: #0b9444;
  padding: 0;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  border-bottom: none;
}

#siteFooter #megaFooter .left .pageElement.textBlockElement h3 span {
  font-size: .7rem;
  font-weight: 600;
  font-weight: 600;
}

#siteFooter #megaFooter .left .pageElement.textBlockElement p {
  line-height: 1.5rem;
}

#siteFooter #megaFooter .left .pageElement.textBlockElement p a {
  color: white;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-size: .8rem;
  letter-spacing: .05rem;
}

#siteFooter #megaFooter .left .pageElement.textBlockElement p a:hover {
  color: #c2c6ca;
}

#siteFooter #megaFooter .right {
  width: 30%;
  display: inline-block;
  float: right;
  padding-top: 1%;
}

#siteFooter #megaFooter .right .layoutContainer {
  padding-left: 0;
}

#siteFooter #megaFooter .right .pageElement.heroPhotoElement {
  margin-right: 0;
  text-align: center;
  margin-bottom: 5px;
}

#siteFooter #megaFooter .right .pageElement.heroPhotoElement img {
  max-width: 45%;
  display: inline-block;
}

#siteFooter #megaFooter .right .pageElement.textBlockElement h3 {
  color: white;
  margin-top: -20px;
}

#siteFooter #megaFooter .right .beSocial {
  background: transparent;
}

#siteFooter #megaFooter .right .beSocial p {
  text-align: center;
  margin-bottom: 7px;
}

#siteFooter #megaFooter .right .beSocial p a {
  float: none;
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  #siteFooter .layoutContainer {
    padding-left: 0;
  }
  #siteFooter #footerUpdates {
    padding: 30px 15px;
  }
  #siteFooter #megaFooter {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 768px) {
  #siteFooter > ul li#poweredByNGIN {
    float: none;
    width: 100%;
    margin-top: 10px;
  }
  #siteFooter #megaFooter .left {
    display: none;
  }
  #siteFooter #megaFooter .right {
    width: 100%;
  }
  #siteFooter #megaFooter .right .heroPhotoElement img {
    width: 50%;
    margin: auto;
  }
  #siteFooter > ul {
    text-align: center;
    border-top: none;
  }
  #siteFooter #footerUpdates {
    background: #111111;
    display: none;
  }
  #siteFooter #footerUpdates .updates {
    padding: 15px;
  }
  #siteFooter #footerUpdates .updates .layoutInner > .column1,
  #siteFooter #footerUpdates .updates .layoutInner > .column2 {
    width: 100% !important;
    margin-left: 0;
  }
}

.bannerWrap {
  display: none;
}

.bannerWrap .bannerTitle {
  float: left;
  color: white;
  font-family: "Orbitron", sans-serif;
  line-height: 50px;
  width: 70%;
  text-align: center;
  display: none;
}

.bannerWrap .bannerLogo {
  width: 15%;
  display: block;
  height: 50px;
  float: left;
  background-image: url("../images/logo_new.png");
  background-size: auto 70%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: calc(100% - 100px);
  margin: 0 50px;
}

#menuBtn {
  padding: 10px;
  cursor: pointer;
  display: none;
  height: 50px;
  position: relative;
  display: none;
  width: 50px;
  float: left;
}

#menuBtn span {
  display: block;
  height: 30px;
  width: 30px;
  z-index: 3;
  padding-top: 11px;
  margin: auto;
  padding-left: 2px;
}

#menuBtn span i, #menuBtn span i:after, #menuBtn span i:before {
  background-color: #fff;
  border-radius: 1px;
  content: '';
  display: block;
  height: 3px;
  max-width: 25px;
  position: absolute;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

#menuBtn span i:before {
  top: -7px;
}

#menuBtn span i:after {
  bottom: -7px;
}

#playerMenu {
  height: 50px;
  width: 60px;
  float: right;
  position: relative;
  margin-right: 10px;
  display: none;
}

#playerMenu:after {
  content: 'USL Network';
  color: white;
  width: 60px;
  height: 50px;
  display: inline-block;
  bottom: 5px;
  right: 0;
  text-align: center;
  font-size: 10px;
  position: absolute;
  line-height: 60px;
}

.mobile #navLinksContainer {
  position: relative;
  max-width: 100% !important;
  border-radius: 0;
  opacity: 0;
  z-index: -999;
  left: 0 !important;
  top: 2px !important;
}

.mobile #navLinksStatus,
.mobile #navLinksShowAll,
.mobile #navLinksForm {
  background: #111111 !important;
}

.mobile #navLinksForm input {
  font-family: "Orbitron", sans-serif;
}

.mobile #navLinksResults li a {
  border-radius: 0;
  border-bottom: 1px solid #8c9192 !important;
}

.mobile #navLinksKill {
  display: none;
}

.mobile #navLinksLink span {
  background: transparent;
  position: relative;
}

.mobile #navLinksLink span span span {
  background: transparent;
  padding-right: 0;
}

.mobile #navLinksLink span span span:before {
  content: '\f002';
  display: inline-block;
  font-family: "FontAwesome";
  color: #0b9444;
  margin-left: 5px;
  font-size: 12px;
  position: absolute;
  left: -25px;
  transform: rotate(90deg);
}

.desktop.scrolling #topNav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.desktop.scrolling #topNav table tr td {
  padding: 8px 0;
}

.desktop.scrolling #topNav table tr td.selected > a, .desktop.scrolling #topNav table tr td:hover > a {
  color: white;
}

.desktop.scrolling #topNav table tr td.selected > a:after, .desktop.scrolling #topNav table tr td:hover > a:after {
  display: none;
}

.desktop.scrolling #topNav table tr td#homeLink {
  padding: 2px 5px 0 0;
  margin-right: 1%;
}

.desktop.scrolling #topNav table tr td#homeLink a {
  height: 50px;
  width: 70px;
}

.desktop.scrolling #topNav .beSocial {
  padding: 12px 0;
  height: 50px;
}

#mobileNav {
  width: 100%;
  display: none;
  position: relative;
  z-index: 9999;
  border-bottom: solid 4px #c2c6ca;
}

#mobileNav #topNav {
  display: none;
  float: left;
  width: 100%;
  background: #eeeeee;
}

#mobileNav #topNav .beSocial {
  float: none;
  width: 100%;
  padding: 15px 0;
  height: 60px;
}

#mobileNav #topNav .beSocial p {
  text-align: center;
}

#mobileNav #topNav .beSocial p a {
  float: none;
  display: inline-block;
}

#mobileNav #topNav .beSocial p a[href*='facebook'] {
  background-image: url("../images/connect/facebook.svg");
}

#mobileNav #topNav .beSocial p a[href*='twitter'] {
  background-image: url("../images/connect/twitter.svg");
}

#mobileNav #topNav .beSocial p a[href*='youtube'] {
  background-image: url("../images/connect/youtube.svg");
}

#mobileNav #topNav .beSocial p a[href*='instagram'] {
  background-image: url("../images/connect/instagram.svg");
}

#mobileNav #topNav .beSocial p a[href*='vimeo'] {
  background-image: url("../images/connect/vimeo.svg");
}

#mobileNav #topNav .beSocial p a[href*='vine'] {
  background-image: url("../images/connect/vine.svg");
}

#mobileNav #topNav .beSocial p a[href*='google'] {
  background-image: url("../images/connect/google.svg");
}

#mobileNav #topNav .dropdown-disclosure {
  background: none;
}

#mobileNav #topNav table {
  width: 100%;
}

#mobileNav #topNav table tbody tr {
  width: 100%;
}

#mobileNav #topNav table tbody tr td {
  display: block;
  width: 100%;
  padding: 0;
  margin-right: 0;
  border-bottom: solid 1px #c2c6ca;
}

#mobileNav #topNav table tbody tr td:hover a, #mobileNav #topNav table tbody tr td.hover a {
  background: transparent;
}

#mobileNav #topNav table tbody tr td:hover > a:after, #mobileNav #topNav table tbody tr td.hover > a:after {
  display: none;
}

#mobileNav #topNav table tbody tr td.selected > a {
  background: transparent;
}

#mobileNav #topNav table tbody tr td.selected > a:after {
  display: none;
}

#mobileNav #topNav table tbody tr td#homeLink {
  display: none;
}

#mobileNav #topNav table tbody tr td:hover ul {
  display: none;
}

#mobileNav #topNav table tbody tr td.hover ul {
  display: none;
}

#mobileNav #topNav table tbody tr td.hover li.hover > a:before {
  color: #fff;
}

#mobileNav #topNav table tbody tr td > a:after {
  display: none;
}

#mobileNav #topNav table tbody tr td a {
  width: 100%;
  padding: 10px 5px;
  text-align: left;
  color: #111111;
}

#mobileNav #topNav table tbody tr td a.disabled {
  opacity: 0.6;
}

#mobileNav #topNav table tbody tr td a span {
  width: 92%;
  display: inline-block;
}

#mobileNav #topNav table tbody tr td a:before {
  display: inline-block;
  position: relative;
  width: 8%;
  content: '';
}

#mobileNav #topNav table tbody tr td a.nested span {
  width: 92%;
  display: inline-block;
}

#mobileNav #topNav table tbody tr td a.nested:before {
  display: inline-block;
  position: relative;
  top: 0px;
  width: 8%;
  content: '\f107';
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  color: #0b9444;
  font: 100 1.2em/1em "FontAwesome";
  font-weight: bold;
  text-align: center;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

#mobileNav #topNav table tbody tr td a.nested.listOpen:before {
  content: '\f106';
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  top: 3px;
  position: relative;
  opacity: 1;
}

#mobileNav #topNav table tbody tr td ul {
  width: 100%;
  position: relative;
  left: 0;
}

#mobileNav #topNav table tbody tr td ul li {
  border-right: 0;
}

#mobileNav #topNav table tbody tr td ul li:first-of-type {
  padding-top: 0;
}

#mobileNav #topNav table tbody tr td ul li:last-of-type {
  padding-bottom: 0;
}

#mobileNav #topNav table tbody tr td ul li > a.listOpen {
  background-color: #0b9444 !important;
  color: white !important;
}

#mobileNav #topNav table tbody tr td ul li > a.listOpen:before {
  color: white !important;
}

#mobileNav #topNav table tbody tr td ul li > a {
  padding: 10px;
  background-color: white !important;
  color: #0b9444;
  border-top: 1px solid #efefef;
}

#mobileNav #topNav table tbody tr td ul li > a:hover {
  background-color: #0b9444;
}

#mobileNav #topNav table tbody tr td ul li > a.nested:before {
  color: #0b9444;
  font-weight: bold;
}

#mobileNav #topNav table tbody tr td ul li ul {
  width: 100%;
  position: relative;
  left: 0;
  border-bottom: none;
}

#mobileNav #topNav table tbody tr td ul li ul li a {
  padding-left: 30px;
  background-color: #efefef;
  border-top: 1px solid #ccc;
  color: #2d2d2d !important;
}

body.desktop #pageContentContainer {
  opacity: 1 !important;
}

.menuOn.mobile #navLinksContainer {
  opacity: 1;
  z-index: 998;
}

.menuOn.desktop #pageContentContainer {
  opacity: 1;
}

.menuOn #pageContentContainer {
  opacity: .2;
}

.menuOn #menuBtn span i {
  background: #ffc423;
}

.menuOn #menuBtn span i:before, .menuOn #menuBtn span i:after {
  background: #ffc423;
}

.desktop #playerMenuContainer {
  display: none !important;
}

.desktop .mobileSearch {
  display: none !important;
}

.desktop #mobileNav {
  border-bottom: none;
}

.desktop #siteHeader #navLinksContainer {
  display: none;
}

#teamLogoContainer {
  display: none;
  position: relative;
  background: white;
  width: 100%;
  border-bottom: solid 4px #c2c6ca;
  padding: 15px 10%;
}

#teamLogoContainer .teamLogos a {
  width: 50%;
  display: inline-block;
  height: 100px;
  text-align: center;
  padding: 5%;
  border-bottom: solid 1px #eeeeee;
  border-right: solid 1px #eeeeee;
}

#teamLogoContainer .teamLogos a:first-of-type, #teamLogoContainer .teamLogos a:nth-of-type(2) {
  border-top: solid 1px #eeeeee;
}

#teamLogoContainer .teamLogos a:nth-of-type(odd) {
  border-left: solid 1px #eeeeee;
}

#teamLogoContainer .teamLogos a img {
  height: 100%;
}

.menuVisible #pageContentContainer {
  opacity: .2;
}

@media screen and (max-width: 1024px) {
  #menuBtn {
    display: inline-block;
  }
  #mobileNav #topNav {
    display: block;
    padding: 0 !important;
  }
  #mobileNav #topNav .beSocial {
    display: block;
  }
  #topNav {
    display: none;
  }
  #siteHeader {
    float: none;
    height: 50px;
    background: #111111;
  }
  .logged_in.scrolling #siteHeader {
    top: 0;
    background: #111111;
    height: 50px;
    display: block;
  }
  .scrolling #siteHeader {
    background: #111111;
    height: 50px;
  }
  .bannerWrap {
    display: block;
  }
  .bannerWrap .bannerSocial {
    display: none;
  }
  .scoreboardBanner #masterScoreboard .scoreboardRibbon .previous_button {
    margin-left: 0;
  }
  .scoreboardBanner #masterScoreboard .scoreboardRibbon .next_button {
    margin-right: 0;
  }
}

@media (min-width: 640px) and (max-width: 1024px) {
  #teamLogoContainer .teamLogos a {
    padding: 2% 5%;
  }
}

.fadeIn {
  animation: opacity .25s ease-in;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loadedVideos {
  width: 25%;
  height: 100%;
  overflow: scroll;
  white-space: nowrap;
  float: left;
  border: solid 1px #c2c6ca;
  border-left: none;
  overflow-y: scroll;
  position: relative;
  background: #eeeeee;
  background: -webkit-linear-gradient(white 50%, #eaeaea 200%);
  background: -o-linear-gradient(white 50%, #eaeaea 200%);
  background: -moz-linear-gradient(white 50%, #eaeaea 200%);
  background: linear-gradient(white 90%, #eaeaea 100%);
}

.loadedVideos ul {
  padding: 0px;
  margin: 0px;
  width: 100%;
  display: inline-block;
  list-style: none;
}

.loadedVideos ul li {
  background: transparent;
  border-bottom: 1px solid #c2c6ca;
  padding: 17px 10px;
  width: 100%;
  float: left;
  box-sizing: border-box;
  white-space: normal;
  transition: background .2s ease-in-out;
}

.loadedVideos ul li:last-child {
  border-bottom: none;
}

.loadedVideos ul li.active {
  background: #eeeeee;
}

.loadedVideos ul li.active h3 {
  color: #222222;
}

.loadedVideos ul li.active h3:after {
  background: linear-gradient(to right, rgba(220, 220, 220, 0), gainsboro 50%);
}

.loadedVideos ul li.active span:before {
  content: 'NOW';
  position: absolute;
  display: inline-block;
  height: 25px;
  width: 100%;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  text-align: center;
  line-height: 25px;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.loadedVideos ul li.active:hover span:after {
  display: none;
}

.loadedVideos ul li.active:hover span:before {
  background: rgba(11, 148, 68, 0.75);
}

.loadedVideos ul li:hover {
  background: #eeeeee;
  cursor: pointer;
}

.loadedVideos ul li:hover span:after {
  opacity: 1;
}

.loadedVideos img {
  width: 100%;
  margin-top: -8px;
}

.loadedVideos span {
  width: 75px;
  height: 40px;
  overflow: hidden;
  position: relative;
  float: left;
  display: none;
}

.loadedVideos span:after {
  content: '\f144';
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  font-family: "FontAwesome";
  text-align: center;
  line-height: 40px;
  font-size: 25px;
  color: white;
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  transition: opacity .2s ease-in-out;
}

.loadedVideos h3 {
  color: #333333;
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  text-transform: none;
  margin-left: 0;
  line-height: 1.4em;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.loadedVideos p {
  min-height: 126px;
  overflow: hidden;
  display: none;
}

/*playlistResponse*/
.playlistVideos li {
  list-style: none;
}

.playlistVideos h3 {
  color: #000;
  margin: 20px auto;
}

.featuredVideo {
  width: 75%;
  float: left;
  background: black;
}

.featuredVideo iframe {
  display: none;
}

.featuredVideo iframe:first-child {
  display: block;
}

.oneColumn .loadedVideos h3 {
  margin-left: 85px;
}

.oneColumn .loadedVideos span {
  display: block;
}

.twoColumn6633 .column-1 .mainVideo {
  height: 258px;
}

.twoColumn6633 .column-2 .mainVideo {
  height: 162px;
}

.twoColumn6633 .column-2 .loadedVideos h3 {
  margin-left: 85px;
}

.twoColumn6633 .column-2 .loadedVideos span {
  display: block;
}

.twoColumn6633 .column-2 .featuredVideo {
  width: 100%;
}

.twoColumn6633 .column-2 .loadedVideos {
  width: 100%;
  border-left: solid 1px #c2c6ca;
}

.twoColumn6633 .column-2 .videoPlaylist:after {
  width: calc(100% - 30px);
}

.twoColumn5050 .featuredVideo {
  width: 65%;
}

.twoColumn5050 .mainVideo {
  height: 166px;
}

.twoColumn5050 .loadedVideos {
  width: 35%;
}

.twoColumn3366 .column-1 .mainVideo {
  height: 162px;
}

.twoColumn3366 .column-1 .loadedVideos h3 {
  margin-left: 85px;
}

.twoColumn3366 .column-1 .loadedVideos span {
  display: block;
}

.twoColumn3366 .column-1 .featuredVideo {
  width: 100%;
}

.twoColumn3366 .column-1 .loadedVideos {
  width: 100%;
  border-left: solid 1px #c2c6ca;
}

.twoColumn3366 .column-1 .videoPlaylist:after {
  width: calc(100% - 30px);
}

.twoColumn3366 .column-2 .mainVideo {
  height: 258px;
}

.threeColumn333333 .mainVideo {
  height: 162px;
}

.threeColumn333333 .loadedVideos h3 {
  margin-left: 85px;
}

.threeColumn333333 .loadedVideos span {
  display: block;
}

.threeColumn333333 .featuredVideo {
  width: 100%;
}

.threeColumn333333 .loadedVideos {
  width: 100%;
  border-left: solid 1px #c2c6ca;
}

.threeColumn333333 .videoPlaylist:after {
  width: calc(100% - 30px);
}

.threeColumn502525 .column-1 .featuredVideo {
  width: 65%;
}

.threeColumn502525 .column-1 .mainVideo {
  height: 166px;
}

.threeColumn502525 .column-1 .loadedVideos {
  width: 35%;
}

.threeColumn502525 .column-2 .featuredVideo,
.threeColumn502525 .column-3 .featuredVideo {
  width: 100%;
}

.threeColumn502525 .column-2 .loadedVideos,
.threeColumn502525 .column-3 .loadedVideos {
  width: 100%;
  border-left: solid 1px #c2c6ca;
}

.threeColumn502525 .column-2 .mainVideo,
.threeColumn502525 .column-3 .mainVideo {
  height: 118px;
}

.threeColumn502525 .column-2 .videoPlaylist:after,
.threeColumn502525 .column-3 .videoPlaylist:after {
  width: calc(100% - 30px);
}

.threeColumn255025 .column-2 .featuredVideo {
  width: 65%;
}

.threeColumn255025 .column-2 .mainVideo {
  height: 166px;
}

.threeColumn255025 .column-2 .loadedVideos {
  width: 35%;
}

.threeColumn255025 .column-1 .featuredVideo,
.threeColumn255025 .column-3 .featuredVideo {
  width: 100%;
}

.threeColumn255025 .column-1 .loadedVideos,
.threeColumn255025 .column-3 .loadedVideos {
  width: 100%;
  border-left: solid 1px #c2c6ca;
}

.threeColumn255025 .column-1 .mainVideo,
.threeColumn255025 .column-3 .mainVideo {
  height: 118px;
}

.threeColumn255025 .column-1 .videoPlaylist:after,
.threeColumn255025 .column-3 .videoPlaylist:after {
  width: calc(100% - 30px);
}

.threeColumn252550 .column-3 .featuredVideo {
  width: 65%;
}

.threeColumn252550 .column-3 .mainVideo {
  height: 166px;
}

.threeColumn252550 .column-3 .loadedVideos {
  width: 35%;
}

.threeColumn252550 .column-1 .featuredVideo,
.threeColumn252550 .column-2 .featuredVideo {
  width: 100%;
}

.threeColumn252550 .column-1 .loadedVideos,
.threeColumn252550 .column-2 .loadedVideos {
  width: 100%;
  border-left: solid 1px #c2c6ca;
}

.threeColumn252550 .column-1 .mainVideo,
.threeColumn252550 .column-2 .mainVideo {
  height: 118px;
}

.threeColumn252550 .column-1 .videoPlaylist:after,
.threeColumn252550 .column-2 .videoPlaylist:after {
  width: calc(100% - 30px);
}

@media only screen and (max-width: 901px) {
  .oneColumn .loadedVideos {
    height: 492px !important;
  }
}

@media only screen and (max-width: 900px) {
  .oneColumn .featuredVideo {
    width: 65%;
  }
  .oneColumn .loadedVideos {
    width: 35%;
  }
  .oneColumn .mainVideo,
  .oneColumn .loadedVideos {
    height: 280px !important;
  }
  .twoColumn5050 .featuredVideo {
    width: 100%;
  }
  .twoColumn5050 .mainVideo,
  .twoColumn5050 .loadedVideos {
    height: 200px !important;
  }
  .twoColumn5050 .loadedVideos {
    width: 100%;
    border-left: solid 1px #c2c6ca;
  }
}

@media only screen and (max-width: 768px) {
  .oneColumn .featuredVideo,
  .twoColumn5050 .featuredVideo,
  .twoColumn6633 .featuredVideo,
  .twoColumn3366 .featuredVideo,
  .threeColumn333333 .featuredVideo,
  .threeColumn252550 .featuredVideo,
  .threeColumn255025 .featuredVideo,
  .threeColumn502525 .featuredVideo {
    width: 100%;
  }
  .oneColumn .mainVideo,
  .oneColumn .loadedVideos,
  .twoColumn5050 .mainVideo,
  .twoColumn5050 .loadedVideos,
  .twoColumn6633 .mainVideo,
  .twoColumn6633 .loadedVideos,
  .twoColumn3366 .mainVideo,
  .twoColumn3366 .loadedVideos,
  .threeColumn333333 .mainVideo,
  .threeColumn333333 .loadedVideos,
  .threeColumn252550 .mainVideo,
  .threeColumn252550 .loadedVideos,
  .threeColumn255025 .mainVideo,
  .threeColumn255025 .loadedVideos,
  .threeColumn502525 .mainVideo,
  .threeColumn502525 .loadedVideos {
    height: 390px !important;
  }
  .oneColumn .loadedVideos,
  .twoColumn5050 .loadedVideos,
  .twoColumn6633 .loadedVideos,
  .twoColumn3366 .loadedVideos,
  .threeColumn333333 .loadedVideos,
  .threeColumn252550 .loadedVideos,
  .threeColumn255025 .loadedVideos,
  .threeColumn502525 .loadedVideos {
    width: 100%;
    border-left: solid 1px #c2c6ca;
  }
  .oneColumn .loadedVideos span:after,
  .twoColumn5050 .loadedVideos span:after,
  .twoColumn6633 .loadedVideos span:after,
  .twoColumn3366 .loadedVideos span:after,
  .threeColumn333333 .loadedVideos span:after,
  .threeColumn252550 .loadedVideos span:after,
  .threeColumn255025 .loadedVideos span:after,
  .threeColumn502525 .loadedVideos span:after {
    display: none !important;
  }
  .oneColumn .loadedVideos span,
  .twoColumn5050 .loadedVideos span,
  .twoColumn6633 .loadedVideos span,
  .twoColumn3366 .loadedVideos span,
  .threeColumn333333 .loadedVideos span,
  .threeColumn252550 .loadedVideos span,
  .threeColumn255025 .loadedVideos span,
  .threeColumn502525 .loadedVideos span {
    display: inline-block;
  }
  .oneColumn .loadedVideos h3,
  .twoColumn5050 .loadedVideos h3,
  .twoColumn6633 .loadedVideos h3,
  .twoColumn3366 .loadedVideos h3,
  .threeColumn333333 .loadedVideos h3,
  .threeColumn252550 .loadedVideos h3,
  .threeColumn255025 .loadedVideos h3,
  .threeColumn502525 .loadedVideos h3 {
    margin-left: 85px;
  }
  .oneColumn .loadedVideos ul li.active:hover span:before,
  .twoColumn5050 .loadedVideos ul li.active:hover span:before,
  .twoColumn6633 .loadedVideos ul li.active:hover span:before,
  .twoColumn3366 .loadedVideos ul li.active:hover span:before,
  .threeColumn333333 .loadedVideos ul li.active:hover span:before,
  .threeColumn252550 .loadedVideos ul li.active:hover span:before,
  .threeColumn255025 .loadedVideos ul li.active:hover span:before,
  .threeColumn502525 .loadedVideos ul li.active:hover span:before {
    background: rgba(0, 0, 0, 0.75);
  }
}

@media only screen and (max-width: 640px) {
  .oneColumn .mainVideo,
  .oneColumn .loadedVideos,
  .twoColumn5050 .mainVideo,
  .twoColumn5050 .loadedVideos,
  .twoColumn6633 .mainVideo,
  .twoColumn6633 .loadedVideos,
  .twoColumn3366 .mainVideo,
  .twoColumn3366 .loadedVideos,
  .threeColumn333333 .mainVideo,
  .threeColumn333333 .loadedVideos,
  .threeColumn252550 .mainVideo,
  .threeColumn252550 .loadedVideos,
  .threeColumn255025 .mainVideo,
  .threeColumn255025 .loadedVideos,
  .threeColumn502525 .mainVideo,
  .threeColumn502525 .loadedVideos {
    height: 300px !important;
  }
}

@media only screen and (max-width: 480px) {
  .oneColumn .mainVideo,
  .oneColumn .loadedVideos,
  .twoColumn5050 .mainVideo,
  .twoColumn5050 .loadedVideos,
  .twoColumn6633 .mainVideo,
  .twoColumn6633 .loadedVideos,
  .twoColumn3366 .mainVideo,
  .twoColumn3366 .loadedVideos,
  .threeColumn333333 .mainVideo,
  .threeColumn333333 .loadedVideos,
  .threeColumn252550 .mainVideo,
  .threeColumn252550 .loadedVideos,
  .threeColumn255025 .mainVideo,
  .threeColumn255025 .loadedVideos,
  .threeColumn502525 .mainVideo,
  .threeColumn502525 .loadedVideos {
    height: 190px !important;
  }
}
