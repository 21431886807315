// *** Stat Widget Styles *** //

// Stat Widgets
.widgetHierarchy {
	background: transparent;

	li li li {
		background: $gray
	}

	.singleLevel {
		background: transparent;
		border: none;
		padding: 15px;

		h4 { 
			color: $coal; 

			a { color: $mainColor; }

		}

	}

	ul {

		li {
			background: transparent;
			border: none;
			overflow-x: scroll;

			&::-webkit-scrollbar{
	      background: transparent;
	    }

			.sportTableLabel {

				h4 { 
					color: $coal; 

					a, span { 
						color: $mainColor; 
						font-family: $fontTwo;
					}

				}

			}

			table.statTable {

				.teamLogo-small { display: none; }

				.playerTeam { 
					display: block; 
					color: #6a6a6a;
					font-size: .7rem;
				}

				.leaderName { float: none; }

			}	

		}

	}

	&.widget-roster-player {

		table { background: transparent; }

		.leader-highlight {
			background: transparent;

			.sport-colors { display: none; }

			.stats { 
				margin: 0; 

				.stats-minor {
					float: left;
				}

				.stats-major {
					background: transparent;
					padding: 0;
					margin-left: 5px !important;
					font-size: 13px;
					float: left;
				}

			}

		}

	}	

	.sportTableLabel .teamLogo-tiny { display: none; }

	&.widget-leader {

		li {
			background: transparent;
			border: none;

			.sportTableLabel { display: none; }

			h4 {
				font-size: 1.75rem;
				font-family: $fontOne;
				font-weight: $semibold;
				line-height: normal;

				a { color: $coal; }

			}

			.leader-highlight {
				background: transparent;
				text-align: left;

				.sport-colors {
					min-height: 0;
					height: 0;
					border-bottom: none;
					float: none;

					.color0-m,
					.color1-m,
					.color2-m,
					.edge { display: none; }

					.color0-m { display: none; }

					div.player_photo {
						position: relative;
						float: left;
						left: 0;
						border: solid 3px $coal;
						border-radius: 100px;
						height: 90px;
						width: 90px;

						a img { width: 100%; }

					}

				}

				.stats {
					margin: 0 0 10px 120px;

					.stats-major {
						background: transparent;
						color: #444444;
				    font-size: 4rem;
				    line-height: 3rem;
				    font-family: $fontOne;
				    padding-top: 15px;
					}

					.stats-minor a {
						color: #444444;
				    font-size: 2rem;
				    line-height: normal;
				    font-family: $fontOne;
				    font-weight: $regular;
					}


				}	

			}

			.NginTableWrapper {

				table.statTable {
					background: transparent;
					border: none;

					th {
						background: $gray;
						color: $coal;
						font-family: $fontOne;
						padding: 10px;
						font-size: 1rem;
						font-weight: $semibold;

						&:first-of-type { padding: 5px 10px; }
						
					}

					tr {
						border-bottom: solid 1px $gray;
						border-left: solid 1px $gray;
						border-right: solid 1px $gray;

						&.highlight,
						&.odd,
						&.even { background: white !important; }

						td { 
							padding: 5px 10px; 
							font-size: .9rem;
							font-family: $fontTwo;
							letter-spacing: .025rem;
							border-right: solid 1px $gray;
							font-weight: 600;

							&:first-of-type { padding: 15px 10px; }

							a { 
								color: $coal; 
								transition: $transition-standard;

								&:hover { color: $green; }

							}

							.teamLogo-tiny { display: none; }
						}

					}

				}

			}

		}

	}

	&.widget-gameTable {

		.statTable {
			border: none;
			border-collapse: separate;
			border-spacing: 0 10px;

			tr {  
				background: white !important;
			}

			.even { background: transparent; }

			.game-location-small-list { border-top: none; }

			td {
				border: solid 1px $gray;
				padding: 0 !important;

				&:nth-of-type(2) { 
					text-align: center; 
					background: $accentColor;
					border: none;
					transition: $transition-standard;
					position: relative;

					&:hover { background: $green; }

				}

				.game-status { 
					font-size: 1.2rem;
					font-weight: 600;
					color: white;
					

					&:after {
						content: '\f105';
				    display: inline-block;
				    position: absolute;
				    right: 30px;
				    top: 0;
				    font-family: "FontAwesome";
				    font-size: 1rem;
				    height: 100%;
				    line-height: 103px;
				    width: calc(100% - 30px);
				    text-align: right;
					}

				}

				.game-info-div { color: white; }

				.game-date,
				.game-location {
					font-size: 12px !important;
					color: #f1f1f1;
				}

				.gameListTeam {
					height: auto;
					line-height: 40px;
					border-bottom: 1px solid $gray;
					margin-bottom: 0;
					background: white;
    			padding: 0 10px;

					&:last-of-type { border-bottom: none; }

					.gameListTeamName { 
						padding: 10px 0; 
						height: 50px;
						font-family: $fontTwo;
						font-size: .9rem;

						a {
							color: $coal;
							font-weight: 600; 

							&.teamLogo-tiny  {
								height: 30px !important;
						    width: 30px !important;
						    border-radius: 0;
						    border: none;
						    background-size: 100%;					  }

						  &.teamName { 
						  	line-height: 30px;
						  }

						}

					}

				}	

				.score { 
					float: right; 
					margin-top: 5px;
					font-family: $fontOne;
					color: $coal;
				}

			}

			.game-location-small-list {
				color: $accentColor;
				font-size: 12px;
				font-weight: $semibold;
				text-transform: uppercase;
				font-family: $fontOne;

				.game-status { color: $accentColor; }

				&:after {
					content: '\f054';
					position: relative;
					display: inline-block;
					font-family: $fa;
					font-size: 9px;
					margin-left: 5px;
				}

			}

		}

	}

}



.updates .widgetHierarchy { padding: 0 15px 15px; }

// Box Score

.boxscore-extras.in_progress p.details { 
	background: transparent; 

	a { padding-left: 0; }

}

.boxscore-container {
	background: $dark !important;
	border-radius: 0;
	padding: 15px 0 0;
	font-family: $fontTwo;
	position: relative;

	&:before {
		content: 'VS';
		display: block;
		width: 100%;
		position: absolute;
		color: $coal;
		text-align: center;
		top: 40px;
	}

	.team-score {
		height: 100px;
		width: 50%;

		.teamLogo-medium { display: none !important; }

		.abbr { 
			top: 40px; 
			background: transparent;
			width: 100%;

		}

	}	

	.team-score p { 
		font-family: $fontTwo; 
		text-transform: uppercase;
		color: $coal;
		font-weight: $semibold;
	}

	.boxscore-extras {
		border-radius: 0;
		border: none;
		background: $gray;
		padding: 10px;
		position: relative;
		transition: $transition-standard;

		&:hover {
			background: $green;
		}

		.key, 
		.value {
			font-family: $fontTwo;
			color: $coal;
			font-size: .75rem;
		}

		.details {
			float: right;

			a {
				position: relative;
  			margin-right: 10px;
  			float: right;

				&:before {
					content: 'See More »';
					display: inline-block;
					position: relative;
					color: $accentColor;
					top: 0;
					right: 0;
					transition: $transition-standard;
					font-size: 12px;
					@include redButton();

				}

				&:hover:before { 
					@include redButtonHover(); 
				}

				img { display: none; }

			}	

		}

	}

	.winners-notch {
		background: transparent;

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 0; 
			height: 0; 
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 8px solid $gray;
			top: 0;
			left: 0;

		}

	}

}

.pe33, .pe25 {

	.boxscore-container .boxscore-extras {
		padding-bottom: 0;

		.details {
			width: 100%;
			margin-top: 10px;

			a {
				width: 100%;
				padding: 0;

				&:before { 
					width: 100%; 
					padding: 7px 10px 5px;
				}

			}

		} 

	}

}

// Tab Box Scores

.pageElement .boxscore-container { 
	position: relative; 
	border-radius: 0;
}

.pe50, .pe66, .pe100, #tab_boxscores_content {

	.boxscore-container {
		padding-top: 0;
		background: transparent !important;

		&:before { display: none; }

		.team-score { display: none; }

		.boxscore-inner-container {
			display: block;
			margin: 0 !important;
			position: relative;

			.boxscore {
				font-family: $fontTwo;
				font-weight: 500;

				th { 
					background: $gray;
					color: $coal;
					font-size: 1rem;
					padding: 10px 15px;

					&.colgroup-edge { border-left: none; }

					&.team,
					&:last-child { border-radius: 0; }

				}

				.team-away,
				.team-home {

					td {
						background: transparent;
						border-bottom: solid 1px $gray;
						padding: 15px;
						font-size: 1rem;
						color: $coal;

						&.team {
							background: transparent;
							border-bottom: solid 1px $gray;
							padding: 15px;

							.teamLogo-small  { display: none; }

							a { color: $coal; }

						}

						&.colgroup-edge { 
							border-left: none; 
							background: transparent;
							color: $coal;
							font-weight: $bold;
						}

					}
				}

			}

			.boxscore-extras { 
				background: $gray; 

				p {
					font-size: .8rem;
				    font-weight: 600;
				    letter-spacing: .025rem;
				}
			}

		}	
		
	}

}

.pe25 , .pe33, .pe50, .pe66, .pe100 {

	.boxscore-container { 
		background: white !important; 
		border: solid 1px $gray;

		.teamLogo-medium {
			border: none;
			background-size: auto 100%;
			width: 100% !important;
			margin: 0 0 5px 0;
			height: 75px !important;
		}

	}

}

// Media Queries

@media screen and (max-width: 768px) {

	.widgetHierarchy.widget-gameTable .statTable {

		td {
			width: calc(100% - 15px);
    	display: block;

    	&:nth-of-type(2) { 
    		padding: 20px 0 !important;
    	}

    	.game-status:after { top: -10px; }

    }

	}

}

@media screen and (max-width: 480px) {

	.widgetHierarchy.widget-leader li .leader-highlight .stats .stats-minor a { font-size: 1.5rem; }

}










