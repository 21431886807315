// *** PDL Page Element Styles *** //

// General
.pageElement {

	h3 { 
		font-family: $fontOne; 
		color: $coal;
		font-weight: $semibold;

		span { font-weight: $semibold; }
	}

}

// Scoreboard Banner
.scoreboardBanner { height: auto; }	

#masterScoreboard,
#scoreboard_container { 
	display: block; 
	padding: 0 !important;
	border-bottom: none;
	background: transparent;

	.scoreboardRibbon {
		background: $mainColor;
		border-bottom: solid 1px $coal;
		border-top: solid 1px $coal;
		border-radius: 0;

		.container {
			left: 0;
			right: 0;
			transition: $transition-standard;
		}

		.previous_button,
		.next_button {
			background: $mainColor;
			transition: $transition-standard;
			height: 86px;
			border-radius: 0;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				color: $gray;
				font-family: $fa;
				height: 86px;
				width: 30px;
				line-height: 86px;
				text-align: center;
				font-size: 1rem;
			}

			&:hover { background: $black; }

		}

		.previous_button { 
			margin-left: -30px;
			border-right: 1px solid $coal;
			left: 0;

			&:before { content: '\f100'; }

		}

		.next_button {
			margin-right: -30px;
			border-left: 1px solid $coal;
			right: 0;

			&:before { content: '\f101'; }

		}

		&:hover {

			.previous_button { margin-left: 0; }

			.next_button { margin-right: 0; }

			.container {
				left: 30px;
				right: 30px;
			}

		}

		.game { 
			width: 200px;
			height: 86px;
			padding: 0 5px;
			position: relative;

			.name {
				font-size: 12px;
				max-width: 100px;
			}

		}

		.gameLink {
			border: none;
			border-radius: 0;
			background: transparent;
			border-right: 1px solid $coal;
			margin-top: 0;
			height: 86px;
			font-family: $fontOne;
			text-transform: uppercase;
			transition: $transition-standard;

			&:hover { background: $black; }

		}

		.date, .team, .status, .name, .score { 
			font-weight: 400;
			font-size: 1rem;
		}

		.date { 
			color: $dark; 
			padding: 15px 5px 10px;
			font-size: 10px;
			font-family: $fontTwo;
		}

		.team { 
			color: white; 
			padding: 5px 6px;
			font-size: 1rem;
			line-height: normal;
		}

		.status { 
			color: $dark; 
			font-size: .8rem;
			letter-spacing: .05rem;
			position: absolute;
	    top: 5px;
	    right: 10px;
	    display: none;
		}


	}

}

#scoreboard_container {

	.scoreboardRibbon {
		margin: 0;
		border: none;

		.previous_button {
			margin-left: 0;
			height: 90px;

			&:before { height: 90px; }

		}

		.next_button {
			margin-right: 0px;
			height: 90px;

			&:before { 
				height: 90px; 
				line-height: 90px;
			}

		}

		.container {
			left: 30px;
			right: 30px;
		}

		.game { 
			height: 90px; 
			line-height: 90px;
		}

	}	
	
}

#scoreboard_container {

	.scoreboardRibbon .game { width: 150px; }

}	


// Tab Elements
.user_mode {

	.pageElement.tabbedElement {
		padding: 0;
		border: none;
		margin-bottom: 15px;
	}	

	.contentTabs, 
	ul.contentTabs.layoutContainerTabs {
		border-bottom: 1px solid $dark;
		height: 35px;

		li {

			span a {
				background: transparent;
				color: $mainColor;
				font-family: $fontTwo;
				font-size: .8rem;
				padding: 5px 0;
				margin-right: 15px;
				letter-spacing: .025rem;
				transition: $transition-standard;
			}

			&.selected span a { border-bottom: solid 4px $mainColor; }

			&:hover span a { color: $dark; }

		}

	}

}

.widget-gameTable a { 

	&.icon.goToLink {
		@include redButton();

		&:hover { @include redButtonHover; }

	}

}

// Link Elements
.pageElement.linkElement {
	padding: 0 30px 30px;

	h4 {
		margin: 0 10px;

		a {
			padding: 15px;
			background: $accentColor;
			color: white;
			font-family: $fontOne;
			text-align: center;
			text-transform: uppercase;
			font-size: 1.25rem;
			border-bottom: solid 4px $darkRed;
			transition: $transition-standard;
			font-weight: $semibold;

			&:after { display: none; }

			&:hover {
				background: $darkRed;
				border-bottom: solid 4px $accentColor;
			}

		}

	}

}

// Sponsors
.sponsorElement {
	background: white;
	border: solid 1px $gray;
	padding: 15px;

	.description {
		margin-left: 110px;

		a { 
			@include redButton(); 
			display: inline-block;
			margin-top: 10px;

			&:hover { @include redButtonHover(); }

			&:after { display: none; }

		}

	}	

	.imageLink {
		margin: 0;
	}

} 

// Text Block Elements
.pageElement.textBlockElement {

	h3 {
		color: $mainColor;
		font-family: $fontOne;
		padding: 5px 0;
		border-bottom: solid 1px $accentColor;

		span {
			font-weight: $semibold;
			font-size: 1.5rem;
		}

	}

	.text {

		p { 
			font-family: $fontTwo; 
			color: $coal;
			font-size: 14px;
			line-height: 1.75rem;

			span { 
				font-family: $fontTwo !important; 
				font-size: 14px;
			}

			a {
				text-decoration: none;
				color: #00B663;
				font-size: 14px;
			}

		}

		ol {

			li { 
				font-size: 14px;
				font-family: $fontTwo;
			}

		}

		h1, h2, h3, h4, h5 {
			font-family: $fontOne;
			font-weight: $semibold;
			padding: 0;
		}

		h1 {
			color: $coal;
			font-size: 2rem;
		}

		h2 {
			color: $accentColor;
			font-size: 2rem;
		}

		h3 {
			color: $coal;
			font-size: 1.5rem;
			border-bottom: none;
		}

		h4 {
			color: $accentColor;
			font-size: 1.5rem;
		}

		h5 {
			color: $coal;
			font-size: 1rem;
		}

	}

}

// Registration Link
.surveyLinkElement {
	border-radius: 0;

	a { 
		@include redButton(); 

		&:hover { 
			@include redButtonHover(); 
			color: white;

			span { color: white; }

		}

		span { 
			color: white; 
			font-size: 1.2rem;
			text-decoration: none;
		}

	}

}

// Tables

.pageElement {

	.statTable, 
	.dataTable {
		border: none;

		th {
			background: $gray;
			color: $coal;
			font-family: $fontOne;
			padding: 10px;
			font-size: 1.1rem;
			font-weight: $bold;
			min-width: initial;
			text-align: left;
			letter-spacing: .025rem;
		}

		tr {
			border-bottom: solid 1px $gray;
			border-left: solid 1px $gray;
			border-right: solid 1px $gray;

			&.highlight,
			&.odd,
			&.even { background: white; }

			td { 
				padding: 10px; 
				font-size: .8rem;
				font-family: $fontTwo;
				letter-spacing: .025rem;
				text-align: left;

				a { 
					color: $coal; 

					&.game_link_referrer.addButton {
						position: relative;
						display: block;
						right: 0;
						@include redButton();
						padding: 5px 10px;
						font-family: $fontOne;
						color: white;
						font-size: .75rem;

						&:hover {
							@include redButtonHover();
						}

						&:after {
							content: '\203A';
							display: inline-block;
							position: relative;
							margin-left: 7px;
						}

						img { display: none; }

						&.finished {

							&:after { 
								content: 'Finished \203A'; 
								text-transform: none;
							}
						}

					}

				}

				.teamLogo-tiny { display: none; }

			}

		}

	}

}

#tab_completegamelist_content { overflow-x: scroll; }

// Countdown Element

.pageElement.countdownElement {
	background: $gray;
	font-family: $fontOne;
	padding: 0;
	text-shadow: none;

	h2 { 
		color: white; 
		margin: 0;
		padding: 15px;
		background: transparent;
		color: $coal;
		border-bottom: solid 2px $dark;
		margin: 0 30px;
		font-size: 2.5rem;
    padding: 30px 0;
	}

	abbr {
		background: transparent;
		color: white;
		margin-top: 1px;
		padding: 5px;
		color: $coal;
    padding: 30px 0;

    b { font-size: 2.5rem; }

	}

	span { margin: 5px 3%; }

	small { font-size: 1rem; }

}

body:not(#splash) {

.pageElement.newsSlideShow {

	.newsSlideShow-headline {
		background: rgba(255,255,255,.9);
		width: 100%;
		max-width: 60%;
		left: 5%;
		bottom: 7%;
		padding: 2%;

		h1 {
			margin-bottom: 10px;

			a {
				color: $mainColor;
				font-family: $fontOne;
				font-size: 2vw;
			}

		}

		p {
			line-height: normal;
			height: auto;
			color: $coal;

			span { 
				color:$coal; 
				display: block;
				margin-bottom: 5px;
			}

			a.newsSlideShow-more {
				color: white;
				background: $accentColor;
				box-shadow: none;
				border-radius: 0;
				font-family: $fontOne;
				margin: 0;
				padding: 7px 15px;
				transition: $transition-standard;

				&:hover {
					text-decoration: none !important;
					background: $dark;
				}

			}

		}	

	}

	.newsSlideShow-thumbnails {
		width: 90%;
		padding: 0;
		margin: 15px 5%;
		text-align: left;

		li {
			width: 12px;
			margin: 0 3px;
			border-radius: 100px;
			overflow: hidden;

			a {
				border: none;
				width: 12px;
				height: 12px;
				background: white;

				img { display: none; }
			}

		}

	}

}

}

// Media Slider
.pageElement.mediaSlider {

	h2 {
		color: $coal;
		font-family: $fontOne;
		font-weight: $semibold;
		font-size: 2rem;
		padding: 0;
	}

	.sn-media-slider {

		.slide-title,
		.slide-description { 
			font-family: $fontOne; 
			margin: 5px;
		}

	}

}

// Team Buttons
.column {

	.pageElement.widget-teamButtons {

		.teamBtnCol {

			a {
				background: transparent;
				border: none;
				color: $coal;
				font-family: $fontOne;
				transition: $transition-standard;
				height: 50px;
				margin: 0 0 10px;
				background: white;
				border: solid 1px $gray;
				position: relative;

				&:after {
					content: '\f105';
					display: inline-block;
					position: absolute;
					height: 50px;
					width: 20px;
					right: 10px;
					top: 0;
					line-height: 50px;
					font-family: $fa;
					text-align: center;
					visibility: visible;
				}

				&:hover { 
					color: $accentColor; 
					background: $light;

					.sport-colors .teamLogo-small {
						border: none;
						border-radius: 0;
						border-right: solid 1px $gray;
					}

				}

				.sport-colors {
					border: none;
					padding: 7px 0px;

					.color0-sm,
					.color1-sm,
					.color2-sm {
						display: none;
					}

					.teamLogo-small {
						border: none;
						border-radius: 0;
						height: 48px !important;
						width: 50px !important;
						top: 0;
						background-size: auto 100%;
						transition: $transition-standard;
						margin-right: 10px;
						border-right: solid 1px $gray;
						background-size: auto 80%;
						left: 0;
						background-color: white;
						display: none;
					}

				}

			}

		}

		.teamname { 
			font: $semibold normal 1rem $fontTwo; 
			padding: 5px 5px 5px 15px;
			color: $mainColor;
		}

	}

}	

// Gallery Element
.galleryElement h3 {
	padding: 10px 0;
}

// Contact Element
.pageElement.contactElement {
	font-family: $fontOne;
	color: $coal;

	h3 {
		font-family: $fontOne;
		color: $mainColor;
		padding: 0;

		span { font-weight: $semibold; }

	}

	.title { color: $accentColor; }

}

// Page Divider
.hrElement { border-top: 1px solid $gray; }

// Hero photo captions
.pageElement.heroPhotoElement {

	.cutline {
		border-top: none;
		background: transparent;
		font-family: $fontOne;
	}

}

// Poll Element
.pageElement.pollElement {
	background: white;
	border: none;

	h3 {
		background: $mainColor;
		color: white;

		span {
			font-size: 1.5rem;
			line-height: normal;
		}

	}

	ul {

		li {

			&.pollChoice label {
				font-family: $fontOne;
				font-size: 1rem;
				color: $coal;
			}	

			&.voteButton input {
				background: $accentColor;
				font-family: $fontOne;
				font-weight: $semibold;
				padding: 8px 30px 3px;
				border-bottom: 4px solid $darkRed;
				transition: $transition-standard;
				font-size: 1rem;

				&:hover {
					background: $darkRed;
					border-bottom: solid 4px $accentColor;
				}

			}

		}	

	}

}

// Cutline

.pageElement .cutline { padding: 5px 0; }

// Select Inputs

.user_mode {

	select {
		background: white;
	  border: 0;
	  border-radius: 0;
	  height: 36px;
	  font-family: $fontTwo;
	  font-size: .75rem; 
	}

	button#user-filters-submit {
		background: $accentColor;
		font-family: $fontTwo;
		font-weight: $semibold;
		padding: 8px 30px 3px;
		border-bottom: 4px solid $darkRed;
		transition: $transition-standard;
		font-size: 1rem;
		border-radius: 0;
		text-shadow: none;
		text-transform: uppercase;
		box-shadow: none;
		color: white;

		&:hover {
			background: $darkRed;
			border-bottom: solid 4px $accentColor;
		}
		
	}

}


// Excel Link

.icon.excelLink {
	font-family: $fontOne;
	color: white !important;
	font-weight: $semibold;
	background: $mainColor;
	font-size: 1rem !important;
	padding: 10px !important;
	margin: 0 !important;
	transition: $transition-standard;

	&:before {
		content: '\f15c';
		display: inline-block;
		position: relative;
		margin-right: 7px;
		font-family: $fa;
	}

	&:hover { background: $dark; }

}

// Document Link

.pageElement.documentElement {

	.excel.iconSizeM {
		height: auto;
    padding-left: 0;
    padding-top: auto;

    a {
    	top: auto;
    	color: white;
    	font-size: 1rem;
    	font-weight: $semibold;
    	background: $mainColor;
    	padding: 10px 20px;
    	transition: $transition-standard;

    	&:before {
				content: '\f15b';
				display: inline-block;
				position: relative;
				margin-right: 7px;
				font-family: $fa;
			}

			&:hover { background: $dark; }

    }

	}

}

.reportTableElement .filtered_survey_actions { margin-bottom: 10px; }


// Photo Gallery Element
.pageElement.galleryElement {

	.image_title {
		background: transparent;
	}

	.prev_btn,
	.next_btn { top: calc(50% + 23px); }

	.controls { display: none !important; }

}

// Rankings Element

.pageElement.rankingsElement {

	.logoLink { display: none; }

	.statTable {

		tr {
			
			td.name a { color: $accentColor; }

		}	

	}	

	.up,
	.down {
		background: transparent;
		position: relative;

		&:before {
			content: '\f077';
			display: block;
			position: absolute;
			font-family: $fa;
			top: -3px;
			left: 0;
			width: 100%;
			color: $mainColor;
		}

	}

	.down:before { 
		content: '\f078'; 
		color: $accentColor;
	}

}	

// Contact Form

	/* Contact Form */

.user_mode .contactForm {
	background: #c2c6ca; 
	padding: 3em 1em;

	label {
		font-family: "klavika-web", sans-serif; 
		color: #222; 
		font-size: 1.125rem; 
		line-height: 1.5; 
		font-weight: 400; 
		margin: 0 1em;
		display: inline-block; 
		width: 30%; 
		text-align: right; 
		vertical-align: top;
	}

	input {
		font-family: "klavika-web", sans-serif; 
		color: #222; 
		font-size: 1.125rem; 
		font-weight: 400; 
		border: none; 
		padding: .25em .5em; 
		margin-bottom: .5em; 
		width: 50%; 
		display: inline-block; 
		-webkit-appearance: none; 
		-webkit-border-radius: 0;
	}

	textarea {
		font-family: "klavika-web", sans-serif; 
		color: #222; 
		font-size: 1.125rem; 
		font-weight: 400; 
		border: none; 
		padding: .5em;
		margin-bottom: .75em; 
		width: 50%; 
		display: inline-block; 
		min-height: 3em; 
		-webkit-appearance: none; 
		-webkit-border-radius: 0;
	}

	.submit {
		background: #ec1c2d; 
		color: #fff; 
		font-family: "klavika-web", sans-serif; 
		font-size: 1.5rem; 
		font-weight: 700; 
		text-transform: uppercase; 
		border-bottom: 4px solid #d0112b; 
		cursor: pointer; 
		margin: 0 auto; 
		display: block;
		width: 50%; 
		margin-left: calc(30% + 2em); 
		transition: box-shadow .2s ease-in-out, background .2s ease-in-out;
		-webkit-appearance: none; 
		-webkit-border-radius: 0;

		&:hover {
			background: #d0112b; 
			box-shadow: inset 0 0 20px #ec1c2d; 
			transition: box-shadow .2s ease-in-out, background .2s ease-in-out;
		}
	}	

	input[type=checkbox] { -webkit-appearance: checkbox; }

}

// Site Map
.pageElement.siteMapElement {

	.unlimitedHierarchy {
		background: transparent;

		li {
			background: transparent;

			div {
				background: transparent;

				h3 {
					font-size: 1rem;
					color: $mainColor;

					a { 
						color: $coal; 
						transition: $transition-standard;

						&:hover {
							text-decoration: none;
							color: $accentColor;
						}
					}
						
				}

			}

		}

		.lastLi { background: transparent; }

	}

}

// Top Prospects

#prospectsContainer,
#playerMenuContainer {
	padding-top: 15px;
	position: relative;

	.prospects:after {
		content: '';
		display: block;
		position: absolute;
		width: calc(100% - 15px);
		background: white;
		height: 10px;
		bottom: 15px;
		left: 0;
	}

	.prospects,
	.players {
		padding-left: 0;
		margin-right: 15px;
		background: white;
		padding-bottom: 15px;

		.pageEl {
			padding: 0 15px;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 3px;
				left: 0;
				height: 2px;
				width: calc(100% - 30px);
				background: $gray;
				visibility: visible;
				margin: 0 15px;
			}
		
			.pageElement{
				&.textBlockElement {
					margin: 0;
					padding: 15px 0 0;
					position: relative;

					.text { 
						margin-top: 0; 
						position: absolute;
						width: 100%;
						bottom: 0;

						h1, h2, h3, h4 {
							text-transform: uppercase;
							line-height: normal;
						}

						h1 {
							color: $mainColor;
							font-size: 4vw;
						}

						h2 {
							font-size: 1vw;
						}

						h3 {
							color: $black;
						}

						h4 {
							color: $black;
							font-size: 3vw;
							margin-top: -10px;
						}

					}

					.rightTextImage { 
						margin: 0; 

						img {
							filter: grayscale(100%);
							transition: $transition-standard;
							height: 175px;
  						width: auto;
						}

					}

					&:hover {

						.rightTextImage img { filter: grayscale(0%); }

					}

				}
				
				&.tabbedElement { 
					padding: 0 !important; 
					margin-right: 0;
					overflow: visible;

					ul.contentTabs.layoutContainerTabs {
						background: $dark;
						height: 45px !important;
						position: relative;
						border-bottom: none;
						text-align: center;

						&:before,
						&:after {
							content: '';
							display: block;
							position: absolute;
							height: 100%;
							width: 15px;
							background: $dark;
							top: 0;
						}

						&:before { right: 100%; }

						&:after { left: 100%; }

						li {
							line-height: 45px;
							float: none;
							display: inline-block;


							span a { 
								color: white !important; 
								padding: 9px 0 !important;
							}

							&.selected span a { border-bottom: solid 4px $accentColor !important; }

						}	

					}

				}	

			} 

		}	

	}

}

// Bottom Options
.bottomOptions .pageOptions {

	li { font-size: 12px; }

}

// .selectedNodes

.pageElement .selectedNodes { 
	background: $gray; 
	border: none;

	h3 { 
		font-family: $fontTwo; 
		font-size: .9rem;
	}

}

.nodePickerContainer {

	.tab {
		background: transparent;

		a { 
			background: $dark; 
			margin-right: 0;
			color: white;
			text-shadow: none;
			letter-spacing: .025rem;
			font-weight: 300;
			font-size: 12px;
			padding: 0 10px;
		}	

	}

	.nodePickerSlideBox { height: auto !important; }

}	

//Custom Elements

.user_mode .clubButtons {

	.alpha {

		.pageElement.textBlockElement {

			&:before { display: none; }

			&:hover { background: white; }

		}	

	}

	.pageElement.textBlockElement {
		background: white;
		border: solid 1px $gray;
		transition: $transition-standard;
		position: relative;
		margin-bottom: 5px;

		&:hover { background: $light; }

		&:before {
			content: '\f105';
			display: inline-block;
			position: absolute;
			right: 15px;
			top: calc(50% - 7px);
			font-family: $fa;

		}

		.leftTextImage,
		.rightTextImage {
			width: 50px !important;
    		min-height: 40px !important;
    		margin: 0;
    		padding: 5px 0 3px;
    		border-right: solid 1px $gray;
		}

		img {
			    width: 40px !important;
    			height: 40px !important;
    			margin-left: 4px;
		}

		.text p {

			a {
				color: $coal;
				font-size: 18px;
				line-height: 45px;
				margin-left: 60px;
    			display: block;

			}

		}

	}

}

.trimStandings {

	.npAccordion {
		width: 100%;
    display: inline-table;
    display: none;
	}

	.accordionBtn {
		display: block;
  	font-family: $fontTwo;
  	height: 40px;
  	color: $gray;
  	text-align: center;
  	background: white;
  	border-bottom: solid 1px $gray;
  	border-right: solid 1px $gray;
		border-left: solid 1px $gray;
		transition: $transition-standard;
		position: relative;

		&:hover { 
			color: $dark; 
			cursor: pointer;
		}

  	&:before {
  		content: 'Full Standings \f107';
  		display: inline-block;
  		font-family: $fa;
  		height: 100%;
  		position: absolute;
  		left: 0;
  		top: 0;
  		line-height: 40px;
  		text-align: center;
  		width: 100%;
  		font-size: .8rem;
  		font-weight: 500;
  		transition: $transition-standard;
  	}

  	&.npVisible {

  		&:before {
  			content: 'Show Less \f106';
  		}

  	}

  }

	.tabContainer { margin-top: -5px; }

	.tabbedElement {

		.contentTabs {
			margin-bottom: 0;
			padding: 0 5px;
			border-bottom: none !important;

			li {

				span a { 
					color: $dark !important; 
    			letter-spacing: .05rem !important;
    			font-family: $fontTwo !important;
    			font-size: .75rem !important;
				}

			}

		}

	}

	.widgetHierarchy ul li {

		.sportTableLabel h4 { display: none; }

		table.statTable .teamLogo-small { 
			display: block; 
			border: none;
			border-radius: 0;
			background-size: auto 100%;
			height: 30px !important;
    	width: 30px !important;
		}

	} 

	.statTable,
	.npAccordion {
		background: white;
		border-right: solid 1px $gray;
		border-left: solid 1px $gray;

		th { 
			background: white; 
			font-family: $fontTwo;
			font-size: .8rem;
			padding: 15px 10px;
			display: none;
			border-top: solid 1px $gray;
		}

		tr {
			border-bottom: solid 1px $gray;

			td, th {

				&.gamesPlayed,
				&.pointsScored,
				&.name { display: table-cell; }

				&.name { width: 80%; }

				&.gamesPlayed,
				&.pointsScored { width: 10%; }

			}

			td {
				border-bottom: solid 1px $gray;
				padding: 10px;
				display: none;
				color: $coal;

			}	

			&.nonPlayoff { 
				display: none;

				td {
					background: $light !important; 
				}

			}	

		}

		.name { 
			display: table-cell; 

			&.condensedView {
				display: none;
			}

		}

	}

}


// Media Queries

@media screen and (max-width: 1024px) {

	#masterScoreboard,
	#scoreboard_container {

		.scoreboardRibbon {

			.container { 
				left: 30px;
				right: 30px;
			}
			
			.game { width: 150px; }	
		
		}

	}

}


@media screen and (max-width: 990px) {

	body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline h1 a {
		font-size: 3vw;
		line-height: normal;
	}

}	

@media screen and (max-width: 768px) {

	body:not(#splash) .pageElement.newsSlideShow {

		.newsSlideShow-thumbnails { display: none; }

		.newsSlideShow-headline {
			left: 0;
			bottom: 0;
			max-width: 100%;
			border-bottom: 3px solid $gray;

			h1 { 
				margin-bottom: 0;
				text-align: center; 
			}

			p a.newsSlideShow-more { display: none; }

		}

	}	

}


@media screen and (max-width: 640px) {

	body:not(#splash) .pageElement.newsSlideShow .newsSlideShow-headline h1 a { font-size: 4vw; }

	.user_mode .pageElement.tabbedElement { 

		ul.contentTabs.layoutContainerTabs {
			height: auto;
			border-bottom: none;

			li {
				display: block;
				width: 100%;

				span a {
					display: block;
					text-align: center;
					border-bottom: solid 2px $dark;
				}

				&.selected span a {
					border-bottom: solid 4px $mainColor;
					color: $mainColor;
				}

			}

		}

	}	

	.pageElement.countdownElement {

		h2 { font-size: 1.75rem; }

		abbr b { font-size: 2rem; }

	}	

}

// Animations

.pullDown{
	animation-name: pullDown;
	-webkit-animation-name: pullDown;	

	animation-duration: .1s;	
	-webkit-animation-duration: .1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%; 		
}

@keyframes pullDown {
	0% {
		transform: scaleY(0.1);
	}
	100% {
		transform: scaleY(1);
	}							
}

@-webkit-keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

