//*** Sports Page Styles ***//

.sport-tools { background: transparent; }

// Sport Header
#sport-header {
  float: left;
  display: block;
  width: 100%;
  border-top: none;

	.teamLogo  {
		left: 15px;
		height: 40px;
		width: 120px;
	}

	&.noDrop{

  	#megaDropDown-seasons{ padding-right: 10px !important; }

	}

  #snm_advert {
    display: none;
    text-decoration: none;
    padding: 10px;
    background: transparent;
    position: absolute;
    z-index: 1;
    opacity: 0.8;
    @include transition( all 0.3s ease-in-out);

    &:before{
      width: 30px;
      height: 25px;
      position: absolute;
      display: block;
      font: normal 14px/1em $fontOne;
      font-size: 1.75em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      background:url($assetPath+'/app_images/marketing/mobilePhone.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover { opacity: 1; }

		span,
		strong {
      color: #444;
      font-size: 9px;
      text-align: left;
      display: block;
      line-height: 13px;
      display: block;
      padding-left:33px;
		}

	}

	.sport-tools {
		margin: 10px 0px 20px 0px;
		width: 100%;
		display: block;
		background: none;
		border-top: 1px solid #efefef;
		float: left;
		padding: 0px;
    background: transparent;

		ul{
  		width: 100%;
  		z-index: 1;
  		position: relative;

  		&:after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background: #efefef;
        float: left;
        z-index: -1;
        position: absolute;
        opacity: 1;
        visibility: visible;
        bottom: 0px;
  		}

			li{
				display: inline-block;
				margin: auto;
				background: none;
        border-right: 0px;
        border-left: 0px;
        border-top:0px;
        border-bottom: 3px solid #eee;
        float: left;
        list-style: none;
        @include border-radius(0px);

				a{
					display: inline-block;
					text-align: center;
					padding: 10px;
					background: none;
					border:none;
					list-style: none;
          cursor: pointer;
          color: $coal;
          display: block;
          font: .8rem $fontTwo;
          outline: medium none;
          text-decoration: none;
          width: auto;
          @include font-smoothing();
          @include transition( color 0.3s ease-in-out);
				}

				a:before {
					font: normal normal normal 14px/1em $fa;
					padding: 0px;
					display: none;
				}

				&:hover {
				  @include transition( all 0.3s ease-in-out);
          border-bottom: 3px solid $accentColor;

          a { color: #000; }

				}

			}

			.selected {
        border-bottom:3px solid $accentColor;
        border-right: 0px;
        border-left: 0px;
        border-top:0px;

        &:hover{

          a { color: $accentColor; }

        }

        a { color: $accentColor; }

      }
			#tool-home{

				a {

          span{
            background: none;
            text-indent: 0px;
            width: auto;
            height: auto;
		      }

		      &:before{ content: "\f015"; }

	      }

			}

			#tool-roster{

				a:before{ content: "\f007"; }

			}

			#tool-game-schedule{

				a:before{ content: "\f133"; }

			}

			#tool-player-stats{

				a:before{ content: "\f080"; }

			}

			#tool-team-stats{

				a:before{ content: "\f1fe"; }

			}

			#tool-standings{

				a:before{ content: "\f1b3"; }

			}

			#tool-bracket{
				a:before{
					content: "\f0e8";
				}
			}
			#tool-photo{

				a:before{ content: "\f03e"; }

			}

			#tool-video{

				a:before{ content: "\f1c8"; }

			}

			#tool-posts{

				a:before { content: "\f086"; }

			}

			.tool-yellow-admin{

  			a:before{ content: "\f013"; }

			}

      .tool-yellow{

  			a:before{ content: "\f013"; }

			}

      .tool-yellow-manage-team{

        a:before{ content: "\f024"; }

      }

		}

		ul.tools { width: 100%; }

		.button-tool { float: left; }

		li,
		#autoRefreshBar li {
			display: inline;
			float: left;
			border-right: 1px solid #eee;
		}

		li.tool-yellow {
			border-color: #edc52c;

			a{
				background: #fddd40 url($assetPath+'/app_images/sport-header/btnsBG-yl.gif') 0 0 repeat-x;
				border-color: #ffc;
				color: #807540;
			}

		}

		li.tool-yellow:hover {
			border-color: #dda300;
			color: #594100;

			a{
				background-color: #fddd40;
				background-position: 0 -80px;
				border-color: #ffc;
				color: #1a1a1a;
			}

		}

		li.tool-yellow:active {
			border-color: #dda300;
			color: #594100;

			a{
				background-color: #fddd40;
				background-position: 0 -160px;
				color: #333;
			}

		}
	}
}

// Sport Crumbs
#sport-crumbs{
	position: relative;
	float: left;
	display: block;
	width: 100%;
	background: transparent;
  border-bottom: solid 1px $accentColor;

	.sport-crumbs-btns{
    display: block;
    float: left;
    overflow-x: scroll;
    overflow-y:hidden;
    -ms-overflow-style: none;
    width: 100%;
    padding: 10px;
    border-top:1px solid #efefef;
    border-bottom: 1px solid #efefef;

    &::-webkit-scrollbar{
      display: none;
    }

	}

	ul{
    display: inline-block;
    float: left;

    li{
    	float: left;
    	list-style: none;

    	a{
    		padding: 0px;
        font-family: $fontOne;
        color: $coal;
        font-size: .9rem;
        transition: $transition-standard;

        &:hover { color: green; }

        span {
          font-size: .9rem;
          transition: $transition-standard;

          &:hover { color: $green; }

        }

    		&:before{
      		content: "\f054";
          color: $accentColor;
          display: inline-block;
          font: normal 10px/1em $fa;
          padding: 2px;
          position: relative;
          margin: 0 0 0 10px;
    		}

    	}

      &:first-child{

        a:before{
          display: none;
        }

      }

    }

    li.crumbSelected{
      position: relative;

      a{ color: #000; }

      .arrow-down{

        &:after{
          content: "\f077";
          color: #000;
        }

      }
    }
	}

	.sport-crumbs-list{

    ul{
      li{

      	a{
      		padding: 10px 20px;
      	}

      }
  	}
	}

	a{
    color: white;
    display: block;
    outline: medium none;
    padding: 10px 20px;
    position: relative;
    text-decoration: none;
    font-weight: normal;
    background: none;

    &.last_child{
      background: none;

      &:hover{ background: none; }

    }

		span{
      display: inline-block;
      font-size: 0.85em;
      padding: 10px;
      text-align: center;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
		}

		span:after{
      border: 1px solid #777;
      border-radius: 20px;
      font: 12px/1em $fa;
      margin: auto;
      padding: 4px;
      text-align: center;
		}

		span.division, span.league, span.team  {
			background: none;
			padding: 5px 0 5px 5px;
		}

		.arrow-down {
      background: none;
      display: block;
      height: auto;
      margin: auto;
      opacity: 0.75;
      padding: 0;
      position: relative;
      right:0px;
      top: 50%;
      width: auto;
      display: inline-block;
      padding-left: 10px;
		}

	}

	a:hover{
		color: $light;
		background: none;
	}

	a.wide { padding: 0px; }

	.arrow-down {

    &:after{
     content: "\f078";
     display: inline-block;
    }

	}

	.sport-crumbs-list-wrapper{
  	display: block;
  	width: 100%;
	}

  .sport-crumbs-list {
  	display: none;
  	text-align: left;
  	float: left;
  	margin: auto;
  	position: absolute;
  	top: 100%;
  	width: 100%;
  	max-width: 50%;
  	z-index: 20;
  	border-left: 1px solid #eee;
  	border-right: 1px solid #eee;

    &.thisCrumb{
      display: block !important;
      @include animation(dropdown-season $transition-standard);
    }

  	ul {
  		list-style: none;
  		width: 100%;

  		li{
  			width: 100%;
  			display: block;

  			a {
  				background: #fff;
  				color:#666;
  				border-bottom: 1px solid #eee;
  				display: block;
  				font-size: 12px;
  				line-height: 24px;
  				padding: 5px 10px;
  				text-decoration: none;
  				width: 100%;
  				display: block;

  				&:before{ display: none; }

  				img{ margin: 0 3px -3px 0; }

  			}

  			a:hover {
  				color: $light;
  				background-color: #fafafa;
  			}

  		}

  		.sport-crumbs-list-close{
    		display: block;
    		background: #fff;

    		a{
      		display: block;
      		float: right;
      		text-align: right;
      		width: 50px;
      		border-bottom: 0px;
      		background: transparent;
      		padding: 15px 15px 10px 10px;

      		&:after{
            content: "\f00d";
            position: relative;
            font: normal normal normal 12px/1em $fa;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: translate(0, 0);
            color: #666;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            border-radius: 40px;
            padding: 3px 5px;
            border:1px solid #666;
            color: #666;
            opacity: 0.7;

            &:hover{
              color: #000;
              border-color: #000;
            }

      		}

      		&:hover{
        		background: #fff;
        		color: #000;
      		}

    		}
  		}
  	}
  }
}

//Sport Colors
.sport-colors {
  clear: both;
  overflow: visible;
  position: relative;
  float: left;
  width: 100%;
  border-bottom: 1px solid $gray;

  .color0,
  .color1,
  .color2 { display: none; }

  .edge{ display: none; }

  h2{
    float: left;
    font: bold 2.3em/1.5em $fontOne;
    max-width: 75%;
    padding: 15px 0px 15px 0px;
    position: relative;
    text-shadow: 1px 1px 0 #fff;
    width: 100%;
    left: 0;
    display: inline-block;
    width: 100%;
    top: 0;
    left: 0;

    a{
      display: inline-block;
      text-decoration: none;
      padding: 0px;
      color: $mainColor;
      text-shadow: none;
    }

  }

  .teamLogo-large{
    height: 75px !important;
    width: 75px !important;
    position: relative;
    display: inline-block;
    float: left;
    border-radius: 0;
    border: none;
    left: 0;
    top:0;
    margin: 15px;
    background-size: contain;
    display: none;

    &[title="League"] { display: none; }

  }

}
  /* Seasons Mega Drop Down */
  .dropdown-wrapper{
    display: block;
    position: relative;
    width: 100%;
    float: left;
    clear: both;

    &.mdd-active-open{

      #megaDropDown-seasons {

        &:after{ content: "\f077"; }

      }

      #megaDropDown-seasons-callout{
        display: block;
        @include animation(dropdown-season $transition-standard);
      }

   }

   #megaDropDown-seasons {
    background: #efefef;
    display: inline-block;
    margin: auto;
    outline: medium none;
    text-align: right;
    text-decoration: none;
    width: 100%;
    height: 100%;
    position: relative;
    padding:0px 50px 0px 10px !important;
    background-image: none !important;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    .season{
      color: #999;
      display: block;
      font: normal 10px/1em $fontTwo;
      margin: auto;
      width: auto;
      margin-bottom: 5px;
    }

    .subseason{
      color: #666;
      font: bold 16px/1em $fontOne;
      font-weight: 500;
    }

    &:after{
      content: "\f078";
      display: none;
      position: absolute;
      right: 10px;
      top:10px;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0, 0);
      color: #fff;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      border-radius: 100px;
      border:1px solid #666;
      color: #666;
      font:normal 12px/1em $fa;
      padding: 4px;
    }

  }

  #megaDropDown-seasons-callout{
    background: #fff;
    padding: 10px;
    text-align: left;
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 230px;
    z-index: 2;
    padding: 15px 15px 20px 15px;
    display: none;
    border:2px solid #eee;

    .season-title {
      display: block;
      font: bold 12px $fontTwo;
      padding: 0 0 5px;
      margin: 5px 0;

      span{ float: left; }

    }

    select {
      width: 100%;
      border: 0px;
      border:2px solid #eee;
	    background: #fff;
	    color:#000;
      background-image:url($assetPath+'/app_images/select-down-arrow.svg');
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 27px;
      padding: 7px;
      display: block;
      -webkit-appearance:none;
      -moz-appearance:none;
      appearance:none;
      font-size: .8rem;
    }

    a {
      display: block;
      font-size: 12px;
      padding: 2px 5px;
      text-decoration: none;
      background-color: #efefef;
      text-align: center;
      color: #666;
      border: 1px solid #fff;

      &:hover{
        background: #efefef;
        border: 1px solid #e6e6e6;
      }

    }

    a.close {
      background: #fff;
      border: 1px solid #777;
      @include border-radius(100%);
      color: #777;
      display: block;
      float: right;
      font-size: 12px;
      font-weight: bold;
      margin: 0;
      padding: 0px 7px 2px 7px;
      font-family: $fontTwo;
      border: none;

    }

    a.current {
      background: #efefef none repeat scroll 0 0;
      border-radius: 0;
      color: #333;
      font-size: 11px;
      font-weight: bold;
      padding: 5px 10px;
      text-align: center;
    }

    .footer { margin: 10px 0 0; }

    optgroup option {
      margin: 0;
      padding: 0 0 0 10px;
    }

  }

   #megaDropDown-seasons.mdd-active{
    cursor: pointer;

    &:after{ display: block; }

    &:hover{ background-color: #efefef; }

  }
}

.teamPage #rapidTabs.rapidTabsConstruction{ background: none; }

.yieldPageContent{
  clear: both;
  padding: 0;
}

// Active Players

.activePlayers h4 {
  font-size: 1rem;
  color: $mainColor;
  font-weight: $semibold;
}

// Sport Slider
#sport-slider.sport-tools {

  background: transparent;
  border: none;
  border-radius: 0;

  #scroll-container {
    height: auto;
    overflow-x: scroll;
    position: relative;
    min-height: 75px;
  }

  #track {
    display: none;
  }

  li {
    border: none;
    border-radius: 0;

    a {
      background: $gray;
      color: $coal;
      font-family: $fontOne;
      border-radius: 0;
      font-size: 1rem;
      text-shadow: none;
      transition: $transition-standard;
      border: none;

      &:hover { background: $green; }

    }

  }

  .players {

    li a { padding: 15px; }

  }

}

// Pill Buttons

.sport-tools {
  margin-left: 0;

  .pill-buttons {

    li {
      border: none;
      border-radius: 0;

      a {
        font-family: $fontOne;
        border: none;
        background: transparent;
        padding: 7px 10px;
        font-size: 1rem;
        border-radius: 0;
        color: $mainColor;
        transition: $transition-standard;

        &:hover {
          color: $dark;
        }

      }

      &.selected a {
        background: transparent;
        border-bottom: solid 4px $mainColor;
      }

    }

  }

}

.highlight { background: white !important; }

th.highlight { background: $dark !important; }

// PlayerStatsDiv

#PlayerStatsDiv .sport-tools { background: transparent; }

// Default Report

  .pageElement.paginationElement {
    background: transparent;
    color: $coal;
    font-family: $fontOne;
    padding: 15px 10px;

    h4 {
      font-size: 1.25rem;
    }

    .results {
      color: $coal;
      font-size: .75rem;
    }

    .paginationNav {

      a {
        font-size: 1rem;
        color: $coal;
        transition: $transition-standard;

        &:hover { color: $gray; }
      }

      em {
        font-size: 1rem;
        color: $accentColor;
      }
    }

  }

// stat-category-header

#pageContentContainer h3.stat-category-header {
  border-top: none;
  background: transparent;
  border-radius: 0;
  line-height: normal;
  height: auto;
  padding: 5px 0;
  text-indent: 0;
  color: $mainColor;
  border-bottom: solid 3px $accentColor;
}

// Roster Player Info Element

.pageElement.rosterPlayerInfoElement {

  .defaultPlayerInfo {

    h3 {
      background: $mainColor !important;
      border-top: none !important;
      border-bottom: solid 1px $accentColor !important;
      padding: 5px 0;

      .playerName-bg { background: transparent; }

    }

  }

  .RosterPlayerInfoImage div {
    background: transparent;
  }

}

// PreviewStatsDiv

#PreviewStatsDiv {

  table tr.last {
    background: $gray;

    td { color: $coal !important; }

  }

  .next_game {
    background: $gray;
    border-radius: 0;
    border-bottom: none;

    .info {
      width: 35%;

      .header { color: $accentColor; }

      .time { color: $coal; }

      .vs_or_at { color: $mainColor; }

      a {
        @include redButton();

        &:hover {
          @include redButtonHover();
        }

      }

    }

  }

  .team {

    &.away_team,
    &.home_team {
      background: transparent;
      box-shadow: none;

      .team_logo a {
        border: none !important;
        border-radius: 0;
        display: none;
      }

    }

  }

  .team_name {
    color: $mainColor;
    font-size: 3vw;
    line-height: normal;
    height: auto;
    white-space: normal;
    font-weight: $semibold;
  }

}

// preview-sm-soccer_goalkeeper

#preview-sm-soccer_goalkeeper {

  .icon.goToLink {
    @include redButton();
    margin: 5px 0;
    display: inline-block;
    padding: 9px 15px 5px;

    &:after {
      display: inline-block;
      content: '\f105';
      position: relative;
      font-family: $fa;
      margin-left: 10px;
    }

    &:hover {
      @include redButtonHover();
    }

  }

}

// Gameshow Page

#GameShowContent {

  .awayTeam,
  .homeTeam {

    span.team_logo { display: none; }

  }

}

.gameShow {

  #pageContentContainer { padding: 15px; }

  #GameShowContent {

    ul.team_stats-list-header {
      box-shadow: none;
      border-radius: 0;
      background: $gray;
      border-bottom: none;

      li {
        line-height: normal;
        height: auto;

        h3 {
          font-size: 1.25rem;
          text-shadow: none;
          font-family: $fontOne;
          padding: 5px;
          color: $coal;
          font-weight: 600;
        }

        &.team_logo span a {
          border: none;
          border-radius: 0;
        }

      }

      &.awayTeam,
      &.homeTeam {

        span.team_logo { display: none; }

      }

    }

    .table_container {
      box-shadow: none;
      border-radius: none;
      padding: 0;
      border: none;

      table.player_stats-list {

        th {
          background: $gray;
          border-right: none;
          padding: 10px;
          font-family: $fontOne;
          color: $coal;
          font-size: 1rem;
        }

        td {
          border-right: none;
          padding: 10px;
          font-family: $fontTwo;
          color: $coal;
          font-size: 1rem;
        }

        tr.totals {

          td { color: white; }

        }

      }

    }

    .GameShowDataContainer{

      &.game_stats ul.team_stats-list li ul li {
        height: 40px;
        font-family: $fontTwo;
        font-size: 14px;
        color: $coal;
      }

      .StatWidgetGroup {
        background: transparent;
        margin-right: 0;

        .StatWidgetContainer-sub,
        .StatWidget { margin-right: 0; }

        .StatWidget {

          .player_stats-list-header {
            background: transparent !important;

            li {
              height: auto;
              line-height: normal;
              padding: 0;
              color: $coal;
              text-shadow: none !important;
              width: 100%;
              text-align: center;

              h3 {
                text-shadow: none;
                font-size: 1.25rem;
                padding: 10px;
              }

            }

          }

        }

        .StatWidgetContainer { padding: 0; }

      }

      &.game_boxscore {

        h3 {
          color: $mainColor;
          font-family: $fontOne;
          font-size: 1.5rem;
          margin-bottom: 5px;
          border-bottom: none;

          .team_logo { display: none; }
        }

        ul.scoring_summary {
          font-family: $fontTwo;
          color: $coal;
          font-size: .8rem;

          > li {
            padding: 10px;
            border-right: solid 1px $gray;
            border-left: solid 1px $gray;

            &.interval_row {

              background: $gray;

              ul li {
                font-family: $fontTwo;
                font-weight: bold;
                font-size: 16px;
                letter-spacing: .05rem;

                &.teamScore { width: auto; }

              }

            }

            &.scoring_info {

              &.even,
              &.odd {
                background: white;
                border-bottom: solid 1px $gray;
              }

            }

            ul {
              padding: 0;
              background: transparent;

              li {
                font-size: 16px;
                font-weight: 500;

                &.game_clock { min-width: 4rem; }

              }

            }

          }

        }

      }

    }

    table.player_stats-list.stat_table {
      font-family: $fontOne;
      color: $coal;
      background: transparent;
      padding: 30px;

      tr {
        background: white;
        border-right: solid 1px $gray;
        border-left: solid 1px $gray;
      }

      th {
        padding: 10px;
        background: $gray;
        font-size: 12px;
        border-right: none;

        // &[title*='Minutes Played'] { display: none; }

      }

      .totals {

        th {
          background: white;
          color: $coal;
        }

      }

      td {
        border-right: none;
        padding: 10px;
        border: none;
        border-bottom: solid 1px $gray;
        background: transparent;
        font-size: .8rem;
        font-family: $fontTwo;
        font-weight: 500;

        &.even,
        &.odd { background: transparent; }

        a {
          color: $coal;
          line-height: normal;
        }

        // &.minutesplayed { display: none; }

      }

    }

  }

}

#GameShowScoreboardSimple {
  background: transparent;

  .game_info_bar_v2 {
    background: white;
    border-left: solid 1px $gray;

    .status { background: $accentColor; }

    li { line-height: 38px; }

    .date { color: $mainColor; }

   }

   .game_header_v2 {
    display: none;

    .team_logo .teamLogo-medium {
      border: none;
      border-radius: 0;
      background-size: auto 100%;
      width: 40px !important;
    }

    .team_box {

    }

    h3 {
      color: $coal;
      font-weight: $semibold;
      font-family: $fontTwo;

      span {
        color: $mainColor;

        &:before {
          content: '-';
          display: inline-block;
          margin: 0 7px 0 5px;
          color: $coal;
        }

      }

    }

   }

    .player_stats-list {
      border: none;

      th {
        background: $gray;
        color: $coal;
        font-size: 1rem;
        font-family: $fontOne;
        padding: 5px 15px;
        border-right: none !important;
      }

      tr {
        border-right: solid 1px $gray;
        border-left: solid 1px $gray;
      }

      td {
        font-family: $fontTwo;
        color: $coal;
        font-weight: $semibold;
        padding: 10px;
        border-right: none !important;
        font-size: .9rem;
        background: white;
        border-right: solid 1px $gray;
      }

   }

  .box-score-border {
    border-top: none;
    border-bottom: solid 1px $gray;
  }

}

#GameShowContent {

  &.game_show_nextgen {

    ul.tab_menu-list {
      box-shadow: none;
      border-radius: 0;
    }

    .rapidContentGeneral,
    #contentTabsLoader {
      box-shadow: none;
      border-radius: 0;
      border: none;
      background: transparent;
    }

  }

  .tab_menu {

    #rapidTabs {

      li {

        a {
          background: transparent;
          color: $mainColor;
          font-family: $fontTwo;
          font-size: .8rem;
          padding: 5px 0;
          margin-right: 15px;
          letter-spacing: .025rem;
          transition: $transition-standard;
          border-radius: 0;
          box-shadow: none;
          font-weight: 500;
        }

        &.selected a {
          border: none;
          border-bottom: solid 4px $mainColor;
          box-shadow: none;
          margin-top: 0;
        }

        &:hover a { color: $dark; }

      }

    }

  }

  .StatWidgetGroupContainer.game_preview-header {

    .StatWidgetGroup {
      border-radius: 0;
      box-shadow: none;
      background: transparent;
      border: none;
    }

  }

  .game_preview-header {

    .awayTeam,
    .homeTeam { background: $mainColor !important; }

  }

  .awayTeam,
  .homeTeam  {
    background: $coal !important;
    border: none;
    border-radius: 0;
    font-family: $fontOne;

    span.team_logo {
      text-align: center;

      a.teamLogo-medium  {
        width: 100% !important;
        height: 100px !important;
        border: none;
        border-radius: 0;
        background-size: auto 100%;
      }

    }

    h2 {
      font-family: $fontOne;
      line-height: 45px;
      font-size: 2rem;
    }

  }

  .stat-bar_graph div {

    &.awayTeam,
    &.homeTeam {
      border-radius: 0;
      background: transparent !important;
    }

  }

  .player_stat-leader .player_statbox span.statbox_stat { background: transparent; }

  ul.team_stats-list {
    background: transparent;

    .noEntry { background: transparent; }

    li ul {
      border-bottom: solid 1px $gray;
      border-left: solid 1px $gray;
      border-right: solid 1px $gray;
      margin-bottom: 0;
      background: white;

      li {
        font-size: 4px;
        font-weight: 500;

        &.team_stat {
          color: $coal;
          font-family: $fontOne;
        }

        &.stat-bar_graph .homeTeam span { border-left: none !important; }

      }

    }

  }

  .GameShowDataContainer .StatWidgetGroup {
    background: white;
    box-shadow: none;
    border-radius: 0;
    border: none;

    .player_stat-leader {
      box-shadow: none;
      margin: 0;
      background: $light;
      margin-bottom: 20px;
    }

  }

  .StatWidget {

    &.player_stat-leader-header {
      margin: 0;
      box-shadow: none;
      text-shadow: none;

      div {
        box-shadow: none;
        border-radius: 0;
        background: $gray;
        color: $coal;
        font-family: $fontOne;
        text-shadow: none;
        padding: 7px 0;
        letter-spacing: .05rem;

        span { font-size: 1rem; }

      }

    }

    &.player_stat-leader {

      .noEntry { background: transparent; }

    }

  }

}

#gameGalleryHeader .button {
  @include redButton();

  &:hover { @include redButtonHover();  }

}

#GameShowContent.game_show_nextgen ul.scoring_summary li.scoring_info ul li.team_name { width: auto; }

#GameShowContent {

  ul.game_details {

    li {
      font-size: .9rem;
      margin-right: 15px;
      color: $coal;
    }

    a {
      color: $accentColor;
      transition: $transition-standard;
      display: none;

      &:hover {
        color: $dark;
        text-decoration: none;
      }

    }

  }

  .StatWidgetGroup.inset.game_details {

    .background { background: transparent; }

  }

  .StatWidgetContainer.game_details.header {
    background: transparent;
    height: 40px;
    text-indent: 0;
    margin-bottom: 10px;

    h3 {
      color: $mainColor;
      font-size: 1.5rem;
      font-family: $fontOne;
      border-bottom: 1px solid $gray;
    }

  }

}

// Match Center on Game Page

.gameShow {

  .matchCenterHeader {
    background: $accentColor;
    height: 80px;
    color: white;
    font-family: $fontOne;
    font-weight: 500;

    .teamPanel {
      width: 45%;
      float: left;
    }

    .teamLogos {
      height: 80px;
      position: relative;
      width: 80px !important;

      a {
        border: none;
        border-radius: 0;
        background-size: auto 100%;
        height: 110px !important;
        position: absolute;
        z-index: 1;
        top: -10px;
      }

    }

    .teamAway {

      .awayName {
        float: right;
        text-transform: uppercase;
        line-height: 80px;
        font-size: 2rem;
        margin-right: 15px;
        letter-spacing: .1rem;
        font-weight: 600;
      }

      .awayLogo {
        float: right;
      }

    }

    .teamHome {

      .homeName {
        float: left;
        text-transform: uppercase;
        line-height: 80px;
        font-size: 2rem;
        margin-left: 15px;
        letter-spacing: .1rem;
        font-weight: 600;
      }

      .homeLogo {
        float: left;
      }

    }

    .gameScore {
      width: 10%;
      float: left;

      .scored {
        float: left;
        width: 0;
        line-height: 80px;
        font-size: 3rem;
        letter-spacing: .1rem;
        font-weight: 600;
        opacity: 0;
      }

      .scoreDash {
        width: 100%;
        line-height: 80px;
        float: left;
        text-align: center;
        font-size: 1.5rem;
      }

      .awayScore {
        text-align: right;
      }

    }

  }

  #GS_Video {
    margin: 10px 0;

    iframe {

      &:first-of-type {
        width: 75%;
        float: left;
      }

      &:nth-of-type(2) {
        width: 25% !important;
      }

    }


  }

}

// Input Button

input.button-medium {
  @include redButton();

  &:hover { @include redButtonHover(); }

}

// tab game list

#tab_gamelist_content {

  .pageElement {
    overflow-x: scroll;

    &::-webkit-scrollbar{
      background: transparent;
    }

  }

}

// Prototip

.prototip {
  color: $coal;
  font-family: $fontOne;

  .borderMiddle.borderRow { background: transparent !important; }

  .borderBottom.borderRow,
  .borderTop.borderRow { display: none; }

  .score-away:after {
    content: 'VS';
    display: inline-block;
    position: absolute;
    color: $mainColor;
    font-family: $fontOne;
    top: calc(50% - 16px);
    right: -10px;
  }

  .score-away,
  .score-home {
    width: 159px;
    background: transparent;
    padding: 15px;
    position: relative;
  }

  .sport-colors {
    display: none;
  }

  .ngin .tooltip-outer {
    background: $gray;
    border: none;

    h3 {
      font-family: $fontOne;
      background: $dark;
      color: $coal;
      padding: 10px 15px;
      border-bottom: none;
      text-align: center;
    }

  }

}

#GS_Video {

  padding: 0;

}



// Media Queries
@media only screen and (max-width:1024px) {

  #sport-header{
    padding-right: 15px;

    .sport-tools {

      ul li a { font-size: .9em; }

    }

  }

}

@media only screen and (max-width:768px) {

  .dropdown-wrapper { margin-right: 15px; }

  .GameShowDataContainer.game_boxscore {

    .StatWidgetColumn.width50 { width: 100%; }

  }

  .sportPages .layoutContainer {
    padding-left: 0;

    .pageElement { margin-right: 0; }

  }

  #sport-header{

    #sport-crumbs{

      ul li.crumbSelected{ position:static; }

      .sport-crumbs-list{ max-width: 100%; }

      a {

        span{
          font-size: 0.70em;

          &:after{ font: 10px/1em $fa; }

        }

        .arrow-down{ margin-left: 0px; }

      }

    }

    .sport-tools {

      ul li a{
        padding: 10px 2px;
        font-size: 0em;

        &:before{ display: block; }

      }

    }
  }

  /* Seasons Mega Drop Down */
  .dropdown-wrapper{
    max-width: 100%;
    width: 100%;

    &:before { display: none; }

     #megaDropDown-seasons {

      .season{ font-size: 10px; }

      .subseason{ font-size: 9px; }

    }

     #megaDropDown-seasons-callout{ max-width: 100%; }

  }

  .sport-colors{

    .teamLogo-large{
      height: 45px !important;
      width: 45px !important;
      @include border-radius(100%);
    }

  }

  .gameShow {

    #GS_Video {

      iframe {

        &:first-of-type {
          width: 100%;
          float: none;
        }

        &:nth-of-type(2) {
          width: 100% !important;
          margin-top: 10px;
        }

      }


    }

  }

}

@media only screen and (max-width:640px) {

  .sport-colors {

    h2 {
      font-size: 1.5rem;
      line-height: normal;
    }

    .teamLogo-large { margin: 10px; }

  }

  .yieldPageContent .sport-tools {
    margin: 0;

    ul {
      float: none;

      &.games { float: left; }
    }

    .pill-buttons li {
      width: 100%;
      margin-bottom: 5px;

      a { text-align: center; }

    }

  }

  .sportsPage #pageContentContainer { padding-top: 0 !important; }

  #GameShowContent {

    li.tab_menu-tab { width: 50%; }

    .tab_menu #rapidTabs li{
      margin-bottom: 1px;

      a {
        color: white;
        background: $mainColor;
        padding: 10px 5px;
        text-align: center;
        margin-right: 1px;
      }

      &.selected a {
        background: $accentColor;
        border-bottom: none;
        color: white;
      }

    }

  }

  .gameShow {

    #GS_Video {

      iframe {

        &:first-of-type {
          width: 100%;
          float: none;
          height: 300px;
        }

        &:nth-of-type(2) {
          width: 100% !important;
          margin-top: 10px;
        }

      }


    }

  }

  .gameShow .matchCenterHeader {
    height: 50px;

    .teamLogos {
      height: 50px;

      a {
        height: 70px !important;
      }

    }

    .teamAway .awayName,
    .teamHome .homeName {
      height: 50px;
      font-size: 1.25rem;
      margin: 0;
      line-height: 50px;
    }

    .gameScore {

      .scored {
        height: 50px;
        font-size: 1.75rem;
        line-height: 50px;
      }

      .scoreDash {
        line-height: 50px;
        font-size: 1rem;
      }

    }

  }

}
