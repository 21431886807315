// *** Site Footer Styles *** //

#home {

	#siteFooter > ul { @include center($maxWidthHome);  }
}

#siteFooter {
	background: $black;
	max-width: 100%;
	padding: 0 0 15px;
	width: 100%;

	> ul { 
		@include center($maxWidth); 
		text-align: left;
		background: transparent;
		border-top: none;

		li {

			a {
				transition: $transition-standard;

				&:hover { color: $green; }

			}

			#poweredByNGIN {
				float: none;
				width: 100%;
			}

		}

	}

	#footerUpdates {
		background: $mainColor;

		.updates {
			background: #222222; 
			position: relative;
			@include center($maxWidth);
			padding: 30px 15px;

			.layoutInner {

				> .column1 {}

				> .column2 {
					width: calc(66.66666666% - 30px) !important;
					margin-left: 30px;
				}

			}	

			&:before { right: 100%; }
			
			&:after { left: 100%; }

			.pageElement {

				&.textBlockElement {

					h3 { color: white; }

				}

				&.widgetElement {
					margin-right: 0;

					.boxscore-container {
						background: white;

						.boxscore {

							thead { display: none; }

							.colgroup-edge { 
								background: transparent !important; 
								color: $coal !important;
							}

							.team {
								padding-left: 0;

								a {
									color: $coal !important;
									font-weight: 600 !important;
									font-size: .9rem;
								}
								
								.teamLogo-small { 
									display: block; 
									border: none;
									border-radius: 0;
									background-size: auto 100%;
									height: 30px !important;
								}	

							}

						}

						.boxscore-inner-container .boxscore-extras {
							background: $light;

							a span { color: $coal; }
 
							.details a:before { color: $accentColor; }

							.boxscore-game-location { display: none; }

						}

					}

				}

				&.tabbedElement {

				ul.contentTabs.layoutContainerTabs {

					li {

						span a {
							color: white;
							font-weight: 600;
						}

						&.selected span a { border-bottom: solid 4px white; }

					}

				}

				.tabContainer {
						
						.pageElement.aggregatorElement {
							background: transparent;

							.newsItemElement {

								.newsDate { color: white; }

								.newsAuthor { display: none; }

								h4 { color: white; }

							}

							&.newsAggregatorElement {

								.item {
									background: transparent;
									border: none;

									.newsItemElement .newsItemHeader {

										h2 a { font-size: 1rem; }

										a img {

											&.wide {
												position: relative;
											}

										}

									}	

									h4 a { color: white; }

									> a { background: $mainColor; }

									.details {

										.dateAuthor { 
											color: #f1f1f1; 
											font-size: 12px;
										}

									}

								}	

							}

							.item {
								border-bottom: none;

								&.extendedOptions {
									border-top: solid 2px $dark;
									text-align: center !important;

									// .rssLink { display: none; }

									a.icon {
										padding: 15px 30px;
										background: $accentColor;
										color: white;
										font-family: $fontOne;
										text-align: center;
										text-transform: uppercase;
										font-size: 1.25rem;
										border-bottom: solid 4px $darkRed;
										transition: $transition-standard;
										display: inline-block;
										font-weight: $semibold;
										margin-bottom: 10px;

										&:hover {
											background: $darkRed;
											border-bottom: solid 4px $accentColor;
										}

									}

								}

							}	
						}
					}	

				}

			}	

			.boxscore-container {
				position: relative
			}

		}

	}

	#megaFooter { 
		@include center($maxWidth); 
		padding: 30px 15px;
		display: block;

		.left {
			width: 70%;
			display: inline-block;

			.footerLeft { padding-left: 0; }

			.pageElement.textBlockElement {

				h3 {
					color: $accentColor;
					padding: 0;
					font-family: $fontOne;
					text-transform: uppercase;
					border-bottom: none;

					span { 
						font-size: .7rem;
						font-weight: $semibold; 
						font-weight: 600;
					}

				}

				p {
					line-height: 1.5rem;

					a { 
						color: white; 
						text-decoration: none;
						transition: $transition-standard;
						font-size: .8rem;
						letter-spacing: .05rem;

						&:hover { color: $gray; }

					}

				}	

			}

		}

		.right {
			width: 30%;
			display: inline-block;
			float: right;
			padding-top: 1%;

			.layoutContainer { padding-left: 0; }

			.pageElement.heroPhotoElement { 
				margin-right: 0; 
				text-align: center;
				margin-bottom: 5px;

				img {
					max-width: 45%;
					display: inline-block
				}

			}

			.pageElement.textBlockElement {

				h3 { 
					color: white; 
					margin-top: -20px;
				}

			}

			.beSocial{
				background: transparent;

				p {
					text-align: center;
					margin-bottom: 7px;

					a {
						float: none;
						display: inline-block;
					}

				}

			}

		}

	}

}


// Media Queries
@media screen and (max-width: 1024px) {

	#siteFooter {

		.layoutContainer { padding-left: 0; }

		#footerUpdates { padding: 30px 15px; }

		#megaFooter { padding: 30px 15px; }

	}

}

@media screen and (max-width: 768px) {
	#siteFooter {

		> ul li {

			&#poweredByNGIN {
				float: none;
				width: 100%;
				margin-top: 10px;
			}

		}

		#megaFooter {

			.left { display: none; }

			.right {
				width: 100%;

				.heroPhotoElement img {
					width: 50%;
					margin: auto;
				}

			}

		}

		> ul { 
			text-align: center; 
			border-top: none;
		}

		#footerUpdates {
			background: $mainColor;
			display: none;

			.updates { 
				padding: 15px; 

				.layoutInner {

					> .column1,
					> .column2 { 
						width: 100% !important; 
						margin-left: 0;
					}

				}	

			} 

		}	

	}	

}

























