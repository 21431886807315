// *** Aggregator Styles *** //

// Default Aggs
.aggregatorElement {
	border: none;
	background: transparent;

	h3 { 
		background: transparent; 
		padding: 5px 0;
		color: $mainColor;
		border-bottom: solid 1px $accentColor;

		span { font-size: 1.5rem; }

	}

	.item {
		box-shadow: none;
		border-bottom: none;
		margin: 0 15px;
    	padding: 15px 0;
    	border-bottom: solid 1px #ddd;

		&.extendedOptions { background: transparent; }

		.teaser { 
			margin-top: 10px;
			font-size: .9rem;
    		letter-spacing: .025rem; 
		}

	}

	.newsItemElement {

		h4 {
			float: left;
	    width: 60%;
	    clear: none;
	    color: $coal;
	    font-weight: $regular;
	    font-family: $fontTwo;
		}

		.newsDate, .newsAuthor { 
			display: block; 
			margin-bottom: 0;
		}

		.newsItemHeader {

			a {
				color: $coal;
				font-family: $fontOne;
				font-weight: $semibold;
			    height: 125px;
			    display: inline-block;
			    position: relative;
			    overflow: hidden;
			    margin-right: 3%;
			    float: left;
			    width: 250px;

		    img { margin: 0; }

			} 

			h2 {
				width: 60%;
				display: inline-block;
				vertical-align: top;
				float: none;
				font-size: 18px;

				a { 
					width: 100%; 
					height: auto;
					color: $accentColor;
					transition: $transition-standard;

					&:hover {
						color: $dark;
					}

				}

			}

		}	

	}

	&.newsAggregatorElement {

		h3 { border-bottom: none; }

		.item {
			margin: 0;
			padding: 10px;
			background: white;
			border-left: solid 1px $gray;
			border-right: solid 1px $gray;

			&:first-of-type { border-top: solid 1px $gray; }

			> a { 
				height: 65px;
				width: 110px;
				float: left;
				overflow: hidden;
				position: relative;
				background: white;

				img { 

					&.tall {
						height: auto;
						width: 100%;
					}

					&.wide { 
						height: 100%; 
						width: auto;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}

				}
			}

			h4 a {
				color: $coal;
				font-size: .8rem;
				letter-spacing: .025rem;
				font-weight: $bold;
				line-height: normal;
				transition: $transition-standard;
				font-family: $fontTwo;

				&:hover { color: $accentColor; }

			}

			.details { 
				padding-left: 0; 
				width: calc(100% - 125px);
				float: left;
				margin-left: 10px;

				.dateAuthor { margin-top: 5px; }

				.teaser { 
					font-size: .65rem;
    				letter-spacing: .025rem;
    				font-family: $fontTwo;
				}

			}
			
			.commentsAndMore,
			&.extendedOptions { display: none; }	

			.teaser { margin-top: 2px; }
				
			&:last-of-type { border-bottom: none; }

		}

	}

	&.eventAggregatorElement {

		h3 { border-bottom: none; }

		.vevent {
			padding: 15px;
			box-shadow: none;
			border-bottom: solid 1px $gray;
			margin: 0;
			background: white;
			border-right: solid 1px $gray;
			border-left: solid 1px $gray;

			&:first-of-type { border-top: solid 1px $gray; }

			.summary {
				margin-bottom: 5px;

				a {
					color: $coal;
					transition: $transition-standard;
					font-family: $fontTwo;
					font-size: .9rem;
		    	letter-spacing: .025rem;
		    	font-weight: 600;
		    	line-height: normal;

					&:hover { color: $green; }

				}

			}

			.details { 
				font-weight: $regular; 
				color: $dark;
				font-family: $fontTwo;

				.time { 
					font-weight: $regular; 
					font-size: .7rem;
				}

			}

		}

		.dateImage {
			border: none;
			background: transparent;

			.month {
				background: transparent;
				border-top: none;
				color: $coal;
				font-weight: $regular;
				font-size: 12px;
				font-family: $fontTwo;
			}

			.date {
				font-weight: $semibold;
				color: $mainColor;
				font-size: 1.75rem;
			}
		}

		.item {
			color: $light;
		}

		.extendedOptions {

			a {
				font-family: $fontTwo;
				font-size: .8rem;
				font-weight: 500;

				&[title*='View All'] { display: none; }

			}	

		}

	}

}


// Custom Agg
.user_mode {

	.pe33,
	.pe25 {

		.aggregatorElement .item .details {

			h4 a { 
				font-size: 14px !important; 
				line-height: normal !important;
				padding: 0 15px;
			}

		}	

		.aggregatorElement .item .details {
			padding: 0 20px !important;
		}

	}	

	.pe25 {

		.aggregatorElement .item .details {
			width: 100% !important;

			h4 a {
				white-space: nowrap;
			  overflow: hidden;
			  text-overflow: ellipsis;
			}

		}	

	}

	.customAgg {

		&:hover {

			.aggregatorElement .item { border-bottom: solid 4px $accentColor; }

		}

		.aggregatorElement {	

			.aggHeader { display: none; }

			.item {
				margin: 0;
				padding: 0;
				background: $black;
				position: relative;
				border-bottom: solid 4px $gray;
				transition: $transition-standard;
				margin-bottom: 15px;

				&.extendedOptions { display: none; }

				a {
					display: block;
					width: auto;
					height: auto;
					float: none;
					background: black;

					img {
						width: calc(100% + 1px);
						height: auto;
						opacity: .8;
						transition: $transition-standard;
						display: block;

						&.tall {
							width: calc(100% + 1px);
						}

						&.wide { 
							height: auto; 
							width: calc(100% + 1px);
							position: relative;
							top: 0;
							left: 0;
							transform: none;
						}

					}	

				}

				.details { 
					position: absolute; 
					bottom: 25px;
					padding-left: 0;
					padding: 0 $aggPadding;
					width: auto;
					margin-left: 0;
					float: none;

					h4 {

						a {
							font-family: $fontOne;
							color: white;
							font-size: 2.5rem;
							font-weight: 600;
							line-height: 2.5rem;
							background: transparent;
							letter-spacing: .05rem;
						}

					}

					.aggTag {
							font-family: $fontOne;
							color: white;
							text-transform: uppercase;
							padding: 5px 10px;
							background: $accentColor;
							margin-right: 10px;
							padding-left: 50px;
							background: $accentColor;
							font-size: 1rem;
							color: white;
							font-family: $fontOne;
							left: 0;
					    position: absolute;
					    top: -40px;
						}

					.dateAuthor {
						position: absolute;
						top: -30px;
						font-size: 14px;
						color: white;
						font-family: $fontOne;
						left: 0;

						.expandedView { 
							display: block; 
							float: left;
						}

					}

					.teaser { color: white; }

					.commentsAndMore { display: none; }

				}

				&:hover {

					a img {
						opacity: 1;
					}

				}

			}	
		}
	}
}

// Spotlight

.spotlight {

	.pageElement.aggregatorElement {
		background: $accentColor;
		font-family: $fontOne;
		padding: 30px;

		h3.aggHeader {
			background: transparent;
			font-family: $fontOne;

			span {
				color: white;
				font-size: 2rem;
				text-transform: uppercase;
				letter-spacing: .025rem;
				font-weight: $semibold;


				span {
					display: block;
					color: white;
					font-size: 1rem;
					text-transform: uppercase;
					letter-spacing: .025rem;
					font-weight: $regular;
				}

			}

		}

		.item {
			border-bottom: none;
			box-shadow: none;

			.newsItemElement {
				position: relative;

				.newsItemHeader {

					> a {
						width: 40%;
						display: inline-block;
						float: left;
						background: $black;
						margin-right: 0;
						height: auto;

						img { 
							width: 100%; 
							opacity: .8;
							transition: $transition-standard;
							margin: 0;
						}

					}	

					h2 {
						width: 47%;
						margin-left: 13%;
						float: left;

						a {
							color: white;
							font-size: 1.5vw;
							font-weight: $regular;
							line-height: normal;
							border-bottom: solid 4px $accentColor;
    					padding-bottom: 10px;
    					transition: $transition-standard;

    					&:hover { border-bottom: solid 4px white; }

						}

					}

				}	

				.newsDate,
				.newsAuthor { display: none; }

			  h4 {
			 		line-height: 130%;
			    display: inline-block;
			    color: white;
			    font-size: 3vw;
			    top: -5px;
			    position: absolute;
			    left: 44%;
			    font-weight: $semibold;
			    width: auto;
			  }

			}	

			&.extendedOptions { display: none; }

			&:hover {

				.newsItemElement .newsItemHeader > a img { 
					opacity: 1;
				}

			}


		}	

	}

}


// Img Sizes

.aggregatorElement a img { 

	&.wide { 
		height: 100%; 
		width: auto;
		position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	}

	&.tall {
		height: auto;
		width: 100%;
	}

}

// Side Panel 

#sidePanelContainer {
	max-width: 300px;
  margin: 0 calc(50% - 150px);

	.sidePanel {
		padding-left: 0;

		.pageElement { margin-right: 0; }

		.aggregatorElement {

			h3 { border-bottom: none; }

			&.newsAggregatorElement .item {
				margin: 0;
				padding: 10px;
				background: white;
				border-left: solid 1px $gray;
				border-right: solid 1px $gray;

				&:first-of-type { border-top: solid 1px $gray; }

				.details { 
					padding-left: 0; 

					.dateAuthor { display: none; }

					.teaser { 
						font-size: .65rem;
	    				letter-spacing: .025rem;
	    				font-family: $fontTwo;
					}

				}

				.teaser { margin-top: 2px; }
				
				.commentsAndMore,
				&.extendedOptions { display: none; }	

				&:last-of-type { border-bottom: none; }

			}	

		}

	}

}

#NewsArticles {

	.item { 
		background: white !important; 

		h4 a { font-family: $fontTwo; }

	}

}



// Media Queries



@media screen and (max-width: 1024px) {

	.user_mode .customAgg {

		.aggregatorElement .item .details h4 a {
			font-size: 5vmin;
		}

	}

	.user_mode #sidePanelContainer {
		max-width: 100%;
		margin: auto;
	}

	.mobileMargin {
    max-width: 300px;
    margin: auto;
	}

}

@media screen and (min-width: 768px) {

	.user_mode {

		.pe33,
		.pe25 {

			.aggregatorElement .item {

				> a {
					width: 100%;
					height: 140px;
				}

				.details {
					margin-left: 0;
					margin-top: 10px;

					h4 a { 
						font-size: 14px !important; 
						line-height: normal !important;
					}

				}	

			}	

			.aggregatorElement .item .details {
				padding: 0 !important;
				width: 100%;
			}

		}	

	}	

}

@media screen and (max-width: 768px) {

	.spotlight .pageElement.aggregatorElement {

		h3.aggHeader span { font-size: 6vw; }

		.item .newsItemElement {

			h4 { font-size: 8vmin; }

			.newsItemHeader {

				h2 a { font-size: 3vmin; }

			}

		}	

	}	

	.aggregatorElement .newsItemElement {

		h4 {
			text-align: center;
    	width: 100%;
		}

		.newsDate,
		.newsAuthor {
			width: 100%;
    	text-align: center;
		}

		.newsItemHeader {
		
			a { 
				width: 100%; 
				text-align: center;

				img { float: none; }

			}

			h2 { 
				width: 100%; 
				margin-top: 15px;
			}

		}	

	}

	.user_mode {

		.pe33,
		.pe25 {

			.aggregatorElement .item .details h4 a { font-size: 5vmin; }

		}	

	}	

	.user_mode .customAgg .aggregatorElement .item .details {
		width: 100%;

		.teaser {
			height: 1rem;
			white-space: nowrap;
	  	overflow: hidden;
	  	text-overflow: ellipsis;
	  }
																							
	 }
	  
}

@media screen and (max-width: 640px) {

	.spotlight .pageElement.aggregatorElement {
		padding: 15px;

		.item .newsItemElement {

			h4 { 
				font-size: 8vmin; 
				width: 60%;
				text-align: left;
			}

			.newsItemHeader {

				a img { float: left; }

				h2 {
					margin-top: 0;

					a { 
						font-size: 4vmin; 
						text-align: left;

					}

				}	

			}

		}	

	}	

	.aggregatorElement.newsAggregatorElement .item h4 a { font-size: 1rem; }


}











